import { Form } from "@themesberg/react-bootstrap";
import React from "react";

function SelectInput(props) {
  const { options = [], label = null, labelClass, required = false } = props;
  return (
    <Form.Group>
      {label && (
        <Form.Label className={labelClass}>
          {label} {required ? <span style={{ color: "red" }}> *</span> : ""}
        </Form.Label>
      )}
      <Form.Select defaultValue={props.defaultValue || "0"} {...props}>
        <option value="">--Select--</option>
        {options.map((o, i) => (
          <option key={i} value={o}>
            {o}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
}

export default SelectInput;
