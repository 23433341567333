import { useLocation } from "react-router";

export function getMonth(mont) {
  const monthes = [
    "Jan",
    "Feb",
    "Mat",
    "App",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  return monthes[mont - 1];
}
export function checkStrEmpty(str) {
  return !(str && str.length > 1 && str.split(" ").join("").length > 0);
}

export function formatNumber(n = 0) {
  if (typeof n !== "number" && typeof n !== "string") {
    return "0";
  }

  // Convert n to a number if it's a string and round it to a certain number of decimal places
  n = parseFloat(n);

  if (isNaN(n)) {
    return "0";
  }

  // Format the number with commas for thousands and round it to two decimal places
  const formattedNumber = n.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedNumber;
}
export function toCurrency(number) {
  return new Intl.NumberFormat("en-US", { style: "decimal" }).format(number);
}
export function toParagraph(text) {
  // Split the text into sentences by using periods as separators
  var sentences = text?.toLowerCase()?.split(". ");

  // Capitalize the first letter of each sentence
  var capitalizedSentences = sentences?.map(function (sentence) {
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  });

  // Join the sentences back together with periods and spaces
  var paragraph = capitalizedSentences?.join(". ");

  return paragraph;
}

export function toParagraph2(text) {
  // Split the text into sentences by using periods as separators
  var sentences = text?.toLowerCase()?.split(". ");

  // Capitalize the first letter of each sentence
  var capitalizedSentences = sentences?.map(function (sentence) {
    // Use regex to find words within brackets and convert them to uppercase
    var updatedSentence = sentence.replace(/\([^)]+\)/g, function (match) {
      return match.toUpperCase();
    });

    return updatedSentence.charAt(0).toUpperCase() + updatedSentence.slice(1);
  });

  // Join the sentences back together with periods and spaces
  var paragraph = capitalizedSentences?.join(". ");

  return paragraph;
}

export function isAdmin(route) {
  return route.includes("admin");
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// System for American Numbering
var th_val = ["", "thousand", "million", "billion", "trillion"];
// System for uncomment this line for Number of English
// var th_val = ['','thousand','million', 'milliard','billion'];

var dg_val = [
  "zero",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
];
var tn_val = [
  "ten",
  "eleven",
  "twelve",
  "thirteen",
  "fourteen",
  "fifteen",
  "sixteen",
  "seventeen",
  "eighteen",
  "nineteen",
];
var tw_val = [
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];
export function toWords(s) {
  s = s.toString() || 0;
  s = s.replace(/[\\, ]/g, "");
  if (s !== parseInt(s)) return "not a number ";
  var x_val = s.indexOf(".");
  if (x_val === -1) x_val = s.length;
  if (x_val > 15) return "too big";
  var n_val = s.split("");
  var str_val = "";
  var sk_val = 0;
  for (var i = 0; i < x_val; i++) {
    if ((x_val - i) % 3 === 2) {
      if (n_val[i] === "1") {
        str_val += tn_val[Number(n_val[i + 1])] + " ";
        i++;
        sk_val = 1;
      } else if (n_val[i] !== 0) {
        str_val += tw_val[n_val[i] - 2] + " ";
        sk_val = 1;
      }
    } else if (n_val[i] !== 0) {
      str_val += dg_val[n_val[i]] + " ";
      if ((x_val - i) % 3 === 0) str_val += "hundred ";
      sk_val = 1;
    }
    if ((x_val - i) % 3 === 1) {
      if (sk_val) str_val += th_val[(x_val - i - 1) / 3] + " ";
      sk_val = 0;
    }
  }
  if (x_val !== s.length) {
    var y_val = s.length;
    str_val += "naira ";
    for (var e = x_val + 1; e < y_val; e++) str_val += dg_val[n_val[e]] + " ";
  }
  return str_val.replace(/\s+/g, " ");
}

export function toNairaWords(s) {
  const main = s.toString().split(".");
  const pre = toWords(main[0]);
  let post = null;
  if (main[1] > 0) {
    post = toWords(main[1]);
  }
  if (post) {
    return pre + " and " + post + " kobo only";
  } else {
    return pre + " naira only";
  }
}
