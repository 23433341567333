import { Col,  Row } from "@themesberg/react-bootstrap";
import React from "react";
import { Button, Modal } from "reactstrap";

function CustomModal(props) {
  const {
    toggle = (f) => f,
    isOpen = false,
    // title = "",
    logo = null,
    message = "",
    color = "primary",
    size = "md",
  } = props;
  return (
    <Modal
      className="modal-dialog-centered modal-primary"
      contentClassName="bg-white"
      isOpen={isOpen}
      toggle={toggle}
      size={size}
    >
      <Row className="">
        <Col md={1}></Col>
        <Col md={10} className="text-center p-0 m-0">
          {logo}
        </Col>

        <Col md={1}>
          <Button
            aria-label="Close"
            title="Close"
            className="close"
            data-dismiss="modal"
            color="danger"
            type="button"
            onClick={toggle}
          >
            <span aria-hidden={true}>X</span>
          </Button>
        </Col>
      </Row>
      <div className="modal-body">
        <div className="py-1 text-center">
          <i className="ni ni-check-bold ni-3x" color={color} />
          <h4 className="heading mt-1">{message}</h4>
        </div>
        {props.children}
      </div>
      {/* <div className="modal-footer">
        <Button
          className="text-white"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={toggle}
        >
          Close
        </Button>
      <Button className="btn-white ml-auto" color="default" type="button">
          Proceed to proposal
        </Button> 
      </div> */}
    </Modal>
  );
}

export default CustomModal;
