import React, { useState } from "react";
import CustomInput from "../examples/CustomInput";
import { CustomButton } from "../../components/UI";
import PaymentInvoice from "../PaymentInvoice";

import { useHistory, useParams } from "react-router-dom";
import { Routes } from "../../routes";
import { Card } from "@themesberg/react-bootstrap";

function GenerateReceipt() {
  const { ref_no } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [transactionRef, setTransactionRef] = useState("");

  const displayReceipt = () => {
    if (transactionRef && transactionRef !== "") {
      history.push(
        `${Routes.adminPaymentReceipt.path}?ref_no=${transactionRef}`
      );
    } else {
      // alert
    }
  };

  return (
    <div
    //  style={{
    //   backgroundImage: `url(${require("../../assets/img/kano.png")})`,
    //   backgroundSize: 'cover',
    //   backgroundPosition: 'center center',
    //   opacity:'0.4'
    // }}
    >
      <div className="d-flex p-2  justify-content-center flex-wrap flex-md-nowrap align-items-center py-4 pt-8">
        <Card className="shadow col-md-6 py-5">
          <Card.Body className="text-center p-4 ">
            <div className="mb-4 mb-md-0">
              <h4>GENERATE RECEIPTS</h4>
            </div>
            <div className="col-md-12">
              <CustomInput
                label="Enter Invoice Number"
                value={transactionRef}
                className="text-center"
                onChange={({ target: { value } }) => setTransactionRef(value)}
              />
            </div>
            <CustomButton onClick={displayReceipt}>Generate</CustomButton>
          </Card.Body>
        </Card>
      </div>

      {/* {ref_no && ref_no !== "" ?  <PaymentInvoice transactionRef={transactionRef} /> : null} */}
    </div>
  );
}

export default GenerateReceipt;
