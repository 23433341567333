import React, { useEffect, useState } from "react";
import { Col, Row, Card, Form, Button } from "@themesberg/react-bootstrap";
import { _fetchApi, _postApi } from "../../redux/action/api";
import { useCallback } from "react";
import CustomTypeahead from "../../components/UI/CustomTypeahead";
import { Table } from "reactstrap";

export default function NewTaxForm() {
  const _form = {
    sector: "",
    parent_code: "",
    description: "",
    tax_code:"",
    tax_fee: "",
    query_type:'create-tax'
  };
  const [form, setForm] = useState(_form);
  // const [taxList, setTaxList] = useState([]);
  const [, setSubHeads] = useState([]);
  const [taxHeads, setTaxHeads] = useState([]);
  const [tableData, setTableData] = useState([])

  const handleChanges = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const getTaxHeads = useCallback(() => {
    _fetchApi(
      `/kigra-taxes?query_type=select-heads&sector=${form.sector}`,
      (resp) => {
        setTaxHeads(resp.result);
      },
      () => {}
    );
  }, [form.sector]);

  const getSubHeads = useCallback(() => {
    _fetchApi(
      `/kigra-taxes?query_type=select&parent_code=${form.parent_code}`,
      (resp) => {
        if (resp?.result?.length) {
          setSubHeads(resp.result);
        }
      },
      (err) => {
        console.error(err);
        setSubHeads([]);
      }
    );
  }, [form.parent_code]);


  const handleAdd = (e) => {
    e.preventDefault();
    setTableData( p => ([...p, form]))
  }

  const handleSubmit = () => {
    _postApi(
      "/kigra-taxes",
      tableData,
      (res) => {
        console.log(res)
      },
      (err) => {
        console.error(err);
      }
    );
    console.log(form);
  };

  useEffect(() => {
    getSubHeads();
    getTaxHeads();
  }, [getSubHeads, getTaxHeads]);

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Tax information</h5>
        <Form>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Tax category</Form.Label>
                <Form.Select
                  required
                  value={form.sector}
                  name="sector"
                  type="select"
                  onChange={handleChanges}
                  placeholder="Select tax category"
                >
                <option value="">Select tax sector</option>
                  <option value="TAX">Tax</option>
                  <option value={"NON TAX"}>Non Tax</option>
                  <option value={"VEHICLES"}>Vehicles tax</option>
                  <option value={"LAND"}>Land tax</option>
                  <option value={"LGA"}>LGA Revenue</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="taxHead">
                {/* <Form.Label>Tax Head</Form.Label> */}
                <CustomTypeahead
                  options={taxHeads}
                  label='Tax Head'
                  labelKey={(l) => l.tax_code}
                  onChange={(e) => {
                    console.log(e);
                    setForm((p)=>({...p, parent_code:e[0].title, tax_code:e[0].economic_code}))
                  }}
                  col={12}
                  clearButton
                  allowNew
                  newSelectionPrefix="Create a new item: "
                  placeholder="Search or create Head"
                />
                </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Tax title</Form.Label>
                <Form.Control 
                  name="description" 
                  value={form.description}
                  onChange={handleChanges} 
                />
              </Form.Group>
            </Col>
            <Col sm={4} md={4} className="mb-3">
              <Form.Group id="city">
                <Form.Label>Revenue code</Form.Label>
                <Form.Control 
                  required type="text" 
                  placeholder="Revenue code"
                  value= {form.tax_code} 
                  name= "tax_code"
                  onChange={handleChanges}
                />
              </Form.Group>
            </Col>
            <Col sm={4} md={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Tax fee</Form.Label>
                <Form.Control
                  name="tax_fee"
                  placeholder="Tax fee"
                  value={form.tax_fee}
                  onChange={handleChanges}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
            <Button 
              variant="primary"
              onClick={handleAdd}
              >
              Add to list
            </Button></Col>
            
          </Row>
          <Row>
            {/** Render new tax list */}
          </Row>

        </Form>
        <Table striped>
          <thead>
            <tr>
              <th>
                Tax category
              </th>
              <th>
                Tax Head
              </th>
              <th>
                Tax title
              </th>
              <th>
                Revenue code
              </th>
              <th>
                Tax Fee
              </th>
            </tr>
          </thead>
          {tableData.map((item, idx) => (
            <tbody>
              <tr>
                <th>
                  {item.sector}
                </th>
                <th>
                  {item.parent_code}
                </th>
                <th>
                  {item.description}
                </th>
                <th>
                  {item.tax_code}
                </th>
                <th>
                  {item.tax_fee}
                </th>
              </tr>
            </tbody>
          ))}
        </Table>
          <Row>
          <Col className="text-center mt-3"> 
            <Button 
              variant="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button></Col>
          </Row>
      </Card.Body>
    </Card>
  );
}