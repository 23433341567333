import {
  AUTH,
  LOADING_LOGIN,
  LOADING_SIGNUP,
  LOGOUT,
  AUTH_ERROR,
  ERRORS,
  APPROVAL_DISPHATCH,
  TAX_ACCOUNT,
  RECOVER_USER,
} from "../action/type";

const initialState = {
  authenticated: false,
  user: {},
  loadingLogin: false,
  loadingSignup: false,
  requestIsValid: true,
  error: "",
  loadingApp: false,
  item_code: "",
  recoverPhone: { code: "", phone: "" },
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case AUTH:
      return {
        ...state,
        user: action.payload?.user,
        tax_account:
          action.payload && action.payload?.tax_account?.id > 0
            ? action.payload.tax_account
            : state.tax_account,
        authenticated: true,
      };
    case TAX_ACCOUNT:
      return {
        ...state,
        tax_account: action.payload,
      };

    case RECOVER_USER:
      return {
        ...state,
        recoverPhone: action.payload,
      };
    case APPROVAL_DISPHATCH:
      return {
        ...state,
        item_code: action.payload,
      };
    case LOADING_LOGIN:
      return {
        ...state,
        loadingLogin: !state.loadingLogin,
      };
    case ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    case LOADING_SIGNUP:
      return {
        ...state,
        loadingSignup: !state.loadingSignup,
      };
    case AUTH_ERROR:
      return {
        ...state,
        error: "",
      };
    case LOGOUT:
      return {
        ...state,
        authenticated: false,
        user: {},
      };
    default:
      return state;
  }
}
