import { combineReducers } from 'redux';

import auth from './auth';
import kigraReducer from './kigra';

const rootReducer = combineReducers({
  auth,
  kigra: kigraReducer,
});

export default rootReducer;
