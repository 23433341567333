import React, { useEffect, useState } from "react";
import {
  Card,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  TextField,
  IconButton,
  Icon,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { _postApi } from "../../redux/action/api";
import { toast } from "react-hot-toast";
import { FaArrowLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import "./profile.css";
import { CustomButton } from "../../components/UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "reactstrap";
import { stateLga } from "../../statelga";
import SelectInput from "../components/SelectInput";
import CustomInput from "../examples/CustomInput";

function TaxpayerProfile() {
  const [viewMode, setViewMode] = useState(true);
  const history = useHistory();
  const { user, tax_account } = useSelector((p) => p.auth);
  const [userData, setUserData] = useState({
    ...tax_account,
    query_type: "update-taxpayer",
  });

  // Switch between view mode and edit mode
  const switchMode = () => {
    setViewMode(!viewMode);
  };

  // Handle changes in input fields
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };
  const [loading, setLoading] = useState(false);

  // Save the changes
  const saveChanges = () => {
    setLoading(true);
    _postApi(
      "/auths/-post-users",
      userData,
      (resp) => {
        if (resp.success) {
          // setUserData(...resp);
          toast.success("Success");
          setViewMode(false);
          setLoading(false);
        }
      },
      (error) => {
        console.error(error);
        toast.error("Not submitted");
      }
    );
  };

  // Implement delete user functionality
  const deleteUser = () => {
    // Add delete user logic here
  };

  // Implement reset password functionality
  const resetPassword = () => {
    // Add reset password logic here
  };

  // Implement browse avatar functionality
  const browserAvatar = () => {
    // Add browse avatar logic here
  };

  useEffect(() => {
    if (user && user.id) {
      setViewMode(true);
      setUserData({
        user_id: user.id,
        taxID: tax_account?.taxID,
        name: user.name,
        username: tax_account?.username,
        phone: user?.phone,
        tin: tax_account?.state,
        org_name: tax_account?.org_name,
        role: tax_account?.role,
        email: user?.email,
        address: tax_account?.address,
        state: tax_account?.state,
      });
      setViewMode(false);
    }
  }, [user]);
  return (
    <div className="container rounded bg-white mt-5 mb-5">
      <p className="mb-0">
        {" "}
        <Button size="sm" onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faArrowCircleLeft} /> Back
        </Button>
      </p>
      <div className="row">
        <div className="col-md-3 border-right">
          <div className="d-flex flex-column align-items-center text-center p-3 py-5">
            <img
              className="rounded-circle mt-5"
              width="150px"
              src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
              alt="Profile Picture "
            />
            <span className="font-weight-bold">{user && user?.name}</span>
            <span className="text-black-50">TAx ID: {user && user?.taxID}</span>
          </div>
        </div>
        <div className="col-md-5 border-right">
          <div className="p-3 py-5">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4 className="text-right">Profile Settings</h4>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <label for="first-name" className="labels">
                  Name
                </label>
                <input
                  type="text"
                  id="first-name"
                  className="form-control"
                  placeholder="First Name"
                  value={userData.name}
                  name="name"
                  onChange={handleInputChange}
                  disabled
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <label for="mobile-number" className="labels">
                  Mobile Number
                </label>
                <input
                  type="text"
                  id="mobile-number"
                  className="form-control"
                  placeholder="Enter Phone Number"
                  value={userData.phone}
                  name="phone"
                  onChange={handleInputChange}
                  disabled
                />
              </div>
              <div className="col-md-12">
                <label for="address-line-1" className="labels">
                  Address
                </label>
                <input
                  type="text"
                  id="address-line-1"
                  className="form-control"
                  placeholder="Enter Address Line 1"
                  value={userData.address}
                  name="address"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-12">
                <label for="email-id" className="labels">
                  Email ID
                </label>
                <input
                  type="text"
                  id="email-id"
                  className="form-control"
                  placeholder="Enter Email ID"
                  value={userData.email}
                  name="email"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <Col md={12}>
              <SelectInput
                label="State of residence"
                type="text"
                name="state"
                // required={true}
                options={stateLga.map((item) => item.state)}
                value={userData.state}
                onChange={handleInputChange}
              />
            </Col>

            <Col md={12} className="mb-1">
              {/* {JSON.stringify(s)} */}
              <SelectInput
                label="L.G.A"
                type="text"
                name="lga"
                // required={true}
                options={
                  stateLga.filter((item) => item.state === userData.state)[0]
                    ?.lgas
                }
                value={userData.lga}
                onChange={handleInputChange}
              />
            </Col>
            <Col md={12} className="mb-1">
              <CustomInput
                label="Ward"
                type="text"
                name="ward"
                value={userData.ward}
                onChange={handleInputChange}
              />
            </Col>

            <div className="mt-3 text-center">
              <CustomButton loading={loading} onClick={saveChanges}>
                Save Profile
              </CustomButton>
            </div>
          </div>
        </div>
        {/* <div className="col-md-4">
          <div className="p-3 py-5">
            <br />
            <div className="col-md-12">
              <label for="experience-design" className="labels">
                Experience in Designing
              </label>
              <input
                type="text"
                id="experience-design"
                className="form-control"
                placeholder="Experience"
                value=""
              />
            </div>
            <br />
            <div className="col-md-12">
              <label for="additional-details" className="labels">
                Additional Details
              </label>
              <input
                type="text"
                id="additional-details"
                className="form-control"
                placeholder="Additional Details"
                value=""
              />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );

  // <div className="user-profile">
  //   {/* {JSON.stringify(userData)} */}
  //   <Button className="" onClick={() => history.goBack()}>
  //     <FaArrowLeft /> Go back
  //   </Button>
  //   <Card style={{ padding: 10 }}>
  //     <Toolbar>
  //       <div className="md-toolbar-tools">
  //         <h2>
  //           <span>OVERVIEW</span>
  //         </h2>
  //         <span style={{ flex: 1 }}></span>
  //         {viewMode ? (
  //           <Button
  //             className="md-icon-button"
  //             onClick={switchMode}
  //             style={{ backgroundColor: "#000033", color: "#fff" }}
  //           >
  //             Edit Profile
  //           </Button>
  //         ) : (
  //           <Button
  //             className="md-icon-button"
  //             onClick={() => {
  //               switchMode();
  //               saveChanges();
  //             }}
  //             style={{ backgroundColor: "#000033", color: "#fff" }}
  //           >
  //             Save Changes
  //           </Button>
  //         )}
  //       </div>
  //     </Toolbar>
  //     {/* {} */}
  //     <div
  //       className="md-card-content"
  //       style={{
  //         display: "flex",
  //         flexDirection: "row",
  //         width: "100%",
  //         padding: 20,
  //       }}
  //     >
  //       <div className="user" style={{ width: "40%" }}>
  //         <center>
  //           <img
  //             src={require("../../images/Ahmad.jpg").default}
  //             alt="user avatar"
  //             className="user-avatar"
  //             style={{ width: "50%", height: 250 }}
  //           />
  //         </center>
  //         <form
  //           name="userBasicInfoForm"
  //           style={{
  //             display: "flex",
  //             flexDirection: "column",
  //             alignItems: "start",
  //             justifyContent: "center",
  //             // marginLeft: '20px'
  //           }}
  //         >
  //           <TextField
  //             className="user-input-container"
  //             label="Contact name"
  //             required
  //             name="name"
  //             value={userData.name}
  //             onChange={handleInputChange}
  //             disabled
  //             fullWidth
  //             multiline
  //           />
  //           <TextField
  //             className="user-input-container"
  //             label="UserName"
  //             required
  //             name="username"
  //             value={userData.username}
  //             onChange={handleInputChange}
  //             disabled
  //             fullWidth
  //           />
  //         </form>
  //       </div>
  //       <form style={{ width: "60%", marginLeft: 15 }}>
  //         <div
  //           style={{
  //             display: "flex",
  //             flexDirection: "column",
  //             alignItems: "start",
  //             justifyContent: "center",
  //             // marginTop: 52
  //           }}
  //         >
  //           <TextField
  //             label="Contact Phone No"
  //             required
  //             name="phone"
  //             value={userData.phone}
  //             onChange={handleInputChange}
  //             disabled
  //             fullWidth
  //           />
  //           <TextField
  //             label="TIN"
  //             required
  //             name="tin"
  //             value={userData.tin}
  //             onChange={handleInputChange}
  //             disabled
  //             fullWidth
  //             multiline
  //           />
  //         </div>
  //         <div
  //           style={{
  //             display: "flex",
  //             flexDirection: "column",
  //             alignItems: "start",
  //             justifyContent: "center",
  //           }}
  //         >
  //           <TextField
  //             label="Contact Email"
  //             required
  //             name="email"
  //             type="email"
  //             value={userData.org_email || userData.email}
  //             onChange={handleInputChange}
  //             disabled={viewMode}
  //             fullWidth
  //           />
  //           <TextField
  //             label="Contact Address"
  //             required
  //             name="address"
  //             value={userData.address}
  //             onChange={handleInputChange}
  //             disabled={viewMode}
  //             fullWidth
  //           />
  //         </div>
  //         <div
  //           style={{
  //             display: "flex",
  //             flexDirection: "column",
  //             alignItems: "start",
  //             justifyContent: "center",
  //           }}
  //         >
  //           <TextField
  //             label="State of residence"
  //             type="text"
  //             required
  //             name="state"
  //             value={userData.state}
  //             onChange={handleInputChange}
  //             disabled={viewMode}
  //             fullWidth
  //           />
  //           <TextField
  //             label="Local Government"
  //             type="text"
  //             name="lga"
  //             value={userData.lga}
  //             onChange={handleInputChange}
  //             disabled={viewMode}
  //             fullWidth
  //           />
  //         </div>
  //       </form>
  //     </div>
  //   </Card>
  // </div>
  // );
}

export default TaxpayerProfile;
