/* eslint-disable react/jsx-key */
import React, { useCallback, useEffect } from "react";
import { Button, Container, Spinner, Table } from "reactstrap";
import { useState } from "react";
import { formatNumber, toParagraph, useQuery } from "../../utils";
import "./invoice.css";
import PaymentButton from "../payments/PaymentButton";
import { toast } from "react-hot-toast";
import { Col, Row, Modal } from "@themesberg/react-bootstrap";
import { faDownload, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { _fetchApi, _postApi, apiURL } from "../../redux/action/api";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import CustomModal from "../../components/UI/CustomModal";
import { useMemo } from "react";
import PaymentInvoice from "../PaymentInvoice";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoicePdf from "../../components/InvoicePdf";
import useIsPhoneSize from "../../utils/useIsPhoneSize";
import { LandAccountantInvoice } from "../LandAccountantInvoice";
import LandTermalInvoice from "../../components/LandTermalInvoice";
import TermalInvoice from "../../components/TermalInvoice";

export default function GeneralCart({
  data = [],
  form = {},
  setModal = (f) => f,
  // callBack = (f) => f,
  // total = 0,
  handleTaxFeeChecked = (f) => f,
}) {
  const [finalData, setFinalData] = useState(data);

  const total = useMemo(() => {
    return data
      .filter((item) => item.checked === true)
      .reduce((it, id) => it + parseFloat(id.tax_fee), 0);
  }, [data]);

  const { tax_account, user } = useSelector((s) => s.auth);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [qrcode, setQrCode] = useState(null);
  const [list, setList] = useState([]);
  const [PDFisOpen, setPDFIsOpen] = useState(false);
  const togglePDF = () => setPDFIsOpen(!PDFisOpen);
  const isMobile = useIsPhoneSize();
  const query = useQuery();
  const pay = query.get("pay");
  const [payModal, setPayModal] = useState(false);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [refNo, setRefNo] = useState(null);

  const handleSubmit = (transaction_type = "invoice") => {
    console.log("Handled payment");

    const tax_list = [];

    if (data.length) {
      data
        .filter((item) => item.checked === true)
        .forEach((item) => {
          tax_list.push({
            ...item,
            mda_var: item.mda_var,
            mda_val: item.mda_val,
            transaction_type: "payment",
            description: item.title,
            rev_code: item.economic_code,
            amount: item.tax_fee,
            service_category: item.tax_parent_code,
            item_code: item.id,
            sector: item.sector,
            phone: item.phone
              ? item.phone
              : tax_account.office_phone || tax_account.phone,
          });
        });
      tax_list.push({
        mda_var: data[0]?.mda_var,
        mda_val: data[0]?.mda_val,
        tax_code: data[0]?.tax_code,
        sector: data[0]?.sector,
        tax_parent_code: data[0]?.tax_parent_code,
        amount: tax_list.reduce((it, id) => it + parseFloat(id.amount), 0),
        total: tax_list.reduce((it, id) => it + parseFloat(id.amount), 0),
        description: data.map((itm) => itm.title).toString(),
        transaction_type: "invoice",
        rev_code: data[0]?.economic_code || "",
        service_category: data[0]?.tax_parent_code || "",
        item_code: data[0].id || "",
        mda_code: data[0]?.mda_code || "",
        mda_name: data[0]?.mda_name || "",
        department: data[0]?.department,
        phone: data[0].phone
          ? data[0].phone
          : tax_account.office_phone || tax_account.phone,
      });
      const final_data = {
        total: tax_list.reduce((it, id) => it + parseFloat(id.amount), 0),
        user_id: tax_account.taxID,
        nin: tax_account.nin,
        nin_id: tax_account.nin_id,
        agent_id: user.role === "user" ? "" : user.id,
        tax_list,
        tax_station: data[0].tax_station,
        transaction_type,
        transaction_date: moment().format("YYYY-MM-DD"),
        start_date: moment(data[0].from).format("YYYY-MM-DD"),
        end_date: moment(data[0].to).format("YYYY-MM-DD"),
        tax_payer: tax_account.org_name || tax_account.name,
        phone: tax_list[0].phone,
        invoice_status: data[0]?.invoice_status || "",
      };
      setLoading(true);
      _postApi(
        "/transactions/execute",
        final_data,
        (resp) => {
          if (resp.success) {
            setIsOpen(true);
            setRefNo(resp.data[0].ref_no);
            setLoading(false);
          } else {
            toast.error("Error");
            setLoading(false);
          }
        },
        (e) => {
          console.error("Error", e);
        }
      );
    }
  };

  useEffect(() => {
    if (data.length === 0) {
      setModal(false);
    }
  }, [data, setModal]);

  const getList = useCallback(() => {
    if (refNo) {
      setLoading(true);
      _fetchApi(
        `/transactions/retrieve?query_type=view_invoice&reference_number=${refNo}`,
        (resp) => {
          setList(resp.data);
          if (resp.data[0]?.user_id) {
            setLoading(false);
          }
        },
        (err) => {
          console.error(err);
          setLoading(false);
        },
        (err) => {
          console.log(err);
          setList([]);
          setLoading(false);
        }
      );

      const qrCodeUrl = `${apiURL}/transactions/get-qr-code?ref_no=${refNo}`;
      fetch(qrCodeUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("@@auth_token"),
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.blob();
        })
        .then((blob) => {
          const qrCodeImageUrl = URL.createObjectURL(blob);
          setQrCode(qrCodeImageUrl);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [refNo, tax_account.taxID]);

  useEffect(() => {
    getList();
  }, [getList]);

  const final_list = list.some((item) => item.transaction_type === "payment")
    ? list
    : list.map((item) => ({ ...item, cr: item.dr }));

  return (
    <div className="cardss p-0 m-0">
      {/* {JSON.stringify({ finalData })} */}
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h3>{form.page_title}</h3>
        <h5>Payment for: {data[0]?.tax_parent_code}</h5>
        {/* <h5>Revenue code: {data[0]?.economic_code}</h5> */}
        <h5>Tax Payer: {tax_account.org_name || tax_account.name}</h5>
      </div>
      <div className="d-flex justify-content-between">
        <h6>({data.length}) Items selected</h6>
        <h6>Total: {formatNumber(total)}</h6>
      </div>
      {/* {JSON.stringify(form.page_title)} */}
      <table className="table-zebra" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th width="10%">S/N</th>
            <th width="60%"> Description</th>
            <th width="15%">Revenue code</th>
            <th width="15%" className="text-center">
              Amount
            </th>
            {form.page_title !== "TAX PAYMENTS" ? (
              <th width="15%" className="text-right">
                Action
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {data
            .filter((item) => item.checked === true)
            ?.map((it, idx) => (
              <tr key={idx}>
                <td width={"15x"}>{idx + 1}</td>
                <td>{toParagraph(it.title)}</td>
                <td>{data.economic_code}</td>
                <td className="text-right">{formatNumber(it.tax_fee)}</td>
                {form.page_title !== "TAX PAYMENTS" ? (
                  <td className="text-right">
                    <Button
                      size="sm"
                      title="Delete"
                      color="danger"
                      onClick={() => handleTaxFeeChecked(it)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} className="" />
                    </Button>
                  </td>
                ) : null}
              </tr>
            ))}
        </tbody>
      </table>
      <br />
      {/* {form.trx_type === "invoice" ? ( */}
      <Row>
        <Button
          color="warning"
          className="mr-2"
          block
          onClick={() => {
            handleSubmit("invoice");
          }}
          disabled={loading}
        >
          {loading ? <Spinner size="sm" /> : null}
          {form.trx_type === "invoice" ? "Save" : "Pay Now"}
        </Button>
      </Row>
      {/* ) : ( */}
      <Row>
        <Col></Col>
      </Row>
      {/* )} */}

      <Modal size="xl" fullscreen as={Modal.Dialog} centered show={PDFisOpen}>
        <Modal.Header>
          <Modal.Title className="h6">Invoice No.: {refNo}</Modal.Title>
          <Button
            variant="danger"
            className="bg-danger text-white"
            aria-label="Close"
            onClick={togglePDF}
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>{refNo ? <PaymentInvoice refNo={refNo} /> : ""}</Modal.Body>
      </Modal>

      <CustomModal size="lg" toggle={toggle} isOpen={isOpen}>
        <Container className="text-center">
          {form.trx_type === "invoice" ? (
            <h3>Your Invoice ref. is: {refNo}</h3>
          ) : (
            <>
              <h2>Are you sure you want to pay now? </h2>
              <b>Ref. No:{refNo}</b>
            </>
          )}
          {form.trx_type === "invoice" ? (
            <Row className=" row d-flex justify-content-between ">
              {" "}
              {!isMobile ? (
                <Col>
                  <Button
                    className="btn-link"
                    onClick={() => {
                      if (user.role === "user") {
                        history.push(
                          `${Routes.paymentInvoice.path}?ref_no=${refNo}`
                        );
                      } else {
                        if (refNo) {
                          setIsOpen(false);
                          setPDFIsOpen(true);
                        }
                      }
                    }}
                  >
                    Print
                  </Button>
                </Col>
              ) : (
                <Col>
                  {final_list[0]?.sector !== "LAND" ? (
                   <> <PDFDownloadLink
                      document={
                        <InvoicePdf
                          user={tax_account}
                          data={final_list
                            .filter((item) => item.cr > 0)
                            .map((l) => ({ ...l, tax_fee: l.cr }))}
                          qrcode={qrcode}
                        />
                      }
                      fileName="invoice.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Loading document..."
                        ) : (
                          <Button variant="primary">
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="me-2"
                            />
                            Download PDF
                          </Button>
                        )
                      }
                    </PDFDownloadLink>
                    <PDFDownloadLink
                        document={
                          <TermalInvoice
                            user={tax_account}
                            data={final_list
                              .filter((item) => item.cr > 0)
                              .map((l) => ({ ...l, tax_fee: l.cr }))}
                            qrcode={qrcode}
                          />
                        }
                        fileName="termal-invoice.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            "Loading document..."
                          ) : (
                            <Button variant="primary">
                              <FontAwesomeIcon
                                icon={faDownload}
                                className="me-2"
                              />
                              Termal Invoice
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                    </>
                  ) : (
                    <>
                      <PDFDownloadLink
                        document={
                          <LandTermalInvoice
                            user={tax_account}
                            data={final_list
                              .filter((item) => item.cr > 0)
                              .map((l) => ({ ...l, tax_fee: l.cr }))}
                            qrcode={qrcode}
                          />
                        }
                        fileName="invoice.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            "Loading document..."
                          ) : (
                            <Button variant="primary">
                              <FontAwesomeIcon
                                icon={faDownload}
                                className="me-2"
                              />
                              Download Invoice PDF
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                      <PDFDownloadLink
                        document={
                          <LandAccountantInvoice
                            data={final_list
                              .filter((item) => item.cr > 0)
                              .map((l) => ({ ...l, tax_fee: l.cr }))}
                          />
                        }
                        fileName="invoice.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            "Loading document..."
                          ) : (
                            <Button variant="primary">
                              <FontAwesomeIcon
                                icon={faDownload}
                                className="me-2"
                              />
                              Download Payment Schedule PDF
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                    </>
                  )}
                </Col>
              )}
              {/* // ) : null} */}
            </Row>
          ) : (
            <Row>
              <PaymentButton
                onMouseOver={() => {}}
                addStyle={{ with: "100%", marginTop: 10 }}
                amount={total}
                reference_no={refNo}
                email={tax_account.org_email || tax_account.email}
                name={tax_account.org_name || tax_account.name}
                taxID={tax_account.taxID}
                phone={tax_account.office_phone || tax_account.phone}
                data={data.filter((item) => item.checked === true)}
                sector={finalData[0].sector}
                callback={handleSubmit}
                setPayModal={setPayModal}
                toggle={toggle}
              />
            </Row>
          )}
        </Container>
      </CustomModal>
      <CustomModal
        size="lg"
        toggle={() => {
          setPayModal(!payModal);
        }}
        isOpen={payModal}
      >
        <Container className="text-center">
          <h3>
            Congratulations you payment has successfully recorded, your
            reference number is: {refNo}
          </h3>
          <Row className=" row d-flex justify-content-between ">
            {!isMobile ? (
              <Col>
                <Button
                  className="btn-link"
                  onClick={() => {
                    if (user.role === "user") {
                      history.push(
                        `${Routes.paymentInvoice.path}?ref_no=${refNo}`
                      );
                    } else {
                      if (refNo) {
                        setIsOpen(false);
                        setPDFIsOpen(true);
                      }
                    }
                  }}
                >
                  Print
                </Button>
              </Col>
            ) : (
              <Col>
                {final_list[0]?.sector !== "LAND" ? (
                  <PDFDownloadLink
                    document={
                      <InvoicePdf
                        user={tax_account}
                        data={final_list
                          .filter((item) => item.cr > 0)
                          .map((l) => ({ ...l, tax_fee: l.cr }))}
                        qrcode={qrcode}
                      />
                    }
                    fileName="invoice.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        "Loading document..."
                      ) : (
                        <Button variant="primary">
                          <FontAwesomeIcon icon={faDownload} className="me-2" />
                          Download PDF
                        </Button>
                      )
                    }
                  </PDFDownloadLink>
                ) : (
                  <>
                    <PDFDownloadLink
                      document={
                        <LandTermalInvoice
                          user={tax_account}
                          data={final_list
                            .filter((item) => item.cr > 0)
                            .map((l) => ({ ...l, tax_fee: l.cr }))}
                          qrcode={qrcode}
                        />
                      }
                      fileName="invoice.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Loading document..."
                        ) : (
                          <Button variant="primary">
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="me-2"
                            />
                            Download Invoice PDF
                          </Button>
                        )
                      }
                    </PDFDownloadLink>
                    <PDFDownloadLink
                      document={
                        <LandAccountantInvoice
                          data={final_list
                            .filter((item) => item.cr > 0)
                            .map((l) => ({ ...l, tax_fee: l.cr }))}
                        />
                      }
                      fileName="invoice.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Loading document..."
                        ) : (
                          <Button variant="primary">
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="me-2"
                            />
                            Download Payment Schedule PDF
                          </Button>
                        )
                      }
                    </PDFDownloadLink>
                  </>
                )}
              </Col>
            )}
          </Row>
        </Container>
      </CustomModal>
    </div>
  );
}
