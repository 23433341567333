import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import { useHistory, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxOpen,
  faHandHoldingUsd,
  faSignOutAlt,
  faTable,
  faTimes,
  faCog,
  faChevronDown,
  faChevronUp,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Badge,
  Image,
  Button,
  Dropdown,
  Navbar,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { Routes } from "../routes";
import ReactHero from "../assets/img/kigra.jpg";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
import { isAdmin, useQuery } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/action/auth";
import { FaApper } from "react-icons/fa";
import useIsPhoneSize from "../utils/useIsPhoneSize";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const onCollapse = () => setShow(!show);
  const { extension, links } = props;
  const { user } = useSelector((s) => s.auth);
  const isPhoneSize = useIsPhoneSize();

  const getAccess = (val) => {
    return user?.accessTo
      ?.split(",")
      .map((item) => item.trim())
      .includes(val);
  };

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon = faCog, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    // State to track if the item is expanded
    const [expanded, setExpanded] = useState(defaultKey === eventKey);

    // Function to toggle the expanded state
    const handleToggle = () => {
      setExpanded(!expanded);
    };

    return (
      <Nav.Item>
        <Nav.Link
          eventKey={eventKey}
          onClick={handleToggle}
          className="d-flex justify-content-between align-items-center"
        >
          <p>
            <span className="sidebar-icon">
              <FontAwesomeIcon icon={icon} size="sm" />
            </span>
            <span className="sidebar-text">{title}</span>
          </p>
          <span className="expand-icon">
            <FontAwesomeIcon
              icon={expanded ? faChevronUp : faChevronDown}
              size="sm"
            />
          </span>
        </Nav.Link>
        {expanded && (
          <div style={{ paddingLeft: "10px" }}>
            <Nav className="flex-column">{children}</Nav>
          </div>
        )}
      </Nav.Item>
    );
  };
  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
      imageHeight,
      imageWidth,
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={imageHeight || 20}
                height={imageWidth || 20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  const sector = (item) => user?.sector?.includes(item);

  const gotoSector = () => {
    switch (sector) {
      case "NON TAX":
        history.push(Routes.NonTaxPayment.path);
        break;
      case "TAX":
        history.push(Routes.TaxPayment.path);
        break;
      case "VEHICLES":
        history.push(Routes.VehiclePayments.path);
        break;
      case "LGA":
        history.push(Routes.LgaPayments.path);
        break;
      case "LAND":
        history.push(Routes.LandPayments.path);
        break;
      default:
        history.push(Routes.Selection.path);
        break;
    }
  };

  const routeTo = sector("NON TAX")
    ? Routes.NonTaxPayment.path
    : sector("TAX")
    ? Routes.TaxPayment.path
    : sector("VEHICLES")
    ? Routes.VehiclePayments.path
    : sector("LGA")
    ? Routes.LgaPayments.path
    : sector("LAND")
    ? Routes.LandPayments.path
    : Routes.Selection.path;
  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="light"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={
            user.role === "user"
              ? Routes.Selection.path
              : Routes.DashboardOverview.path
          }
        >
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
          style={{ color: "wheat", backgroundColor: "wheat" }}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div
            className="sidebar-inner px-4 pt-3 bg-dark"
          //  style={{height:'90vh',overflowY: 'auto' }}
          >
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image
                    src={ProfilePicture}
                    className="card-img-top rounded-circle border-white"
                  />
                </div>
                <div className="d-block d-flex">
                  <h6>{user?.name}</h6>
                  <Button
                    as={Link}
                    variant="secondary"
                    size="xs"
                    to={Routes.Signin.path}
                    className="text-dark"
                    style={{ marginLeft: 10 }}
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                    Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              {!isPhoneSize && (
                <NavItem
                  title=""
                  link={
                    user.role === "user"
                      ? Routes.Selection.path
                      : Routes.DashboardOverview.path
                  }
                  image={ReactHero}
                  imageHeight={150}
                  imageWidth={150}
                />
              )}
              {getAccess("Dashboard") ? (
                <NavItem
                  title="Dashboard"
                  icon={faBoxOpen}
                  link={
                    extension
                      ? Routes.Selection.path
                      : Routes.DashboardOverview.path
                  }
                />
              ) : null}

              <Dropdown.Divider className="my-3 border-indigo" />
              {extension && isAdmin(location.pathname) ? (
                <>
                  <Dropdown.Divider className="my-3 border-indigo" />
                  <Nav className="flex-column pt-3 pt-md-0">
                    <NavItem
                      title="+ Add new tax"
                      link={Routes.CreateTax.path}
                    />
                  </Nav>
                </>
              ) : null}
              {extension}
              {links && links?.length ? (
                <>
                  {links.map((link, idx) => (
                    <Nav key={idx} className="flex-column pt-3 pt-md-0">
                      <NavItem
                        title={link.title}
                        link={link.path}
                        icon={link.icon}
                      />
                    </Nav>
                  ))}
                </>
              ) : null}

              {/* {user?.role === "developer" ? ( */}

              {getAccess("General Reports") ? (
                <CollapsableNavItem title="Reports" icon={faClock}>
                  <NavItem
                    title="General Reports"
                    icon={faClock}
                    link={Routes.GenerateReports.path}
                  />

                  <NavItem
                    title="Bank Report"
                    icon={faClock}
                    link={`${Routes.BankReport.path}`}
                  />
                  {/* {getAccess("MDA Reports") ? ( */}
                  <NavItem
                    title="Tax Report"
                    icon={faClock}
                    link={`${Routes.TaxReport.path}`}
                  />
                  <NavItem
                    title="Non Tax Report"
                    icon={faClock}
                    link={`${Routes.NonTaxReport.path}?sector=NON TAX`}
                  />
                  <NavItem
                    title="Land use Report"
                    icon={faClock}
                    link={`${Routes.LandReport.path}?sector=LAND`}
                  />
                  <NavItem
                    title="LGAs Report"
                    icon={faClock}
                    link={`${Routes.LGAReport.path}?sector=LGA`}
                  />
                  {/*  ) : null} */}
                </CollapsableNavItem>
              ) : null}
              {/* ) : null} */}
              {user?.rank === "Department Head" ||
                getAccess("Receipt Reports") ? (
                <NavItem
                  title="Receipt Reports"
                  icon={faHandHoldingUsd}
                  link={Routes.ReceiptReport.path}
                />
              ) : null}
              {user?.rank === "Department Head" ||
                getAccess("Collection Report") ? (
                <NavItem
                  title="Collection Report"
                  icon={faHandHoldingUsd}
                  link={Routes.CollectionReport.path}
                />
              ) : null}
              {user?.rank === "Department Head" ||
                getAccess("Receipt Reports") ? (
                <NavItem
                  title="Summary Reports"
                  icon={faClock}
                  link={Routes.SummaryReports.path}
                />
              ) : null}
              {!extension ? (
                <>
                  {/* {getAccess("MDA Reports") ? (
                    <NavItem
                      title="MDA Reports"
                      icon={faHandHoldingUsd}
                      link={Routes.MDAReport.path}
                    />
                  ) : null} */}
                  {getAccess("Admin Manager") || getAccess("Tax Admins") ? (
                    <NavItem
                      title="Admin Manager"
                      icon={faCog}
                      link={Routes.AdminsMngr.path}
                    />
                  ) : null}
                  {getAccess("Tax Payers") ? (
                    <NavItem
                      title="Tax Payers"
                      icon={faHandHoldingUsd}
                      link={Routes.TaxPayers.path}
                    />
                  ) : null}
                  {
                    getAccess("Tax Setup") ||
                      getAccess("TAX") ||
                      getAccess("NON TAX") ||
                      getAccess("VEHICLES") ||
                      getAccess("LAND") ||
                      getAccess("LGA") ||
                      getAccess("developer") ? (
                      <CollapsableNavItem title="Tax setup" icon={faTable}>
                        {getAccess("TAX") ? (
                          <NavItem
                            title="Tax"
                            icon={faCog}
                            link={Routes.TaxMngrTable.path}
                          />
                        ) : null}
                        {getAccess("NON TAX") ? (
                          <NavItem
                            title="Non Tax"
                            icon={faCog}
                            link={Routes.NonTaxSetting.path}
                          />
                        ) : null}
                        {getAccess("VEHICLES") ? (
                          <NavItem
                            title="Vehicles"
                            icon={faCog}
                            link={Routes.VehicleTaxSetting.path}
                          />
                        ) : null}
                        {getAccess("LAND") ? (
                          <NavItem
                            title="Land uses"
                            icon={faCog}
                            link={Routes.LandTaxSetting.path}
                          />
                        ) : null}
                        {getAccess("LGA") ? (
                          <NavItem
                            title="LGA Revenues"
                            icon={faCog}
                            link={Routes.LGATaxSetting.path}
                          />
                        ) : null}
                        {user?.role === "developer" ? (
                          <NavItem
                            title="Tax Manager (Dev)"
                            icon={faCog}
                            link={Routes.TaxManager.path}
                          />
                        ) : null}
                      </CollapsableNavItem>
                    ) : null}
                  {/* <NavItem
                    title="Taxes Chart"
                    icon={faCog}
                    link={Routes.Tree.path}
                  /> */}
                  <NavItem
                    title="Generate Receipts"
                    icon={faHandHoldingUsd}
                    link={Routes.GenerateReceipt.path}
                  />
                  <NavItem
                    title="Generate Invoice"
                    icon={faCog}
                    link={routeTo}
                  />
                  {user?.role === "agent" ? (
                    <>
                      <NavItem
                        title="Create Express Account"
                        icon={faHandHoldingUsd}
                        link={Routes.ExpressAcct.path}
                      />
                    </>
                  ) : null}

                  <NavItem
                    title="Reset Password"
                    icon={faCog}
                    link={Routes.AdminResetPassword.path}
                  />

                  {/* <CollapsableNavItem eventKey="tables/" title="Tables" icon={faTable}>
                <NavItem title="Bootstrap Table" icon={faTable} link={Routes.BootstrapTables.path} />
              </CollapsableNavItem> */}

                  {/* <CollapsableNavItem
                    eventKey="tables/"
                    title="Settings"
                    icon={faTable}
                  >
                    <NavItem
                      title="Add new taxes"
                      link={Routes.TaxMngr.path}
                    />
                    <NavItem
                      title="Tax manager"
                      link={Routes.TaxMngrTable.path}
                    /> */}
                  {/* <NavItem
                      title="Transactions"
                      icon={faHandHoldingUsd}
                      link={Routes.Transactions.path}
                    />
                    <NavItem
                      title="Tax Pay List"
                      icon={faHandHoldingUsd}
                      link={Routes.TaxPayers.path}
                    /> */}
                  {/* </CollapsableNavItem> */}

                  <Dropdown.Divider className="my-3 border-indigo" />
                </>
              ) : (
                ""
              )}
              {/* <CollapsableNavItem eventKey="examples/" title="Page Examples" icon={faFileAlt}>
                <NavItem title="Sign In" link={Routes.Signin.path} />
                <NavItem title="Sign Up" link={Routes.Signup.path} />
                <NavItem
                  title="Forgot password"
                  link={Routes.ForgotPassword.path}
                />
                <NavItem
                  title="Reset password"
                  link={Routes.ResetPassword.path}
                />
                <NavItem title="Lock" link={Routes.Lock.path} />
                <NavItem title="404 Not Found" link={Routes.NotFound.path} />
                <NavItem title="500 Server Error" link={Routes.ServerError.path} />
                
                </CollapsableNavItem> */}
              <Button
                onClick={() => dispatch(logout(history))}
                variant="danger"
              >
                Logout
              </Button>
            </Nav>
          </div>
          <div className="mx-5">
            {/* <NavItem title={<p><FontAwesomeIcon icon={faLock} className="me-2" /> Logout</p>} link={Routes.Selection.path} /> */}
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
