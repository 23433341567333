import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons"; // Import the clear icon
import { Form, Col, InputGroup, Button } from "@themesberg/react-bootstrap";

export default function SearchBar(props) {
  const {
    placeholder = "Search...",
    filterText = "",
    onFilterTextChange = (f) => f,
    _ref = null,
  } = props;

  const handleFilterTextChange = (e) => {
    onFilterTextChange(e.target.value);
  };

  const handleClearClick = () => {
    onFilterTextChange(""); // Clear the input field
  };

  return (
    <Col md={12} className={props.className}>
      <InputGroup>
        <InputGroup.Text>
          <FontAwesomeIcon icon={faSearch} />
        </InputGroup.Text>
        <Form.Control
          value={filterText}
          type="text"
          ref={_ref}
          name="filterText"
          onChange={handleFilterTextChange}
          placeholder={placeholder || "Search..."}
        />
        {filterText && ( // Show the clear button only if there is text in the input
          <InputGroup.Text>
            <Button className="p-0 m-0" variant="red" onClick={handleClearClick}>
              <FontAwesomeIcon 
              color="red"
              size="sm"
              icon={faTimesCircle} />
            </Button>
          </InputGroup.Text>
        )}
      </InputGroup>
    </Col>
  );
}
