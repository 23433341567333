export const Routes = {
  // pages
  // Presentation: { path: "/" },
  DashboardOverview: { path: "/dashboard/overview" },
  NonTaxPayment: { path: "/user/dashboard/non-tax-payments" },
  VehiclePayments: { path: "/user/dashboard/vehicle-payments" },
  CreateTax: { path: "/admin/dashboard/create-tax" },
  TaxPayment: { path: "/user/dashboard/tax-payments" },
  LgaPayments: { path: "/user/dashboard/lga-payments" },
  LandPayments: { path: "/user/dashboard/land-payments" },
  AdminSetup: { path: "/admin/dashboard/admins-manager" },
  Transactions: { path: "/transactions" },
  TaxPayerProfile: { path: "/admin/dashboard/tax-payer" },
  UserProfile: { path: "/user/dashboard/profile" },
  Profile: { path: "/user/profile" },
  UserTransactions: { path: "/user/dashboard/transactions" },
  NewResetPassword: { path: "/user/resetpassword-new" },
  AdminResetPassword: { path: "/resetpassword-new" },
  TaxPayerTransactions: { path: "/admin/dashboard/tax-payer-trx" },
  ReportTransaction: { path: "/report-transaction" },
  TaxPayers: { path: "/tax-report" },
  viewTax: { path: "/view-report" },
  printInvoice: { path: "/print-invoice" },
  paymentInvoice: { path: "/payment-invoice" },
  adminPaymentInvoice: { path: "/dashboard/payment-invoice" },
  adminPaymentReceipt: { path: "/dashboard/payment-receipt" },
  paymentReceipt: { path: "/payment-receipt" },
  // adminPaymentReceipt: { path: "/admin/payment-receipt" },
  ReceiptAdmin: { path: "/admin/payment-receipt" },
  Selection: { path: "/selection" },
  OnlinePayment: { path: "/online-payment" },

  MDAReport: { path: "/report-mdas" },
  PaymentLogs: { path: "/receipt-logs" },
  ReceiptReport: { path: "/receipt-report" },
  CollectionReport: { path: "/collection-report" },
  ReceiptReportDetails: { path: "/receipt-report-details" },
  MDAReportTransactions: { path: "/report-mdas/:mda" },
  Settings: { path: "/settings" },
  Upgrade: { path: "/upgrade" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Billing: { path: "/examples/billing" },
  Invoice: { path: "/examples/invoice" },
  Signin: { path: "/" },
  // ResetPas: { path: "/reset-pas" },
  Signup: { path: "/sign-up" },
  ForgotPassword: { path: "/forgot-password" },
  ResetPassword: { path: "/user/reset-password" },
  ResetPass: { path: "/reset-password" },
  RecoverCode: { path: "/recover-code" },
  Lock: { path: "/examples/lock" },
  NotFound: { path: "/examples/404" },
  ServerError: { path: "/examples/500" },

  // docs
  DocsOverview: { path: "/documentation/overview" },
  DocsDownload: { path: "/documentation/download" },
  DocsQuickStart: { path: "/documentation/quick-start" },
  DocsLicense: { path: "/documentation/license" },
  DocsFolderStructure: { path: "/documentation/folder-structure" },
  DocsBuild: { path: "/documentation/build-tools" },
  DocsChangelog: { path: "/documentation/changelog" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },
  TaxMngr: { path: "/admin/dashboard/tax-mngr" },
  TaxMngrTable: { path: "/admin/dashboard/tax-mngr-table" },
  NonTaxSetting: { path: "/admin/dashboard/non-tax-setting" },
  VehicleTaxSetting: { path: "/admin/dashboard/vichicle-tax-setting" },
  LandTaxSetting: { path: "/admin/dashboard/land-tax-setting" },
  LGATaxSetting: { path: "/admin/dashboard/lga-tax-setting" },
  GenerateReceipt: { path: "/admin/dashboard/generate-receipt" },
  ExpressAcct: { path: "/admin/dashboard/express-user" },
  ExpressAcct2: { path: "/user/express" },
  Tree: { path: "/admin/dashboard/tax-settings" },
  // Agent admin route
  AgentGenerateInvoice: { path: "/admin/dashboard/generate-invoice" },
  CreateTaxPayer: { path: "/admin/dashboard/create-tax-payer" },
  // Dont touch the following route is for Developer Admin:
  TaxManager: { path: "/admin/dashboard/tax-manager" },
  AdminsMngr: { path: "/admin/dashboard/admins-manager" },
  AgentTransactions: { path: "/agent/history" },
  GenerateReports: { path: "/admin/gen.reports" },
  BankReport: { path: "/admin/banks-reports" },
  TaxReport: { path: "/admin/tax-reports" },
  NonTaxReport: { path: "/admin/nontax-reports" },
  LGAReport: { path: "/admin/lga-reports" },
  LandReport: { path: "/admin/land-reports" },
  VehicleReport: { path: "/admin/mlo-reports " },
  SummaryReports: { path: "/admin/summary-reports" },

};
