import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logo from "../assets/img/kano.png";
import kigra from "../assets/img/kigra.jpg";
import moment from "moment";
import { toCurrency } from "../utils";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    alignContent:'center',
    justifyContent:'center',
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
  },
  row: {
    flexDirection: "row",
  },
  cell: {
    flexGrow: 1,
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    padding: 4,
    borderLeftColor: "#333",
    borderLeftWidth: 0.5,
    fontSize: 11,
    fontWeight: "700",
    textAlign: "justify",
  },
  header: {
    fontSize: 23,
    textAlign: "center",
    marginTop:30,
  },
  header2: {
    fontSize: 15,
  },
  logo: {
    width: "50px",
    height: "50px",
  },
  logo1: {
    width: "300px",
    height: "300px",
  },
  watermark: {
    display: "flex",
    opacity: 0.1,
    position: "absolute",
    top: 200,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  logoContainer:{
    display:'flex',
    position: "absolute",
    flexDirection:'column',
    marginTop: "1px",
    alignItems: "center",
    justifyContent: "center",
    marginBottom:'5px'
  }
});

const LegderPDF = ({ data = [], user = {} }) => (
  <Document>
    <Page orientation="landscape" size="A4" style={styles.page}>
      <View style={styles.watermark}>
        <Image src={logo} style={styles.logo1} />
      </View>
      <View
        style={styles.logoContainer}
      >
        <Image src={kigra} style={styles.logo} />
      </View>
      <View style={styles.section}>
        <Text style={[styles.header]}>
          KANO INTEGRATED REVENUE MANAGEMENT AND ASSURANCE SYSTEM (KIRMAS)
        </Text>
        <View style={styles.header2}>
          <Text>TAX PAYER: {user.name}</Text>
          <Text>TIN: {user.tin}</Text>
          <Text>NIN: {user.nin}</Text>
          <Text>TaxID: {user.taxID}</Text>
          <Text></Text>
        </View>
        <View style={styles.table}>
          {/* Table Headers */}
          <View style={styles.row}>
            <View style={[styles.cell, { width: "5%" }]}>
              <Text>SN</Text>
            </View>
            <View style={[styles.cell, { width: "8%" }]}>
              <Text>DATE</Text>
            </View>
            <View style={[styles.cell, { width: "17%" }]}>
              <Text>Ref No.</Text>
            </View>
            <View style={[styles.cell, { width: "50%" }]}>
              <Text>DESCRIPTION</Text>
            </View>
            <View style={[styles.cell, { width: "10%" }]}>
              <Text>AMOUNT</Text>
            </View>
            <View style={[styles.cell, { width: "10%", textAlign: "center" }]}>
              <Text>STATUS</Text>
            </View>
          </View>
          {data
            .filter((_) => _.amount > 0)
            .map((row, index) => (
              <View key={index} style={styles.row}>
                <View style={[styles.cell, { width: "5%" }]}>
                  <Text>{row.id}</Text>
                </View>
                <View style={[styles.cell, { width: "8%" }]}>
                  <Text>{moment(row.date).format('DD/MM/YYYY')}</Text>
                </View>
                <View style={[styles.cell, { width: "17%" }]}>
                  <Text>{row.ref_no}</Text>
                </View>
                <View style={[styles.cell, { width: "50%" }]}>
                  <Text>{row.description}</Text>
                </View>
                <View style={[styles.cell, { width: "10%", textAlign:'right' }]}>
                  <Text>{toCurrency(row.amount)}</Text>
                </View>
                <View
                  style={[styles.cell, { width: "10%", textAlign: "center" }]}
                >
                  <Text>{row.status === "paid" ? "Paid" : "Pending"}</Text>
                </View>
              </View>
            ))}
        </View>
      </View>
    </Page>
  </Document>
);

export default LegderPDF;
