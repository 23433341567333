import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Col,
  Row,
  Button,
  ButtonGroup,
  Table,
} from "@themesberg/react-bootstrap";
import { _fetchApi } from "../../redux/action/api";
import { Input, Label } from "reactstrap";
import CustomDropdown from "./components/TaxDropdown";
import { ExcelRenderer } from "react-excel-renderer";
import { formatNumber, toCurrency } from "../../utils";
import { SearchBar } from "../../components/UI";
import PaymentsContainer from "./components/PaymentsContainer";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
// import FlutterwavePayNow from "../../components/payment/FlutterwavePayNow";
import { Modal } from "@themesberg/react-bootstrap";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast from "react-hot-toast";
import SelectInput from "../components/SelectInput";
import { taxStations } from "../../utils/tax-stations";
import DaterangeSelector from "../dashboard/DaterangeSelector";
import CustomTypeahead from "../../components/UI/CustomTypeahead";
import { useDispatch, useSelector } from "react-redux";
import { WelcomeMessage } from "./LandPayments";
import { TAX_ACCOUNT } from "../../redux/action/type";
import useIsPhoneSize from "../../utils/useIsPhoneSize";
import ExpressAcct from "../ExpressAccount";
import { convertToMenu } from "./NonTaxPayments";

// Manually provide the rootData if not present in the data array
//   const menuData = convertToMenu(data, rootData);
//   console.log(menuData);

export default function TaxPayments() {
  const _form = {
    page_title: "TAX PAYMENTS",
    month: "",
    attachment: null,
    rows: [],
    economic_code: "",
    tax_parent_code: "",
    taxes: "",
    amount: "",
    sector: "TAX",
    from: "",
    to: "",
    tax_payer: "",
  };
  const [form, setForm] = useState(_form);
  const [MainTaxList, setMainTaxList] = useState([]);
  const [subTaxList, setSubTaxList] = useState([]);
  const [list, setList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchTax, setSearchTax] = useState("");
  const [presumptiveTaxList, setPresumptiveTaxList] = useState([]);
  const [selectedPresumptive, setSelectedPresumptive] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tax_payers, setTaxPayers] = useState([]);
  const { tax_account, user } = useSelector((p) => p.auth);
  const [tax_payer, setTapayer] = useState("");
  const [filterText, setFilterText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const dispatch = useDispatch();
  const handleFilterChange = (e) => {
    setFilterText(e);
  };
  const myRef = useRef(null);
  const rootData = MainTaxList.find((item) => item.tax_code === "TAX");

  const rearrangedTaxes = convertToMenu(MainTaxList, rootData);

  const TAX_MENU = rearrangedTaxes.concat([
    {
      title: "Presumptive tax",
      id: "Presumptive tax",
      children: [],
      has_child: true,
      economic_code: "12010504",
    },
  ]);
  const [showDefault, setShowDefault] = useState(false);
  const toggleModal = () => setShowDefault(!showDefault);

  const handleChange = ({
    target: { name, value, selected, checked, type },
  }) => {
    if (type === "checkbox") {
      if (checked) {
        setForm((prevState) => ({
          ...prevState,
          [name]: prevState[name] ? `${prevState[name]}, ${value}` : value,
        }));
      } else {
        setForm((prevState) => {
          if (prevState[name]) {
            const updatedValues = prevState[name]
              .split(", ")
              .filter((item) => item !== value);
            const updatedValue = updatedValues.length
              ? updatedValues.join(", ")
              : "";
            return { ...prevState, [name]: updatedValue };
          }
          return prevState;
        });
      }
    } else if (type === "select") {
      setForm((prevState) => ({ ...prevState, [name]: selected }));
    } else {
      setForm((prevState) => ({ ...prevState, [name]: value }));
    }
  };
  const handleFocus = (name) => {
    setTapayer(name);
    setTimeout(() => {
      toast.success("You can now close and search the tax payer by name or ID");
      toggleModal();
    }, 2000);
  };
  const getList = useCallback(() => {
    if (form.tax_code) {
      _fetchApi(
        `/kigra-taxes?query_type=select-sub&parent_code=${form.tax_code}`,
        (resp) => {
          if (resp.result && resp.result.length) {
            setSubTaxList(resp.result);
          } else {
            setSubTaxList([]);
          }
          setLoading(false);
        },
        () => {
          setSubTaxList([]);
          setLoading(false);
        }
      );
    }
  }, [form.tax_code]);

  useEffect(() => {
    getList();
  }, [getList]);

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      "/kigra-taxes?query_type=select-heads&sector=TAX",
      (resp) => {
        setMainTaxList(resp.result);
        setLoading(false);
      },
      () => {
        setMainTaxList([]);
        setLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    _fetchApi(
      "/kigra-taxes?query_type=presumptive&sector=TAX",
      (resp) => {
        setPresumptiveTaxList(resp.result);
      },
      (e) => {
        console.log(e);
      }
    );
  }, []);

  // useEffect(() => {
  //   // if (!form.from || !form.to) {
  //   //   console.error("Please select date");
  //   // } else

  //   // alert(JSON.stringify(form));
  //   if (form.title && form.from && form.to && form.amount) {
  //     setList((prevList) => [
  //       ...prevList,
  //       {
  //         ...form,
  //         tax_payer:
  //           tax_account?.account_type === "org"
  //             ? tax_account?.org_name
  //             : tax_account?.name,
  //         tax_fee: form.amount,
  //       },
  //     ]);
  //     setForm(_form);
  //   } else if (!form.parent && selected_taxes.length < 1) {
  //     toast.error("Select minimum of item");
  //   }
  // }, [form]);

  const handleAddList = (cb = (f) => f) => {
    // alert("bbbnbnbn")
    if (!form.from || !form.to) {
      toast.error("Please select date");
    } else {
      setList((prevList) => [
        {
          ...form,
          tax_payer:
            tax_account?.account_type === "org"
              ? tax_account?.org_name
              : tax_account?.name,
          tax_fee: form.amount,
          checked: true,
        },
      ]);
      cb();
    }
  };

  const handleAddList1 = (cb = (f) => f) => {
    if (!form.from || !form.to) {
      toast.error("Please select date");
    } else {
      cb();
    }
  };

  const getTaxPayers = useCallback(() => {
    if (tax_payer.length > 2) {
      _fetchApi(
        `/users/search-tax-payers?user_id=${tax_payer}&max=50`,
        (resp) => {
          if (resp.success) {
            setTaxPayers(resp.data);
          }
        }
      );
    } else if (tax_payer.length === 0) {
      _fetchApi(
        `/users/search-tax-payers?user_id=${tax_payer}&max=50`,
        (resp) => {
          if (resp.success) {
            setTaxPayers(resp.data);
          }
        }
      );
    }
  }, [tax_payer]);

  useEffect(() => {
    getTaxPayers();
  }, [getTaxPayers]);

  const handleTaxFeeChecked = ({ target: { value } }, item) => {
    setList([]);
    let updatedSelectedPresumptive = [...selectedPresumptive];

    if (
      updatedSelectedPresumptive.some(
        (selectedItem) => selectedItem.title === item.title
      )
    ) {
      // If an item with the same title exists, update its tax_fee
      updatedSelectedPresumptive = updatedSelectedPresumptive.map(
        (selectedItem) => {
          if (selectedItem.title === item.title) {
            return {
              id: selectedItem.id,
              title: selectedItem.title === "" ? selectedItem.title : "",
              tax_fee: value,
              checked: false,
              economic_code: item.economic_code,
            };
          }
          return selectedItem;
        }
      );
    } else {
      updatedSelectedPresumptive.push({
        id: item.id,
        title: item.title,
        tax_fee: value,
        checked: true,
        economic_code: item.economic_code,
      });
    }

    setForm((prevState) => ({
      ...prevState,
      taxes: updatedSelectedPresumptive
        .map((selectedItem) => `${selectedItem.title}|${selectedItem.tax_fee}`)
        .join(", "),
    }));
    setSelectedPresumptive(updatedSelectedPresumptive);
    // handleAddList();
  };

  const handleFileChange = ({ target }) => {
    const file = target.files[0];

    ExcelRenderer(file, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        const rows = resp.rows;
        setForm((p) => ({ ...p, rows }));
        console.log(rows);
      }
    });
  };
  const new_form = Object.assign({}, form, {
    tax_code: form.title,
    tax_parent_code: form.title,
  });

  useEffect(() => {
    const results = [];
    const searchInNestedData = (data) => {
      for (const item of data) {
        if (
          searchText.length > 0 &&
          item.title &&
          item.title.toLowerCase().includes(searchText.toLowerCase())
        ) {
          results.push(item);
        }
        if (item.children) {
          searchInNestedData(item.children);
        }
      }
    };

    searchInNestedData(TAX_MENU);
    setSearchResults(results);
  }, [searchText]);
  const handleCheckBox = (tax) => {
    let arr = [];
    subTaxList.forEach((item) => {
      if (item.id === tax.id) {
        arr.push({
          ...item,
          amount: item.tax_fee || item.amount,
          sqr_meter: item.checked ? 1 : item.sqr_meter || 1,
          checked: !item.checked,
          department: form.department ? form.department : null,
        });
      } else {
        arr.push(item);
      }
    });
    setSubTaxList(arr);
  };
  const handleTaxRateChenged = (e, tax) => {
    const { value } = e.target;
    let arr = [];
    subTaxList.forEach((item) => {
      if (item.id === tax.id) {
        arr.push({
          ...item,
          sqr_meter: value,
          amount: parseFloat(value <= 0 ? 1 : value) * Math.max(tax.tax_fee, 1),
          checked: parseInt(value) > 0 ? true : false,
          department: form.department ? form.department : null,
        });
      } else {
        arr.push(item);
      }
    });
    setSubTaxList(arr);
  };
  const handleAmountChange = (e, tax) => {
    let arr = [];
    subTaxList.forEach((item) => {
      if (item.id === tax.id) {
        arr.push({
          ...item,
          amount: e.target.value,
          checked: parseInt(e.target.value) > 0 ? true : false,
          department: form.department ? form.department : null,
        });
      } else {
        arr.push(item);
      }
    });
    setSubTaxList(arr);
  };

  const filteredPresumptiveTaxList = searchTax.length
    ? presumptiveTaxList.filter((item) => {
        return item.title.toLowerCase().includes(searchTax.toLowerCase());
      })
    : presumptiveTaxList;

  const selected_taxes = list.length
    ? list
    : selectedPresumptive.filter((item) => item.title !== "");

  const handleDateRangeChange = ({ target: { name, value } }) => {
    if (name === "from") {
      setForm((p) => ({ ...p, from: value }));
    } else if (name === "to") {
      setForm((p) => ({ ...p, to: value }));
    }
  };
  const isPhoneSize = useIsPhoneSize();
  const [open, setOpen] = useState(isPhoneSize ? true : false);
  const openModal = () => setOpen(isPhoneSize ? !open : false);

  const handleDeleteRow = (ind) => {};

  const filteredTaxList = filterText.length
    ? subTaxList.filter((item) => {
        return item.title.toLowerCase().includes(filterText.toLowerCase());
      })
    : subTaxList;
  return (
    <PaymentsContainer
      handleAddList={
        form.title === "Presumptive tax" ? handleAddList1 : handleAddList
      }
      open={open}
      openModal={openModal}
      form={new_form}
      search={
        <SearchBar
          filterText={searchText}
          onFilterTextChange={(val) => setSearchText(val)}
          placeholder="Search by tax name"
          className="bg-dark text-white"
        />
      }
      menu={
        loading ? (
          <SkeletonTheme baseColor="#f1f1f1" highlightColor={"#ffffff"}>
            <p>
              <Skeleton count={10} height={50} />
            </p>
          </SkeletonTheme>
        ) : (
          <CustomDropdown
            form={form}
            setForm={setForm}
            data={
              searchResults.length && searchResults[0]?.id
                ? searchResults
                : TAX_MENU
            }
            openModal={openModal}
          />
        )
      }
      selected_taxes={selected_taxes
        .filter((item) => item.title !== "")
        .map((p) => ({
          ...p,
          sector: form.sector,
          mda_var: form.mda_var,
          mda_val: form.mda_val,
          checked: true,
        }))}
      handleDeleteRow={handleDeleteRow}
    >
      {!form.tax_code &&
      selected_taxes.length < 1 &&
      form.title !== "Presumptive tax" &&
      !form.economic_code ? (
        <>
          <WelcomeMessage />
        </>
      ) : (
        <Row className="m-0 p-0">
          <Col style={{ paddingBottom: "50px" }}>
            <Row className="m-0">
              {/* {JSON.stringify(TAX_MENU)} */}
              <Col md={12} className="">
                <DaterangeSelector
                  from={form.from}
                  to={form.to}
                  handleChange={handleDateRangeChange}
                />
                {subTaxList.length && form.title !== "Presumptive tax" ? (
                  <>
                    <Col md={12}>
                      {user.role !== "user" ? (
                        <Row>
                          <Col md={6} className="mb-2">
                            <CustomTypeahead
                              onInputChange={(e) => {
                                setTapayer(e);
                                console.log(e, "TYPED");
                              }}
                              _ref={myRef}
                              labelKey={(e) =>
                                `${
                                  e.account_type === "org" ? e.org_name : e.name
                                }(${e.taxID})`
                              }
                              onChange={(e) => {
                                if (e && e[0]?.id) {
                                  dispatch({
                                    type: TAX_ACCOUNT,
                                    payload: e[0],
                                  });
                                }
                              }}
                              // onInputChange={(e) => {
                              //   setTapayer(e);
                              // }}
                              clearButton
                              col={12}
                              label={`Select Tax payer`}
                              options={tax_payers}
                              placeholder="Search TaxID | Phone No."
                            />
                          </Col>
                          <Col>
                            <br />
                            <Button
                              onClick={() => toggleModal()}
                              className="bg-white mt-2 ml--2 text-dark"
                            >
                              <FontAwesomeIcon icon={faPlus} /> Create Tax payer
                            </Button>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col>
                            <Label>Tax Payer</Label>{" "}
                            <Input
                              type="text"
                              disabled="disable"
                              value={
                                tax_account.account_type === "org"
                                  ? tax_account.org_name
                                  : tax_account.name
                              }
                            />
                          </Col>
                        </Row>
                      )}
                    </Col>
                    <br />
                    <SearchBar
                      filterText={filterText}
                      onFilterTextChange={handleFilterChange}
                      placeholder="Search tax"
                    />
                    <br />
                    <Col md={12} sm={12} lg={12} className="">
                      <table
                        size="sm"
                        style={{ width: "100%", border: "1px solid black" }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                borderWidth: 1,
                                borderColor: "black",
                                fontWeight: "bold",
                                margin: "50px",
                              }}
                              className=" m-5 p-2"
                              width="5%"
                            >
                              <center> SN</center>
                            </th>
                            <th
                              style={{
                                borderWidth: 1,
                                borderColor: "black",
                                fontWeight: "bold",
                              }}
                              width="40%"
                              className="m-4"
                            >
                              <center> Description</center>
                            </th>
                            {subTaxList.length &&
                            subTaxList.some((tax) => tax.uom === "per m2") ? (
                              <th
                                style={{
                                  borderWidth: 1,
                                  borderColor: "black",
                                  fontWeight: "bold",
                                }}
                                width="15%"
                                className="text-right"
                              >
                                <center> Rate (Meter Squre)</center>
                              </th>
                            ) : null}

                            <th
                              style={{
                                borderWidth: 1,
                                borderColor: "black",
                                fontWeight: "bold",
                              }}
                              width="15%"
                              className="text-right"
                            >
                              <center> Amount</center>
                            </th>

                            <th
                              style={{
                                borderWidth: 1,
                                borderColor: "black",
                                fontWeight: "bold",
                              }}
                              width="15%"
                              className="text-right"
                            >
                              <center> Action</center>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredTaxList?.map((item, idx) => (
                            <tr key={idx}>
                              <td
                                className="text-center"
                                style={{ borderWidth: 1, borderColor: "black" }}
                              >
                                {idx + 1}
                              </td>
                              <td
                                style={{ borderWidth: 1, borderColor: "black" }}
                                className="m-5 p-2"
                              >
                                {item.title}
                              </td>
                              {filteredTaxList.length &&
                              filteredTaxList.some(
                                (tax) => tax.uom === "per m2"
                              ) ? (
                                <td
                                  style={{
                                    borderWidth: 1,
                                    borderColor: "black",
                                  }}
                                  className="text-right px-2"
                                >
                                  {item.uom === "per m2" ? (
                                    <input
                                      onChange={(e) =>
                                        handleTaxRateChenged(e, item)
                                      }
                                      value={item.sqr_meter}
                                      type="number"
                                      name="sqr_meter"
                                      className="text-center"
                                    />
                                  ) : null}
                                </td>
                              ) : null}
                              <td
                                className="text-right m-5 p-2"
                                style={{ borderWidth: 1, borderColor: "black" }}
                              >
                                {item.amount <= 0 || item.tax_fee <= 0 ? (
                                  <input
                                    value={item.amount}
                                    onChange={(e) => {
                                      handleAmountChange(e, item);
                                    }}
                                    type="number"
                                    style={{ width: "80px" }}
                                    name="tax_fee"
                                    className="p-0 m-0 text-center"
                                  />
                                ) : item.amount > 0 ? (
                                  formatNumber(item.amount)
                                ) : (
                                  formatNumber(item.tax_fee)
                                )}
                              </td>
                              <td
                                style={{ borderWidth: 1, borderColor: "black" }}
                                className="text-center"
                              >
                                <input
                                  className="large-check"
                                  onChange={(e) => handleCheckBox(item)}
                                  checked={item.checked}
                                  value={item.id}
                                  type="checkbox"
                                  name="taxes"
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Col>
                  </>
                ) : (
                  <div style={{ paddingBottom: "50px" }}>
                    {form.title === "Personal Tax (Paye) - General" ? (
                      <Row className="m-0">
                        <ButtonGroup classNa me="w-100 pb-4">
                          <Button
                            style={{
                              background: form.ipis ? "" : "#fff",
                              color: form.ipis ? "#FFF" : "#000",
                            }}
                            outline
                            onClick={() =>
                              setForm((prevState) => ({
                                ...prevState,
                                ipis: true,
                              }))
                            }
                            active={form.ipis}
                            className="col-md-12"
                          >
                            IPIS
                          </Button>
                          <Button
                            style={{
                              background: form.ipis ? "#fff" : "",
                              color: form.ipis ? "#000" : "#FFF",
                            }}
                            outline
                            onClick={() =>
                              setForm((prevState) => ({
                                ...prevState,
                                ipis: false,
                              }))
                            }
                            active={!form.ipis}
                            className="col-md-12"
                          >
                            NON IPIS
                          </Button>
                        </ButtonGroup>
                      </Row>
                    ) : null}
                    <Col md={12}>
                      {user.role !== "user" ? (
                        <Row>
                          <Col md="8">
                            <Row>
                              <Col md={8}>
                                <CustomTypeahead
                                  onInputChange={(e) => {
                                    setTapayer(e);
                                    console.log(e, "TYPED");
                                  }}
                                  labelKey={(e) =>
                                    `${
                                      e.account_type === "org"
                                        ? e.org_name
                                        : e.name
                                    }(${e.taxID})`
                                  }
                                  onChange={(e) => {
                                    if (e && e[0]?.id) {
                                      dispatch({
                                        type: TAX_ACCOUNT,
                                        payload: e[0],
                                      });
                                    }
                                  }}
                                  // onInputChange={(e) => {
                                  //   setTapayer(e);
                                  // }}
                                  clearButton
                                  col={12}
                                  label={`Select Tax payer`}
                                  options={tax_payers}
                                  placeholder="Search TaxID | Phone No."
                                />
                              </Col>
                              <Col md={4}>
                                <br />
                                <Button
                                  onClick={() => toggleModal()}
                                  className="bg-white mt-2 ml--2 text-dark"
                                >
                                  <FontAwesomeIcon icon={faPlus} /> Create Tax
                                  payer
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={4}>
                            <SelectInput
                              name="tax_station"
                              label="Select Tax Station"
                              onChange={handleChange}
                              options={taxStations}
                            />
                          </Col>
                        </Row>
                      ) : (
                        <>
                          <Label>Tax Payer</Label>{" "}
                          <Input
                            type="text"
                            disabled="disable"
                            value={
                              tax_account?.account_type === "org"
                                ? tax_account?.org_name
                                : tax_account?.name
                            }
                          />
                        </>
                      )}
                    </Col>
                    <br />
                    {form.title === "Presumptive tax" ? (
                      <>
                        <Row>
                          <Col md={10}>
                            <SearchBar
                              filterText={searchTax}
                              onFilterTextChange={(val) => setSearchTax(val)}
                              placeholder="Search item"
                              className="bg-white text-dark"
                            />
                          </Col>
                          <Col />
                        </Row>
                        <table style={{ width: "100%" }}>
                          <thead
                            style={{
                              borderWidth: 1,
                              borderColor: "black",
                              fontWeight: "bolder",
                            }}
                          >
                            <tr>
                              <th
                                className="text-center"
                                width="5%"
                                style={{ borderWidth: 1, borderColor: "black" }}
                              >
                                S/N
                              </th>
                              <th
                                className="text-center"
                                width="70%"
                                style={{ borderWidth: 1, borderColor: "black" }}
                              >
                                Title/Business
                              </th>
                              <th
                                className="text-center"
                                width="5%"
                                style={{ borderWidth: 1, borderColor: "black" }}
                              >
                                Micro Business
                              </th>
                              <th
                                className="text-center"
                                width="5%"
                                style={{ borderWidth: 1, borderColor: "black" }}
                              >
                                Small Business
                              </th>
                              <th
                                className="text-center"
                                width="5%"
                                style={{ borderWidth: 1, borderColor: "black" }}
                              >
                                Medium Business
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredPresumptiveTaxList?.map((item, idx) => (
                              <tr key={idx}>
                                <td
                                  style={{
                                    borderWidth: 1,
                                    borderColor: "black",
                                  }}
                                  className="text-center"
                                >
                                  {idx + 1}
                                </td>
                                <td
                                  style={{
                                    borderWidth: 1,
                                    borderColor: "black",
                                    width: 100,
                                  }}
                                >
                                  {item.title}
                                </td>
                                <td
                                  style={{
                                    borderWidth: 1,
                                    borderColor: "black",
                                  }}
                                  className="text-center"
                                >
                                  <div>{toCurrency(item.micro)}</div>
                                  <input
                                    className="large-check"
                                    onChange={(e) =>
                                      handleTaxFeeChecked(e, item, "micro")
                                    }
                                    checked={selectedPresumptive.some(
                                      (selectedItem) =>
                                        selectedItem.title === item.title &&
                                        parseFloat(selectedItem.tax_fee) ===
                                          parseFloat(item.micro)
                                    )}
                                    value={item.micro}
                                    type="checkbox"
                                    name={`micro-${item.id}`}
                                  />
                                </td>
                                <td
                                  style={{
                                    borderWidth: 1,
                                    borderColor: "black",
                                  }}
                                  className="text-center"
                                >
                                  <div>{toCurrency(item.small)}</div>
                                  <input
                                    className="large-check"
                                    onChange={(e) =>
                                      handleTaxFeeChecked(e, item, "small")
                                    }
                                    checked={selectedPresumptive.some(
                                      (selectedItem) =>
                                        selectedItem.title === item.title &&
                                        parseFloat(selectedItem.tax_fee) ===
                                          parseFloat(item.small)
                                    )}
                                    value={item.small}
                                    type="checkbox"
                                    name={`small-${item.id}`}
                                  />
                                </td>
                                <td
                                  style={{
                                    borderWidth: 1,
                                    borderColor: "black",
                                  }}
                                  className="text-center"
                                >
                                  <div>{toCurrency(item.medium)}</div>
                                  <input
                                    className="large-check"
                                    onChange={(e) =>
                                      handleTaxFeeChecked(e, item, "medium")
                                    }
                                    checked={selectedPresumptive.some(
                                      (selectedItem) =>
                                        selectedItem.title === item.title &&
                                        parseFloat(selectedItem.tax_fee) ===
                                          parseFloat(item.medium)
                                    )}
                                    value={item.medium}
                                    type="checkbox"
                                    name={`medium-${item.id}`}
                                  />
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td
                                style={{
                                  borderWidth: 1,
                                  borderColor: "black",
                                }}
                              >
                                {presumptiveTaxList.length}
                              </td>
                              <td
                                style={{
                                  borderWidth: 1,
                                  borderColor: "black",
                                }}
                              >
                                Others
                              </td>
                              <td
                                style={{
                                  borderWidth: 1,
                                  borderColor: "black",
                                }}
                                colSpan="3"
                              >
                                <Input
                                  type="text"
                                  name="amount"
                                  placeholder="Amount"
                                  value={form.amount}
                                  onChange={handleChange}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    ) : (
                      ""
                    )}

                    <Row>
                      {form.title !== "Presumptive tax" ? (
                        <Col md={6}>
                          <Label>Amount (N)</Label>
                          <Input
                            type="text"
                            name="amount"
                            placeholder="Amount"
                            value={form.amount}
                            onChange={handleChange}
                          />
                        </Col>
                      ) : null}
                      {/* <Col md={6}>
                      <Label>Attachment</Label>
                      <Input
                        type="file"
                        style={{
                          border: "1px solid gray",
                          borderRadius: "4px",
                          paddingRight: "90%",
                          outline: "none", // Remove the default outline
                        }}
                        name="attachment"
                        // value={form.attachment}
                        onChange={handleFileChange}
                        placeholder="Upload attachments"
                      />  
                      </Col>*/}
                    </Row>

                    {form.title === "Paye Federal Govt. Establishments" ? (
                      <Col md={6}>
                        <Label>IPIS</Label>
                        <Input
                          type="text"
                          name="mda_val"
                          placeholder="IPIS No."
                          value={form.mda_val}
                          onChange={({ target: { value } }) =>
                            setForm((p) => ({
                              ...p,
                              mda_val: value,
                              mda_var: "IPIS No.",
                            }))
                          }
                        />
                      </Col>
                    ) : null}
                  </div>
                )}
              </Col>
            </Row>

            {/* {form.title === "Presumptive tax" ? null : (
              <>
                <Col md={12} className="text-center mt-4 mb-1">
                  <Button
                    onClick={() => handleAddList(() => {})}
                    style={{ background: "" }}
                  >
                    Add to list
                  </Button>
                </Col>
                <Col md={12}>
                  {form.rows && form.rows.length > 0 ? (
                    <Table sm style={{ width: "90%", height: "100%" }}>
                      <thead style={{ borderWidth: 1, borderColor: "black" }}>
                        <tr>
                          {form.rows[0].map((header, index) => (
                            <th
                              style={{ borderWidth: 1, borderColor: "black" }}
                              key={index}
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {form.rows.slice(1).map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                              <td
                                style={{ borderWidth: 1, borderColor: "black" }}
                                key={cellIndex}
                              >
                                {cell}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : list.length > 0 ? (
                    <Table size="md" style={{ width: "90%", height: "100%" }}>
                      <thead style={{ borderWidth: 1, borderColor: "black" }}>
                        <tr>
                          <th
                            style={{ borderWidth: 1, borderColor: "black" }}
                            className="text-center"
                          >
                            SN
                          </th>
                          <th
                            style={{ borderWidth: 1, borderColor: "black" }}
                            className="text-center"
                          >
                            TAX PAYER
                          </th>
                          <th
                            style={{ borderWidth: 1, borderColor: "black" }}
                            className="text-center"
                          >
                            TAX DESCRIPTION{" "}
                          </th>
                          <th
                            style={{ borderWidth: 1, borderColor: "black" }}
                            className="text-center"
                          >
                            AMOUNT
                          </th>
                          <th
                            style={{ borderWidth: 1, borderColor: "black" }}
                            className="text-center"
                          >
                            ACTION
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            <td
                              style={{ borderWidth: 1, borderColor: "black" }}
                              key={rowIndex}
                              className="text-center"
                            >
                              {rowIndex + 1}
                            </td>
                            <td
                              style={{ borderWidth: 1, borderColor: "black" }}
                            >
                              {row.tax_payer}
                            </td>
                            <td
                              style={{ borderWidth: 1, borderColor: "black" }}
                            >
                              {row.title}
                            </td>
                            <td
                              style={{ borderWidth: 1, borderColor: "black" }}
                              className="text-end"
                            >
                              {toCurrency(row.amount)}
                            </td>
                            <td
                              style={{ borderWidth: 1, borderColor: "black" }}
                            >
                              <Button
                                onClick={() => handleDeleteItem(rowIndex)}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : null}
                </Col>
              </>
            )} */}
          </Col>
        </Row>
      )}
      <Modal as={Modal.Dialog} centered show={showDefault} size="lg">
        <Modal.Header>
          <Modal.Title className="h6">EXPRESS TAX PAYER FORM</Modal.Title>
          <Button
            variant="danger"
            className="bg-danger text-white"
            aria-label="Close"
            onClick={toggleModal}
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <ExpressAcct isModal={true} handleFocus={handleFocus} ref={myRef} />
        </Modal.Body>
      </Modal>
    </PaymentsContainer>
  );
}
