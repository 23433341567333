import React, { useCallback, useEffect, useState } from "react";
import { _fetchApi } from "../redux/action/api";
import { Card, CardBody, Spinner, Table } from "reactstrap";
import { SearchBar } from "../components/UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row, ButtonGroup } from "@themesberg/react-bootstrap";
import { faArrowCircleLeft, faEye } from "@fortawesome/free-solid-svg-icons";
import UserTransactions from "./UserTransactions";

function TaxPayer() {
  const [loading, setLoading] = useState(false);
  const [taxPayer, setTaxPayer] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [type, setType] = useState("individual");
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);

  const getData = useCallback(() => {
    setLoading(true);
    _fetchApi(
      "/get-tax-payer",
      (res) => {
        if (res.success) {
          setLoading(false);
          setData(res.results);
        }
      },
      (error) => {
        setLoading(false);
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const filterByType = data?.filter((item) => item?.account_type === type);

  const filtered_data = filterByType?.filter(
    (item) =>
      item?.name.toLowerCase().includes(filterText.toLowerCase()) ||
      item?.taxID.toString().includes(filterText)
  );

  const totalTaxPayer = filterByType.length;
  return (
    <Card>
      {show ? (
        <Row>
          <Col>
            <Button onClick={() => setShow(false)}>
              <FontAwesomeIcon icon={faArrowCircleLeft} /> Back
            </Button>
          </Col>
        </Row>
      ) : null}
      {show ? (
        <UserTransactions taxPayer={taxPayer} viewOnly />
      ) : (
        <CardBody>
          <div className="d-flex justify-content-between">
            <div>
              <h4>{type.toUpperCase()} TAX PAYERS</h4>
            </div>
            <div className="mb-2">
              <ButtonGroup aria-label="Basic example">
                <Button
                  variant={type === "individual" ? "dark" : "secondary"}
                  onClick={() => setType("individual")}
                >
                  Individual
                </Button>
                <Button
                  variant={type === "org" ? "dark" : "secondary"}
                  onClick={() => setType("org")}
                >
                  Organization
                </Button>
                {/* 
                NOTE: There is NO THING call "BANK TAX PAYER" 
                BANKs are also  ORGs. they can act as "AGENT(s)" 
                <Button
                  variant={type === "bank" ? "dark" : "secondary"}
                  onClick={() => setType("bank")}
                >
                  Bank
                </Button> */}
              </ButtonGroup>
            </div>
          </div>

          <div className="mb-2">
            <SearchBar
              filterText={filterText}
              onFilterTextChange={(val) => setFilterText(val)}
              placeholder="Search Tax Payer by Name or Tax ID"
            />
          </div>
          <div className="text-right">
            Total tax payers: <b>{totalTaxPayer}</b>{" "}
          </div>
          {loading && (
            <div>
              <Spinner />
              Loading...
            </div>
          )}
          <Table bordered responsive className="table-zebra" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th className="text-center">ACTION</th>
                <th className="text-center">Tax ID</th>
                <th className="text-center">TIN</th>
                <th className="text-center">Name</th>
                <th className="text-center">Email</th>
                <th className="text-center">State</th>
                <th className="text-center">LGA</th>
              </tr>
            </thead>
            <tbody>
              {filtered_data?.map((tax_payer, index) => (
                <tr key={index}>
                  <td className="text-center">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => {
                        setShow(true);
                        setTaxPayer(tax_payer);
                      }}
                      style={{ marginRight: 5 }}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </Button>
                  </td>
                  <td className="text-center">{tax_payer.taxID}</td>
                  <td className="text-center">{tax_payer.tin}</td>
                  <td>
                    {tax_payer.account_type === "org"
                      ? tax_payer.org_name
                      : tax_payer.name}
                  </td>
                  <td>{tax_payer.email}</td>
                  <td>{tax_payer.state}</td>
                  <td>{tax_payer.lga}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      )}
    </Card>
  );
}

export default TaxPayer;
