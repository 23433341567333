import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faArrowUp,
  // faEdit,
  faExternalLinkAlt,
  // faEye,
  // faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Nav,
  Card,
  Image,
  Button,
  Table,
  // Dropdown,
  ProgressBar,
  Pagination,
  Modal,
  Badge,
  // ButtonGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { Routes } from "../routes";
import { pageVisits, pageTraffic, pageRanking } from "../data/tables";
import transactions from "../data/transactions";
import commands from "../data/commands";
import { useHistory } from "react-router-dom";
// import { useSelector } from "react-redux";
import { _fetchApi } from "../redux/action/api";
// import { toast } from "react-hot-toast";
import { formatNumber, toCurrency, toParagraph } from "../utils";
import { useSelector } from "react-redux";
import PaymentLogs from "../pages/PaymentLogs";

export const CURRENCY = "₦";

const ValueChange = ({ value, suffix }) => {
  const valueIcon = value < 0 ? faAngleDown : faAngleUp;
  const valueTxtColor = value < 0 ? "text-danger" : "text-success";

  return value ? (
    <span className={valueTxtColor}>
      <FontAwesomeIcon icon={valueIcon} />
      <span className="fw-bold ms-1">
        {Math.abs(value)}
        {suffix}
      </span>
    </span>
  ) : (
    "--"
  );
};

export const PageVisitsTable = () => {
  const TableRow = (props) => {
    const { pageName, views, returnValue, bounceRate } = props;
    const bounceIcon = bounceRate < 0 ? faArrowDown : faArrowUp;
    const bounceTxtColor = bounceRate < 0 ? "text-danger" : "text-success";

    return (
      <tr>
        <th scope="row">{pageName}</th>
        <td>{views}</td>
        <td>{returnValue}</td>
        <td>
          <FontAwesomeIcon
            icon={bounceIcon}
            className={`${bounceTxtColor} me-3`}
          />
          {Math.abs(bounceRate)}%
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5>Page visits</h5>
          </Col>
          <Col className="text-end">
            <Button variant="secondary" size="sm">
              See all
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <Table responsive className="align-items-center table-flush">
        <thead className="thead-light">
          <tr>
            <th scope="col">Page name</th>
            <th scope="col">Page Views</th>
            <th scope="col">Page Value</th>
            <th scope="col">Bounce rate</th>
          </tr>
        </thead>
        <tbody>
          {pageVisits.map((pv) => (
            <TableRow key={`page-visit-${pv.id}`} {...pv} />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export const PageTrafficTable = () => {
  const TableRow = (props) => {
    const {
      id,
      source,
      sourceIcon,
      sourceIconColor,
      sourceType,
      category,
      rank,
      trafficShare,
      change,
    } = props;

    return (
      <tr>
        <td>
          <Card.Link href="#" className="text-primary fw-bold">
            {id}
          </Card.Link>
        </td>
        <td className="fw-bold">
          <FontAwesomeIcon
            icon={sourceIcon}
            className={`icon icon-xs text-${sourceIconColor} w-30`}
          />
          {source}
        </td>
        <td>{sourceType}</td>
        <td>{category ? category : "--"}</td>
        <td>{rank ? rank : "--"}</td>
        <td>
          <Row className="d-flex align-items-center">
            <Col xs={12} xl={2} className="px-0">
              <small className="fw-bold">{trafficShare}%</small>
            </Col>
            <Col xs={12} xl={10} className="px-0 px-xl-1">
              <ProgressBar
                variant="primary"
                className="progress-lg mb-0"
                now={trafficShare}
                min={0}
                max={100}
              />
            </Col>
          </Row>
        </td>
        <td>
          <ValueChange value={change} suffix="%" />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm mb-4">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">#</th>
              <th className="border-0">Traffic Source</th>
              <th className="border-0">Source Type</th>
              <th className="border-0">Category</th>
              <th className="border-0">Global Rank</th>
              <th className="border-0">Traffic Share</th>
              <th className="border-0">Change</th>
            </tr>
          </thead>
          <tbody>
            {pageTraffic.map((pt) => (
              <TableRow key={`page-traffic-${pt.id}`} {...pt} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const RankingTable = () => {
  const TableRow = (props) => {
    const {
      country,
      countryImage,
      overallRank,
      overallRankChange,
      travelRank,
      travelRankChange,
      widgetsRank,
      widgetsRankChange,
    } = props;

    return (
      <tr>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center">
            <Image
              src={countryImage}
              className="image-small rounded-circle me-2"
            />
            <div>
              <span className="h6">{country}</span>
            </div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">{overallRank ? overallRank : "-"}</td>
        <td className="border-0">
          <ValueChange value={overallRankChange} />
        </td>
        <td className="fw-bold border-0">{travelRank ? travelRank : "-"}</td>
        <td className="border-0">
          <ValueChange value={travelRankChange} />
        </td>
        <td className="fw-bold border-0">{widgetsRank ? widgetsRank : "-"}</td>
        <td className="border-0">
          <ValueChange value={widgetsRankChange} />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Country</th>
              <th className="border-0">All</th>
              <th className="border-0">All Change</th>
              <th className="border-0">Travel & Local</th>
              <th className="border-0">Travel & Local Change</th>
              <th className="border-0">Widgets</th>
              <th className="border-0">Widgets Change</th>
            </tr>
          </thead>
          <tbody>
            {pageRanking.map((r) => (
              <TableRow key={`ranking-${r.id}`} {...r} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
export function TransactionsTable({
  setRef = (f) => f,
  data = [],
  _ref = null,
  viewOnly = false,
}) {
  const loggedInUser = useSelector((state) => state.auth.user);
  const TableRow = (props) => {
    const {
      reference_number,
      tax_payer,
      amount,
      created_at,
      description,
      status,
      date_from,
      date_to,
    } = props;
    const statusVariant =
      status.toLowerCase() === "paid"
        ? "secondary"
        : status.toLowerCase() === "saved"
          ? "warning"
          : status.toLowerCase() === "Canceled"
            ? "danger"
            : "primary";
    const history = useHistory();
    return (
      <tr>
        <td>
        <Button variant="primary" size="sm" className="me-1 text-white">
              <Card.Link
                as={Link}
                to={
                  ["user", "agent"].includes(loggedInUser.role)
                    ? props.status === "saved"
                      ? `${Routes.paymentInvoice.path}?ref_no=${reference_number}`
                      : `${Routes.paymentInvoice.path}?ref_no=${reference_number}`
                    : props.status === "saved"
                    ? `${Routes.adminPaymentInvoice.path}?ref_no=${reference_number}`
                    : `${Routes.adminPaymentInvoice.path}?ref_no=${reference_number}`
                }
                className="fw-normal text-whitex"
              >
                Print {props.status === "saved" ? "Invoice" : "Receipt"}
              </Card.Link>
            </Button>
          {/* {JSON.stringify(loggedInUser.role)} */}
          {/* {loggedInUser.role === "user" ? (
         <></>
          ) : (
            <Button
              onClick={() =>
                loggedInUser.role === "admin"
                  ? history.push(
                    `/dashboard/payment-${props.status === "saved" ? "invoice" : "receipt"
                    }?ref_no=${reference_number}`
                  )
                  : setRef(reference_number)
              }
              variant="primary"
              size="sm"
              className="me-1 text-white"
            >
              Print {props.status === "saved" ? "Invoice" : "Receipt"}
            </Button>
          )} */}
          {/* <Button variant="primary" size="sm" className="me-1 text-white">
                 <Card.Link
                   as={Link}
                   to={`${Routes.paymentReceipt.path}?ref_no=${reference_number}`}
                   className="fw-normal text-whitex"
                 >
                   Print Receipt
                 </Card.Link>
               </Button> */}
        </td>
        {/* <td>
          <span className="fw-normal">{description}</span>
        </td> */}
        <td className="text-center">{description}</td>
        <td className="text-center">{reference_number}</td>
        <td className="text-center">
          <span className="fw-normal">
            {moment(created_at).format("DD/MM/YYYY")}
          </span>
        </td>
        <td className="text-center">
          <span className="fw-normal">
            {moment(date_from).format("DD/MM/YYYY")} -{" "}
            {moment(date_to).format("DD/MM/YYYY")}
          </span>
        </td>
        <td className="text-end pe-2">
          <span className="fw-normal text-end pe-2">
            {formatNumber(amount)}
          </span>
        </td>
        <td className="text-center">
          <span className={`fw-normal text-${statusVariant}`}>
            {status.toLowerCase()}
          </span>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        {/* {JSON.stringify(data)} */}
        <center>{data[0]?.tax_payer}</center>
        <table
          ref={_ref}
          style={{ border: "" }}
          width={"100"}
          className="user-table align-items-center table-hover"
        >
          <thead>
            <tr>
              <th width="10%" className="border-bottom">
                Action
              </th>
              <th width="30%" className="border-bottom  text-left">
                Description
              </th>
              <th className="border-bottom  text-center">Transaction ID</th>
              <th className="border-bottom text-center">Issue Date</th>
              <th className="border-bottom text-center">Period Date</th>
              <th className="border-bottom text-right">Amount ({CURRENCY})</th>
              <th className="border-bottom text-right">Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((t) => (
              <TableRow key={`transaction-${t.reference_number}`} {...t} />
            ))}
          </tbody>
        </table>
      </Card.Body>
    </Card>
  );
}

export function TransactionLogs({
  setRef = (f) => f,
  data = [],
  _ref = null,
  // viewOnly = false,
}) {
  const TableRow = (props) => {
    const location = useHistory();
    const {
      reference_number,
      tax_payer,
      amount,
      description,
      transaction_date,
      status,
      created_at,
      printed_at = "",
    } = props;
    const statusVariant =
      status.toLowerCase() == "paid"
        ? "secondary":status==="success"?"secondary"
        : status.toLowerCase() === "saved"
          ? "warning"
          : status.toLowerCase() === "Canceled"
            ? "danger"
            : "primary";
    return (
      <tr>
        <td className="text-left px-2">
          {moment(
            location.location.pathname === "/receipt-report"
              ? printed_at
              : created_at
          ).format("DD/MM/YYYY")}
        </td>
        <td className="text-left">{toParagraph(tax_payer)}</td>
        <td className="text-left px-2">{reference_number} </td>
        <td className="text-left">{toParagraph(description)}</td>
        <td className="text-end pe-2">
          <span className="fw-normal text-end pe-2">
            {formatNumber(amount)}
          </span>
        </td>
        <td>
          <Badge className={`text-center text-${statusVariant} `}>
            {status === "saved" ? "Invoice" : status}
          </Badge>
        </td>
        {/* <td  className="text-center"> */}
        {/* {status != "saved" ? (
            <>
              {printed > 0 ? (
                <Button
                  size="sm"
                  onClick={() => {
                    setShow(true);
                    setCurrent(props);
                  }}
                >
                  View
                </Button>
              ) : (
                <Button
                  onClick={() =>
                    loggedInUser.role === "admin"
                      ? history.push(
                          `/dashboard/payment-receipt?ref_no=${reference_number}`
                        )
                      : setRef(reference_number)
                  }
                  variant="primary"
                  size="sm"
                  className="me-1 text-white"
                >
                  Print
                </Button>
              )}
            </>
          ) : (
            status
          )} */}
        {/* </td> */}
      </tr>
    );
  };
  const [sort, setSort] = useState({
    type: "string",
    order: "asc",
    name: "description",
  });

  const sortData = (type, property, order) => (a, b) => {
    const modifier = order === "desc" ? -1 : 1;

    if (type === "date") {
      const dateA = new Date(a[property]);
      const dateB = new Date(b[property]);
      return (dateA - dateB) * modifier;
    } else if (type === "string") {
      const stringA = (a[property] || "").toLowerCase();
      const stringB = (b[property] || "").toLowerCase();
      return (stringA < stringB ? -1 : stringA > stringB ? 1 : 0) * modifier;
    } else {
      const numA = a[property] || 0;
      const numB = b[property] || 0;
      return (numA - numB) * modifier;
    }
  };

  const toggleOrder = () => {
    if (sort.order === "asc") {
      setSort((p) => ({ ...p, order: "desc" }));
    } else {
      setSort((p) => ({ ...p, order: "asc" }));
    }
  };

  return (
    <>
      {/* {JSON.stringify(sort)} */}
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          <table
            ref={_ref}
            style={{ border: "" }}
            width={"100"}
            className="user-table align-items-center table-hover"
          >
            <thead>
              <tr>
                <th width="10%" className="border-bottom  text-left">
                  <a
                    href="#"
                    onClick={() => {
                      setSort((p) => ({
                        ...p,
                        name: "created_at",
                        type: "date",
                        order: sort.order,
                      }));
                      toggleOrder();
                    }}
                  >
                    Date
                  </a>
                </th>
                <th width="20%" className="border-bottom text-left">
                  <a
                    href="#"
                    onClick={() => {
                      setSort((p) => ({
                        ...p,
                        name: "tax_payer",
                        type: "string",
                        order: sort.order,
                      }));
                      toggleOrder();
                    }}
                  >
                    Tax payer
                  </a>
                </th>
                <th className="border-bottom  text-center">Transaction ID</th>
                <th width="40%" className="border-bottom  text-left">
                  <a
                    href="#"
                    onClick={() => {
                      setSort((p) => ({
                        ...p,
                        type: "string",
                        name: "description",
                        order: sort.order,
                      }));
                      toggleOrder();
                    }}
                  >
                    Description
                  </a>
                </th>
                <th className="border-bottom text-right">
                  Amount ({CURRENCY})
                </th>
                <th className="border-bottom text-right">Status</th>
              </tr>
            </thead>
            <tbody>
              {data
                .sort(sortData(sort.type, sort.name, sort.order))
                .map((t, idx) => (
                  <TableRow key={idx} {...t} />
                ))}
            </tbody>
          </table>
        </Card.Body>
      </Card>
    </>
  );
}

export const ViewTable = ({ results = [] }) => {
  const totalTransactions = transactions.length;

  const TableRow = (props) => {
    const {
      reference_number,
      transaction_id,
      date,
      description,
      amount,
      status,
    } = props;
    const statusVariant =
      status === "success"
        ? "success"
        : status === "Due"
          ? "warning"
          : status === "Canceled"
            ? "danger"
            : "primary";

    return (
      <tr>
        <td>
          <Card.Link as={Link} to={Routes.Invoice.path} className="fw-normal">
            {transaction_id}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">{description}</span>
        </td>
        <td>
          <span className="fw-normal">{date}</span>
        </td>
        <td>
          <span className="fw-normal">{amount}</span>
        </td>

        <td>
          <span className={`fw-normal text-${statusVariant}`}>{status}</span>
        </td>
        <td>
          <Button variant="primary" size="sm" className="me-1 text-white">
            <Card.Link
              as={Link}
              to={Routes.paymentInvoice.path}
              className="fw-normal text-whitex"
            >
              Print Receipt
            </Card.Link>
          </Button>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Action</th>
              <th className="border-bottom">Transaction Id</th>
              <th className="border-bottom">Description</th>
              <th className="border-bottom">Date</th>
              <th className="border-bottom">Amount</th>
            </tr>
          </thead>
          <tbody>
            {results.map((t) => (
              <TableRow key={`transaction-${t.reference_number}`} {...t} />
            ))}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>Previous</Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>Next</Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            Showing <b>{totalTransactions}</b> out of <b>25</b> entries
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};
export const TaxPayTable = ({ results = [] }) => {
  const totalTransactions = transactions.length;

  const TableRow = (props) => {
    const {
      name,
      phone,
      taxID,
      account_type,
      created_at,
      updated_at,
      lga,
      status,
    } = props;
    const statusVariant =
      status === "Paid"
        ? "success"
        : status === "Due"
          ? "warning"
          : status === "Canceled"
            ? "danger"
            : "primary";

    const navigate = useHistory();

    return (
      <tr>
        <td>
          <Card.Link as={Link} to={Routes.Invoice.path} className="fw-normal">
            {account_type}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">{name}</span>
        </td>
        <td>
          <span className="fw-normal">{phone}</span>
        </td>
        <td>
          <span className="fw-normal">{created_at}</span>
        </td>
        <td>
          <span className="fw-normal">{taxID}</span>
        </td>
        <td>
          <span className={`fw-normal text-${statusVariant}`}>{lga}</span>
        </td>
        <td>
          <Button variant="primary" size="sm" className="me-1 text-white">
            <Card.Link
              as={Link}
              to={Routes.viewTax.path}
              className="fw-normal text-whitex"
            >
              View details
            </Card.Link>
          </Button>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Account Type</th>
              <th className="border-bottom">Full Name</th>
              <th className="border-bottom">Phone Number</th>
              <th className="border-bottom">Date</th>
              <th className="border-bottom">Tax ID</th>
              <th className="border-bottom">LGA</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody>
            {results.map((t) => (
              <TableRow key={`transaction-${t.reference_number}`} {...t} />
            ))}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>Previous</Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>Next</Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            Showing <b>{totalTransactions}</b> out of <b>25</b> entries
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export const CommandsTable = () => {
  const TableRow = (props) => {
    const { name, usage = [], description, link } = props;

    return (
      <tr>
        <td className="border-0" style={{ width: "5%" }}>
          <code>{name}</code>
        </td>
        <td className="fw-bold border-0" style={{ width: "5%" }}>
          <ul className="ps-0">
            {usage.map((u) => (
              <ol key={u} className="ps-0">
                <code>{u}</code>
              </ol>
            ))}
          </ul>
        </td>
        <td className="border-0" style={{ width: "50%" }}>
          <pre className="m-0 p-0">{description}</pre>
        </td>
        <td className="border-0" style={{ width: "40%" }}>
          <pre>
            <Card.Link href={link} target="_blank">
              Read More{" "}
              <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
            </Card.Link>
          </pre>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="p-0">
        <Table
          responsive
          className="table-centered rounded"
          style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
        >
          <thead className="thead-light">
            <tr>
              <th className="border-0" style={{ width: "5%" }}>
                Name
              </th>
              <th className="border-0" style={{ width: "5%" }}>
                Usage
              </th>
              <th className="border-0" style={{ width: "50%" }}>
                Description
              </th>
              <th className="border-0" style={{ width: "40%" }}>
                Extra
              </th>
            </tr>
          </thead>
          <tbody>
            {commands.map((c) => (
              <TableRow key={`command-${c.id}`} {...c} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
