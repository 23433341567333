import { ADD_PAYMENT, EMPTY_CART } from "../action/type";

const defaultState = {
  paymentsList: [],
};

const kigraReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ADD_PAYMENT:
      return {
        ...state,
        paymentsList: [...state.paymentsList, action.payload],
      };

    case EMPTY_CART:
      return {
        ...state,
        paymentsList: [],
      };

    default:
      return state;
  }
};

export default kigraReducer;
