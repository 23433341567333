import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import { Workbox } from "workbox-window";

import "./scss/volt_v10.css";
import "./assets/css/main.css";
import "react-datetime/css/react-datetime.css";
import "react-loading-skeleton/dist/skeleton.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import store from "./redux/store";
import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";
import Notifications from "./Notifications";
// import "./service-worker";

function App() {
  // removing server worker from the app
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister();

        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(async (names) => {
            await Promise.all(names.map((name) => caches.delete(name)));
          });
        }
      });
    }
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <Toaster />
        <Notifications>
          <HomePage />
        </Notifications>
      </BrowserRouter>
    </Provider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
