import React, { useState, useEffect, useCallback } from "react";
import { Col, Row } from "@themesberg/react-bootstrap";
import { _fetchApi } from "../../redux/action/api";
import { Table } from "reactstrap";
import { SearchBar } from "../../components/UI";
import { formatNumber, toCurrency } from "../../utils";
import PaymentsContainer from "./components/PaymentsContainer";
import "./checkbox.css";
import CustomDropdown from "./components/TaxDropdown";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { WelcomeMessage } from "./LandPayments";
import useIsPhoneSize from "../../utils/useIsPhoneSize";
import { convertToMenu } from "./NonTaxPayments";

export default function VehiclePayments() {
  const _form = {
    page_title: "VEHICLE PAYMENTS",
    type: "",
    month: "",
    org: "",
    amount: "",
    sector: "",
    tax_id: "",
    step: 0,
    attachment: null,
    rows: [],
    type_child: "",
    tax_code: "",
    tax_parent_code: "",
    taxes: "",
    selected: [],
    sector: "VEHICLES",
    isLastParent: false,
  };
  const [form, setForm] = useState(_form);
  const [filterText, setFilterText] = useState("");
  const [mainTaxes, setMainTaxes] = useState([]);
  const [subTaxList, setSubTaxList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  // const user = useSelector((state) => state.auth.user);

  const handleFilterChange = (e) => {
    setFilterText(e);
  };

  const handleChange = ({
    target: { name, value, selected, checked, type },
  }) => {
    if (type === "checkbox") {
      if (checked) {
        setForm((prevState) => ({
          ...prevState,
          [name]: prevState[name] ? `${prevState[name]}, ${value}` : value,
        }));
      } else {
        setForm((prevState) => {
          if (prevState[name]) {
            const updatedValues = prevState[name]
              .split(", ")
              .filter((item) => item !== value);
            const updatedValue = updatedValues.length
              ? updatedValues.join(", ")
              : "";
            return { ...prevState, [name]: updatedValue };
          }
          return prevState;
        });
      }
    } else if (type === "select") {
      setForm((prevState) => ({ ...prevState, [name]: selected }));
    } else {
      setForm((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const filteredTaxList = filterText.length
    ? subTaxList.filter((item) => {
        return (
          // item.economic_code
          //   .includes(filterText) ||
          item.title.toLowerCase().includes(filterText.toLowerCase())
        );
      })
    : subTaxList;

  const SELECTED_TAX_ITEMS = form.taxes
    ? subTaxList.filter((item) => {
        return form.taxes.includes(item.title);
      })
    : [];

  const getList1 = useCallback((tax_code) => {
    setLoading1(true);
    _fetchApi(
      `/kigra-taxes?query_type=select-sub&parent_code=${tax_code}`,
      (resp) => {
        if (resp.result.length) {
          setSubTaxList(resp.result);
          setForm((p) => ({
            ...p,
            taxes: resp.result
              ?.filter((item) => item.default === "checked")
              ?.map((item) => item.title)
              ?.join(", "),
          }));
          setLoading1(false);
        }
        setLoading1(false);
      },
      (err) => {
        console.error(err);
        setLoading1(false);
      }
    );
  }, []);

  useEffect(() => {
    getList1(form.tax_code);
  }, [getList1, form.tax_code]);
  const getList = useCallback(() => {
    setLoading(true);
    _fetchApi(
      "/kigra-taxes?query_type=select-heads&sector=VEHICLES",
      (resp) => {
        setMainTaxes(resp.result);
        setLoading(false);
      },
      () => {
        setMainTaxes([]);
        setLoading(false);
      }
    );
  }, []);
  useEffect(() => {
    getList();
  }, [getList]);

  useEffect(() => {
    const _selected = subTaxList
      .filter((t) => t.default === "checked")
      ?.map((item) => item.title)
      ?.join(", ");
    setForm((p) => ({
      ...p,
      taxes: _selected,
    }));
  }, [subTaxList]);

  // Manually provide the rootData if not present in the data array
  const rootData = mainTaxes.find((item) => item.tax_code === "VEHICLES");
  //   const menuData = convertToMenu(data, rootData);
  //   console.log(menuData);
  const rearrangedArray = convertToMenu(mainTaxes, rootData);

  // console.log({ rearrangedArray, rootData });

  const filteredArray = rearrangedArray.flatMap((parent) => {
    // Check if the parent title includes the search text
    if (parent.title.toLowerCase().includes(searchText.toLowerCase())) {
      return [parent]; // Include the parent if there's a match
    }

    // Check if any of the children titles include the search text
    if (parent.children) {
      const matchingChildren = parent.children.filter((child) =>
        child.title.toLowerCase().includes(searchText.toLowerCase())
      );

      if (matchingChildren.length > 0) {
        return [
          {
            ...parent,
            children: matchingChildren,
          },
        ]; // Include the parent with matching children if any child matches
      }
    }

    return []; // Exclude the parent if no match found in title or children titles
  });

  const isPhoneSize = useIsPhoneSize();
  const [open, setOpen] = useState(isPhoneSize ? true : false);
  const openModal = () => setOpen(isPhoneSize ? !open : false);

  return (
    <PaymentsContainer
      open={open}
      openModal={openModal}
      form={form}
      search={
        <SearchBar
          filterText={searchText}
          onFilterTextChange={(val) => setSearchText(val)}
          placeholder="Search by tax name"
          className="bg-dark text-white"
        />
      }
      menu={
        loading ? (
          <SkeletonTheme baseColor="#f1f1f1" highlightColor={"#ffffff"}>
            <p>
              <Skeleton count={10} height={50} />
            </p>
          </SkeletonTheme>
        ) : (
          <div className="p-1">
            <CustomDropdown
              form={form}
              setForm={setForm}
              data={filteredArray}
              openModal={openModal}
            />
          </div>
        )
      }
      selected_taxes={SELECTED_TAX_ITEMS.map((p) => ({
        ...p,
        sector: form.sector,
      }))}
    >
      {/* {JSON.stringify(form, filteredTaxList)} */}
      {form.tax_code ? (
        <Row className="m-0 p-0">
          <Col className="p-0 mb-5" style={{ zIndex: 999 }}>
            <div>
              {/* <hr /> */}
              {loading1 ? (
                <SkeletonTheme baseColor="#f1f1f1" highlightColor={"#ffffff"}>
                  <p>
                    <Skeleton count={10} height={45} />
                  </p>
                </SkeletonTheme>
              ) : (
                <>
                  {" "}
                  <div className="mb-2">
                    <SearchBar
                      filterText={filterText}
                      onFilterTextChange={handleFilterChange}
                      placeholder="Search tax"
                    />
                  </div>
                  <div className="text-end">
                    Total Amount:{" "}
                    {formatNumber(
                      SELECTED_TAX_ITEMS.reduce(
                        (a, b) => a + parseFloat(b.tax_fee),
                        0
                      )
                    )}
                  </div>{" "}
                  <Col md={12} className="">
                    <Table responsive hover size="sm" style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th
                            style={{ borderWidth: 1, borderColor: "black" }}
                            width="5%"
                          >
                            SN
                          </th>
                          <th
                            style={{ borderWidth: 1, borderColor: "black" }}
                            width="80%"
                          >
                            Title
                          </th>
                          <th style={{ borderWidth: 1, borderColor: "black" }}>
                            Amount (₦){" "}
                          </th>
                          <th
                            style={{ borderWidth: 1, borderColor: "black" }}
                            width="5%"
                            className="text-right"
                          >
                            SELECT
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredTaxList?.map((item, idx) => (
                          <tr key={idx}>
                            <td
                              style={{ borderWidth: 1, borderColor: "black" }}
                              className="text-center"
                            >
                              {idx + 1}
                            </td>
                            <td
                              style={{ borderWidth: 1, borderColor: "black" }}
                            >
                              {item.title}
                            </td>
                            <td
                              style={{ borderWidth: 1, borderColor: "black" }}
                              className="text-end"
                            >
                              {formatNumber(item.tax_fee)}
                            </td>
                            <td
                              style={{ borderWidth: 1, borderColor: "black" }}
                              className="text-center"
                            >
                              <input
                                className="large-check"
                                onChange={
                                  item.default === "checked"
                                    ? null
                                    : handleChange
                                }
                                checked={
                                  form.taxes.includes(item.title) ||
                                  item.default === "checked"
                                }
                                value={item.title}
                                type="checkbox"
                                name="taxes"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </>
              )}
            </div>
          </Col>
        </Row>
      ) : (
        <WelcomeMessage />
      )}
    </PaymentsContainer>
  );
}
