import React from "react";
import { Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import logo from "../assets/img/kano.png";
import logo1 from "../assets/img/kigra.jpg";
import { formatNumber, toNairaWords, toParagraph, toWords } from "../utils";
import moment from "moment";
import { toWordsconver } from "../redux/action/api";
import DM_SANS_NORMAL from "../assets/DM_Sans/static/DMSans_24pt-SemiBold.ttf";
import DM_SANS_BOLD from "../assets/DM_Sans/static/DMSans_24pt-Bold.ttf";
import DM_SANS_ITALIC from "../assets/DM_Sans/static/DMSans-Italic.ttf";
import Signature from "../assets/img/mdsign.png";
// Font.register({ family: 'FamilyName', src: DM_SANS, fontStyle: 'normal', fontWeight: 'normal', fonts?: [] });
Font.register({
  family: "DM_SANS",
  fonts: [
    { src: DM_SANS_NORMAL, fontWeight: 700 },
    {
      src: DM_SANS_BOLD,
      fontStyle: "bold",
      // fontWeight: "bold",
    },
    {
      src: DM_SANS_ITALIC,
      fontStyle: "italic",
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    // padding: "10px",
    marginHorizontal: 15,
    marginVertical: 15,
    // marginTop:15,
    fontSize: "16px",
    pageBreakInside: "avoid",
  },
  header: {
    textAlign: "center",
    // fontSize: "25px",
    fontWeight: "bolder",
    // marginTop: -40,
  },
  logo: {
    width: "50px",
    height: "50px",
  },
  logo1: {
    width: "300px",
    height: "300px",
  },
  watermark: {
    opacity: 0.1,
    position: "absolute",
    top: 200,
    left: 170,
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  watermarkText: { fontSize: "50px", transform: "rotate(60deg)" },
  itemContainer: {
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "",
    border: "1px solid #000",
  },
  itemContainer2: {
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "row",
    marginBottom: 7,
  },
  borderBox: {
    border: "1px solid #000",
  },
  qrcodeContainer: {
    textAlign: "center",
    marginTop: "0px",
    alignSelf: "center",
  },
  signatureContainer: {
    textAlign: "center",
    marginTop: "0px",
    alignSelf: "center",
  },
  qrcodeImage: {
    maxWidth: "120px",
    height: "70px",
  },
  signatureImage: {
    width: "120px",
    height: "30px",
  },
  textNormal: {
    fontSize: "15px",
    textAlign: "right",
  },
  hederContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    // height:"100%",
    // marginTop: 120,
  },
});

const AdminNewReceipt = ({
  data = [],
  user = {},
  qrcode,
  total = 0,
  label = "ORIGINAL",
}) => {
  return (
    <View wrap={false} style={styles.page}>
      <View style={styles.hederContainer}>
        <View
          style={{
            width: "40%",
            marginRight: 10,
            marginTop: 148,
          }}
        >
          <View style={styles.itemContainer2}>
            <Text
              style={{
                fontSize: 10.5,
                fontFamily: "DM_SANS",
              }}
            >
              Transaction ID: {data[0]?.reference_number}
            </Text>
          </View>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11 }}>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                Payer Name:
              </Text>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                {data[0]?.tax_payer || user.org_name || user.name}
              </Text>
            </View>
          </View>
          <View style={styles.itemContainer2}>
            <Text
              style={{
                fontSize: 10.5,
                fontFamily: "DM_SANS",
              }}
            >
              KNID: {user.kn_id}
            </Text>
          </View>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11 }}>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                Revenue Head:
              </Text>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                {data[0]?.description}
              </Text>
            </View>
          </View>
          <View style={styles.itemContainer2}>
            <Text style={{ fontSize: 11 }}>{data[0]?.tax_parent_code}</Text>
          </View>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11 }}>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                Payment Validation:
              </Text>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                {data[0]?.logId}
              </Text>
            </View>
          </View>
          {/* <View style={styles.itemContainer2}>
            <Text style={{ fontSize: 11 }}>
              Date: {moment(data[0]?.updatedAt).format("DD/MM/YYYY")}
            </Text>
          </View>

          <View style={styles.itemContainer2}>
            <Text style={{ fontSize: 11 }}>
              Date: {moment(data[0]?.updatedAt).format("HH:MM:SS:A")}
            </Text>
          </View> */}

          <View style={styles.itemContainer2}>
            <Text
              style={{
                fontSize: 10.5,
                fontFamily: "DM_SANS",
                fontWeight: "bold",
              }}
            >
              Amount: N{formatNumber(total)}
            </Text>
          </View>
        </View>
        <View style={{ width: "40%", marginRight: 5, marginTop: 148 }}>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11 }}>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                Amount in Words:
              </Text>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                {toWordsconver(total.toString().split(".")[0])?.toUpperCase()}
                NAIRA
                {total.toString().split(".")[1] !== "00"
                  ? ` and ${toWordsconver(total.toString().split(".")[1]).toUpperCase()} KOBO`
                  : null}{" "}
                ONLY
              </Text>
            </View>
          </View>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11 }}>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                Payer Address:
              </Text>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                { user.office_addres ||user.address}
              </Text>
            </View>
          </View>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11 }}>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                MDA:{" "}
              </Text>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                {data[0]?.mda_name}
              </Text>
            </View>
          </View>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11 }}>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                {" "}
                Payment Period:{" "}
              </Text>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                {data[0]?.date_from && data[0]?.date_to
                  ? `${moment(data[0]?.date_from).format(
                      "D  MMMM, YYYY"
                    )} - ${moment(data[0]?.date_to).format("D  MMMM, YYYY")}`
                  : moment(data[0]?.transaction_date).format("D  MMMM, YYYY")}
              </Text>
            </View>
          </View>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11 }}>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                Printed On:
              </Text>
              <Text
                style={{
                  fontSize: 10.5,
                  fontFamily: "DM_SANS",
                }}
              >
                {moment().format("D - MMMM - YYYY hh:mm:ss A")}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ width: "20%", marginTop: 150, marginLeft: 49 }}>
          <View style={styles.qrcodeContainer}>
            <Image src={qrcode} style={styles.qrcodeImage} />
          </View>
          <View style={styles.signatureContainer}>
            <Image src={Signature} style={styles.signatureImage} />
          </View>
          <Text
            style={{
              fontSize: 9,
              marginBottom: 5,
              textAlign: "center",
              marginTop: 5,
              fontFamily: "DM_SANS",
            }}
          >
            Sani Abdulkadir Dambo
          </Text>
          <Text
            style={{
              textAlign: "center",
              fontSize: 9,
              fontFamily: "DM_SANS",
            }}
          >
            EXECUTIVE CHAIRMAN [KIRS]
          </Text>
        </View>
      </View>
    </View>
  );
};

export default AdminNewReceipt;
