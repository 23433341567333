import React, { useState } from "react";
import { Card, CardBody, Button, CardHeader, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { FaPlus, FaUser } from "react-icons/fa";
import { Row, Modal } from "@themesberg/react-bootstrap";
import ExpressAcct from "../pages/ExpressAccount";
import "./account.css";
export default function Accounts() {
  const { user, tax_account } = useSelector((p) => p.auth);
  const [showDefault, setShowDefault] = useState(false);
  const toggleModal = () => setShowDefault(!showDefault);

  const handleFocus = (name) => {
    setTimeout(() => {
      //   toast.success("You can now close and search the tax payer by name or ID");
      toggleModal();
      // myRef.current.focus();
    }, 2000);
  };
  return (
    <div className="row mb-5">
      <Col md={4}></Col>
      <Col md={4} className="mt-5">
        <Card className="shadow">
          <CardHeader>
            <b>Signin with Kirmas with</b>
          </CardHeader>
          <CardBody>
            <h4 className="text-center">Choose and account</h4>
            <h6 className="text-center mb-5">
              to continue to <span style={{ color: "blue" }}>Kirmas</span>
            </h6>
            <Row className>
              <div className="account">
                <div className="d-flex   ">
                  <div className="">
                    <img
                      src={require("../images/Ahmad-removebg-preview.png")}
                      alt="profile"
                      style={{ width: "50px", cursor: "pointer" }}
                      className="ml-5"
                    />
                  </div>
                  <div className="">
                    <span className="font-weight-bold">{user && user?.name}</span>
                    <br />
                    <span className="text-black-50">
                      TAx ID: {user && user?.taxID}
                    </span>
                  </div>
                </div>
                <hr />
              </div>

              <div className="account">
                <div className="d-flex   ">
                  <div className="">
                    <img
                      src={require("../images/Ahmad-removebg-preview.png")}
                      alt="profile"
                      style={{ width: "50px", cursor: "pointer" }}
                      className="mr-4"
                    />
                  </div>
                  <div className="">
                    <span className="font-weight-bold">Abdulsalm Abubakar</span>
                    <br />
                    <span className="text-black-50">
                      TAx ID: {user && user?.taxID}
                    </span>
                  </div>
                </div>
                <hr />
              </div>

              <div
                onClick={() => toggleModal()}
                className=" shodow btn btn-primary mt-4  "
              >
                <span>Add Account</span>
              </div>
            </Row>

            <Modal as={Modal.Dialog} centered show={showDefault} size="lg">
              <Modal.Header>
                <Modal.Title className="h6">EXPRESS TAX PAYER FORM</Modal.Title>
                <Button
                  variant="danger"
                  className="bg-danger text-white"
                  aria-label="Close"
                  onClick={toggleModal}
                >
                  X
                </Button>
              </Modal.Header>
              <Modal.Body>
                <ExpressAcct isModal={true} handleFocus={handleFocus} />
              </Modal.Body>
            </Modal>
          </CardBody>
        </Card>
      </Col>
      <Col md={4}></Col>
    </div>
  );
}
