import React from "react";

function PrintWrapper(props) {
  return (
    <>
      <style>
        {`@media print {
              table {
                font-size: 75%;
                table-layout: fixed;
                width: 100%;
              }
              
              table {
                border-collapse: collapse;
                border-spacing: 2px;
              }
              
              th,
              td {
                border-width: 1px;
                padding: 0.5em;
                position: relative;
                text-align: left;
              }
              
              th,
                td {
                    border-radius: 0.25em;
                    border-style: solid;
                }
                
                th {
                    background: #EEE;
                    border-color: #BBB;
                }
                
                 {
                    border-color: #DDD;
                }
                .text-right {
                    text-align: right;
                }
                .text-center {
                    text-align: center;
                }
                .font-weight-bold {
                    font-weight: bold;
                }
                .print-start{
                    margin: 2em;
                    margin-top: 4em;
                }
                .print-only{
                  display: block;
                }
                th, td .print-only{
                  display: table-cell;
                }
                .no-print{
                  display: none;
                }
                
              .image{
                height: 5rem;
                width: 6rem;
                align-items: center;
                align-self: center;
                margin-right: 2rem
              }
            }

            @media screen {
              .print-only{
                display: none;
              } 
              
              .no-print{
                display: block;
              } 
              th, td .no-print{
                display: table-cell;
              } 
           }   
          `}
      </style>

      <div className="print-start">{props.children}</div>
    </>
  );
}

export default PrintWrapper;
