import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import DM_SANS_NORMAL from "../assets/DM_Sans/static/DMSans_24pt-SemiBold.ttf";
import DM_SANS_BOLD from "../assets/DM_Sans/static/DMSans_24pt-Bold.ttf";
import DM_SANS_ITALIC from "../assets/DM_Sans/static/DMSans-Italic.ttf";
import { formatNumber } from "../utils";
import moment from "moment";

Font.register({
  family: "DM_SANS",
  fonts: [
    { src: DM_SANS_NORMAL, fontWeight: 700 },
    {
      src: DM_SANS_BOLD,
      fontStyle: "bold",
    },
    {
      src: DM_SANS_ITALIC,
      fontStyle: "italic",
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    width: "100%",
    flexDirection: "row",
    fontFamily: "DM_SANS",
  },
  image: {
    width: 100,
    marginLeft: 4,
    marginRight: 4,
    height: "90vh",
  },
  header: {
    backgroundColor: "#0B3A42",
    width: "100%",
    marginTop: 46,
    height: 70,
    paddingTop: 10,
    textAlign: "center",
    color: "#fff",
    fontSize: 21,
    fontWeight: "bold",
    // fontFamily: ''
  },
  secondHeader: {
    backgroundColor: "#04252A",
    width: "100%",
    padding: 5,
    marginTop: -4,
  },
  thirdHeader: {
    backgroundColor: "#ABCF39",
    width: "55%",
    padding: 10,
    marginTop: -10,
    marginLeft: "auto",
  },
  secondimage: {
    marginTop: 60,
    marginLeft: 105,
    marginBottom: 40,
  },
  image1: {
    width: 200,
  },
  table: {
    margin: "auto",
    width: "80%",
    border: "1px solid #000",
    fontSize: 12,
    textTransform: "uppercase",
    marginTop: 20,
    marginLeft: 50,
    flexDirection: "row",
    marginBottom: 0,
  },
  footer: {
    flexDirection: "row",
    marginTop: 27,
    width: "120%",
    marginLeft: -99,
  },
  secondpage: {
    margin: 60,
    width: "100%",
  },
});

export const CertificateOfCompletion = ({
  data = [],
  user = {},
  tax_account = [],
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.body}>
          <Image
            src={require("../assets/img/ahmad.jpeg")}
            style={styles.image}
          />
          <View style={{ width: "100%" }}>
            <View style={styles.header}>
              <Text style={{ fontFamily: "DM_SANS" }}>
                MINISTRY OF LAND AND PHYSICAL PLANNING
              </Text>
              <Text style={{ fontSize: 13, marginTop: 5 }}>
                NO. 2, DR. BALA MUHAMMAD ROAD NASSARAWA, KANO
              </Text>
            </View>
            <View style={styles.secondHeader}></View>
            <View style={styles.thirdHeader}></View>
            <View style={styles.secondimage}>
              <Image
                src={require("../assets/img/ahmad2.jpeg")}
                style={styles.image1}
              />
            </View>
            <View
              style={{ width: "90%", textAlign: "center", marginBottom: 40 }}
            >
              <Text style={{ fontSize: 17, letterSpacing: 1 }}>
                CERTIFICATE OF COMPLETION OF SLTR PROCESS{" "}
              </Text>
            </View>
            <View
              style={{
                width: "90%",
                textAlign: "center",
                fontSize: 10,
                letterSpacing: 1,
                marginBottom: 7,
              }}
            >
              <Text>
                This is to certify that{" "}
                <Text style={{ fontWeight: "bold", fontSize: 11 }}>
                  {data[0]?.tax_payer.toUpperCase()}{" "}
                </Text>
                has completed the SLTR
              </Text>
            </View>
            <View
              style={{
                width: "90%",
                textAlign: "center",
                fontSize: 10,
                letterSpacing: 1,
              }}
            >
              <Text>
                process for getting a C of O. The details of the application
                are:
              </Text>
            </View>
            <View style={styles.table}>
              <View
                style={{
                  width: "37%",
                  textAlign: "right",
                  borderRight: "1px solid #000",
                }}
              >
                <Text
                  style={{
                    borderBottom: "1px solid #000",
                    padding: 2,
                    width: "100%",
                  }}
                >
                  Parcel Number
                </Text>
                <Text
                  style={{
                    borderBottom: "1px solid #000",
                    padding: 2,
                    width: "100%",
                  }}
                >
                  SLTR File Number
                </Text>
                <Text
                  style={{
                    borderBottom: "1px solid #000",
                    padding: 2,
                    width: "100%",
                  }}
                >
                  Ward
                </Text>
                <Text
                  style={{
                    borderBottom: "1px solid #000",
                    padding: 2,
                    width: "100%",
                  }}
                >
                  LGA
                </Text>
                <Text
                  style={{
                    borderBottom: "1px solid #000",
                    padding: 2,
                    width: "100%",
                  }}
                >
                  State
                </Text>
                <Text
                  style={{
                    borderBottom: "1px solid #000",
                    padding: 2,
                    width: "100%",
                  }}
                >
                  Land Use
                </Text>
                <Text
                  style={{
                    borderBottom: "1px solid #000",
                    padding: 2,
                    width: "100%",
                  }}
                >
                  Registration Status
                </Text>
                <Text style={{ padding: 2, width: "100%" }}>Date</Text>
              </View>
              <View
                style={{
                  width: "63%",
                  textAlign: "left",
                  borderRight: "1px solid #000",
                  fontWeight: "bold",
                }}
              >
                <Text
                  style={{
                    borderBottom: "1px solid #000",
                    padding: 2,
                    width: "100%",
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                 {tax_account?.lga?`KN/{tax_account?.lga}/{data[0]?.mda_val}`:"N/A"} 
                </Text>
                <Text
                  style={{
                    borderBottom: "1px solid #000",
                    padding: 2,
                    width: "100%",
                    paddingLeft: 10,
                  }}
                >
                  {data[0]?.mda_val?`SLTR-{data[0]?.mda_val}`:"N/A"}
                </Text>
                <Text
                  style={{
                    borderBottom: "1px solid #000",
                    padding: 2,
                    width: "100%",
                    paddingLeft: 10,
                  }}
                >
                  {tax_account?.ward?tax_account?.ward:"N/A"}
                </Text>
                <Text
                  style={{
                    borderBottom: "1px solid #000",
                    padding: 2,
                    width: "100%",
                    paddingLeft: 10,
                  }}
                >
                  {tax_account?.lga?tax_account?.lga:"N/A"}
                </Text>
                <Text
                  style={{
                    borderBottom: "1px solid #000",
                    padding: 2,
                    width: "100%",
                    paddingLeft: 10,
                  }}
                >
                  {tax_account?.state?tax_account?.state:"N/A"}
                </Text>
                <Text
                  style={{
                    borderBottom: "1px solid #000",
                    padding: 2,
                    width: "100%",
                    paddingLeft: 10,
                  }}
                >
                  {data[0].description?data[0].description:"N/A"}
                </Text>
                <Text
                  style={{
                    borderBottom: "1px solid #000",
                    padding: 2,
                    width: "100%",
                    paddingLeft: 10,
                  }}
                >
                  {data[0].service_category === "System Process"
                    ? "SYSTEMATIC"
                    : "ON-DEMAND"}
                </Text>
                <Text style={{ padding: 2, width: "100%", paddingLeft: 10 }}>
                  {moment().format("YYYY-MM-DD")}
                </Text>
              </View>
            </View>
            <View>
              <Text style={{ marginTop: 100, fontSize: 12, marginLeft: -40 }}>
                Signed by SLTR Officer:
              </Text>
              <Text style={{ fontSize: 11, marginLeft: -40, marginBottom: 3 }}>
                {user.name}
              </Text>
            </View>
            <View style={styles.footer}>
              <Image
                src={require("../assets/img/Web capture_31-10-2023_10350_.jpeg")}
                style={styles.footerimage}
              />
              <Image
                src={require("../assets/img/Web capture_31-10-2023_10267_.jpeg")}
                style={styles.footerimage1}
              />
            </View>
            <View style={styles.secondpage}>
              <Text style={{ textDecoration: "underline", marginBottom: 10 }}>
                Accountant:
              </Text>
              <Text style={{ width: "100%", fontSize: 20 }}>
                Kindly Accept the Payment of N{formatNumber(data[0]?.tax_fee)} ,
                For The Issuance of SLTR Certificate of Occupancy (C of O).
              </Text>
              <View
                style={{ flexDirection: "row", width: "100%", marginTop: 50 }}
              >
                <Text style={{ width: "50%", fontSize: 17 }}>
                  Sign:....................................,
                </Text>
                <Text style={{ width: "50%", fontSize: 17 }}>
                  Date:....................................,
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
