import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons"; // Added faDownload
import { Col, Row, Button } from "@themesberg/react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer"; // Added PDFDownloadLink
import InvoicePdf from "../components/InvoicePdf";
import { useQuery } from "../utils";
import { _fetchApi, apiURL } from "../redux/action/api";
import { useCallback } from "react";
import PaymentButton from "./payments/PaymentButton";
import { useLocation } from "react-router-dom";
import LandTermalInvoice from "../components/LandTermalInvoice";
import { LandAccountantInvoice } from "./LandAccountantInvoice";
import { useSelector } from "react-redux";
import { CertificateOfCompletion } from "../components/CertificateOfCompletion";
import useIsPhoneSize from "../utils/useIsPhoneSize";
import TermalInvoice from "../components/TermalInvoice";

export default ({ refNo = null }) => {
  const q = useQuery();
  const ref_no = refNo ? refNo : q.get("ref_no");
  const [qrcode, setQrCode] = useState(null);
  const userInfo = useSelector((state) => state.auth.user);

  const [user, setUser] = useState({});
  const [user_id, setUserID] = useState(null);
  const [list, setList] = useState([]);
  const location = useLocation();
  const isAdmin = location.pathname.includes("/dashboard/");
  const [loading, setLoading] = useState(false);
  const agent = useSelector((state) => state.auth.user);
  const { tax_account = [] } = useSelector((state) => state.auth);
  const isMobile = useIsPhoneSize();
  useEffect(() => {
    if (ref_no) {
      setLoading(true);
      _fetchApi(
        `/transactions/retrieve?query_type=view_invoice&reference_number=${ref_no}&user_id=${userInfo.id}`,
        (resp) => {
          setList(resp.data);
          if (resp.data[0]?.user_id) {
            setLoading(false);
            setUserID(resp.data[0].user_id);
          }
        },
        (err) => {
          console.error(err);
          setLoading(false);
        },
        (err) => {
          console.log(err);
          setList([]);
          setLoading(false);
        }
      );
    }
    if (user_id) {
      _fetchApi(
        `/users/get-tax-payer?user_id=${user_id}`,
        (resp) => {
          if (resp.success) {
            setUser(resp.data);
          }
        },
        (err) => {
          console.error(err);
          setUser({});
        }
      );
    }
    if (ref_no) {
      const qrCodeUrl = `${apiURL}/transactions/get-qr-code?ref_no=${ref_no}`;
      fetch(qrCodeUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("@@auth_token"),
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.blob();
        })
        .then((blob) => {
          const qrCodeImageUrl = URL.createObjectURL(blob);
          setQrCode(qrCodeImageUrl);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [ref_no, user_id]);

  const final_list = list.some((item) => item.transaction_type === "payment")
    ? list
    : list.map((item) => ({ ...item, cr: item.dr }));
  return (
    <div className="p-0 m-0 h-100 text-center">
      <Row className="mb-4">
        <Col md={isAdmin ? 12 : 9}></Col>
      </Row>
      {/* {JSON.stringify(userInfo)} */}
      {/* <PDFViewer height="100%" width="70%">
        <InvoicePdf
          user={user}
          data={final_list
            .filter((item) => item.cr > 0)
            .map((l) => ({ ...l, tax_fee: l.cr }))}
          qrcode={qrcode}
        />
      </PDFViewer> */}
      {loading ? (
        <SkeletonTheme baseColor="#f1f1f1" highlightColor={"#ffffff"}>
          <p>
            <Skeleton count={15} height={50} />
          </p>
        </SkeletonTheme>
      ) : (
        <>
          <Row className="mb-4">
            <Col md={isAdmin ? 12 : 9}>
              {final_list[0]?.sector !== "LAND" ? null : (
                <>
                  <PDFDownloadLink
                    document={
                      <LandTermalInvoice
                        user={user}
                        data={final_list
                          .filter((item) => item.cr > 0)
                          .map((l) => ({ ...l, tax_fee: l.cr }))}
                        qrcode={qrcode}
                      />
                    }
                    fileName="invoice.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        "Loading document..."
                      ) : (
                        <Button variant="primary">
                          <FontAwesomeIcon icon={faDownload} className="me-2" />
                          Download Invoice PDF
                        </Button>
                      )
                    }
                  </PDFDownloadLink>
                  {final_list[0]?.department === "SLTR" ? (
                    <PDFDownloadLink
                      document={
                        <CertificateOfCompletion
                          user={agent}
                          tax_account={tax_account}
                          data={final_list
                            .filter((item) => item.cr > 0)
                            .map((l) => ({ ...l, tax_fee: l.cr }))}
                        />
                      }
                      fileName="invoice.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Loading document..."
                        ) : (
                          <Button variant="primary">
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="me-2"
                            />
                            Certificate of Completion PDF
                          </Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : final_list[0]?.department === "Land" ? (
                    <PDFDownloadLink
                      document={
                        <LandAccountantInvoice
                          data={final_list
                            .filter((item) => item.cr > 0)
                            .map((l) => ({ ...l, tax_fee: l.cr }))}
                        />
                      }
                      fileName="invoice.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Loading document..."
                        ) : (
                          <Button variant="primary">
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="me-2"
                            />
                            Download Payment Schedule PDF
                          </Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : null}
                </>
              )}
            </Col>
          </Row>
          {isMobile ? null : <>
            {final_list[0]?.sector !== "LAND" ? (
              <Row style={{ height: '70vh' }}>
                <Col md={8}>
                  <Row>
                    <Col md={12}>
                      <PDFDownloadLink
                        document={
                          <InvoicePdf
                            user={user}
                            data={final_list
                              .filter((item) => item.cr > 0)
                              .map((l) => ({ ...l, tax_fee: l.cr }))}
                            qrcode={qrcode}
                          />
                        }
                        fileName="invoice.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            "Loading document..."
                          ) : (
                            <Button variant="primary">
                              <FontAwesomeIcon icon={faDownload} className="me-2" />
                              Download PDF
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ height: '70vh' }}>
                      <PDFViewer height="100%" width="70%">
                        <InvoicePdf
                          user={user}
                          data={final_list
                            .filter((item) => item.cr > 0)
                            .map((l) => ({ ...l, tax_fee: l.cr }))}
                          qrcode={qrcode}
                        />
                      </PDFViewer>
                    </Col>
                  </Row>
                </Col>
                <Col md={4}>
                  <PDFDownloadLink
                    document={
                      <TermalInvoice
                        user={user}
                        agent={userInfo}
                        data={final_list
                          .filter((item) => item.cr > 0)
                          .map((l) => ({ ...l, tax_fee: l.cr }))}
                        qrcode={qrcode}
                      />
                    }
                    fileName="termal-invoice.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        "Loading document..."
                      ) : (
                        <Button variant="primary">
                          <FontAwesomeIcon
                            icon={faDownload}
                            className="me-2"
                          />
                          Termal Invoice
                        </Button>
                      )
                    }
                  </PDFDownloadLink>

                  <PDFViewer height="100%" width="70%">
                    <TermalInvoice
                      user={user}
                      agent={userInfo}
                      data={final_list
                        .filter((item) => item.cr > 0)
                        .map((l) => ({ ...l, tax_fee: l.cr }))}
                      qrcode={qrcode}
                    />
                  </PDFViewer>
                </Col>
              </Row>
            ) : (
              <>
                {final_list[0]?.department === "Land" ? (
                  <>
                    {" "}
                    <PDFViewer height="100%" width="50%">
                      <LandTermalInvoice
                        user={user}
                        data={final_list
                          .filter((item) => item.cr > 0)
                          .map((l) => ({ ...l, tax_fee: l.cr }))}
                        qrcode={qrcode}
                        agent={agent}
                      />
                    </PDFViewer>
                    <PDFViewer height="100%" width="50%">
                      <LandAccountantInvoice
                        data={final_list
                          .filter((item) => item.cr > 0)
                          .map((l) => ({ ...l, tax_fee: l.cr }))}
                      />
                    </PDFViewer>
                  </>
                ) : final_list[0]?.department === "SLTR" ? (
                  <>
                    {" "}
                    <PDFViewer height="100%" width="50%">
                      <LandTermalInvoice
                        user={user}
                        data={final_list
                          .filter((item) => item.cr > 0)
                          .map((l) => ({ ...l, tax_fee: l.cr }))}
                        qrcode={qrcode}
                        agent={agent}
                      />
                    </PDFViewer>
                    {/* {} */}
                    <PDFViewer height="100%" width="50%">
                      <CertificateOfCompletion
                        user={agent}
                        tax_account={tax_account}
                        data={final_list
                          .filter((item) => item.cr > 0)
                          .map((l) => ({ ...l, tax_fee: l.cr }))}
                      />
                    </PDFViewer>
                  </>
                ) : (
                  <PDFViewer height="100%" width="100%">
                    <LandTermalInvoice
                      user={user}
                      data={final_list
                        .filter((item) => item.cr > 0)
                        .map((l) => ({ ...l, tax_fee: l.cr }))}
                      qrcode={qrcode}
                      agent={agent}
                    />
                  </PDFViewer>
                )}
              </>
            )}
          </>}
        </>
      )}
    </div>
  );
};
