import React, { useEffect, useState } from "react";
import { Col, Row, Card, Form, Button } from "@themesberg/react-bootstrap";
import { _fetchApi, _postApi } from "../../redux/action/api";
import { useCallback } from "react";
import CustomTypeahead from "../../components/UI/CustomTypeahead";
import { toast } from "react-hot-toast";
import moment from "moment";

export default function NewTaxFormModal({ _form = {}, toggle, _getTaxHeads }) {
  const [form, setForm] = useState(_form);
  const [taxHeads, setTaxHeads] = useState([]);

  const handleChanges = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const [edit, setEdit] = useState(false);

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setForm((p) => ({ ...p, default: "checked" }));
    } else {
      setForm((p) => ({ ...p, default: "" }));
    }
  };

  const handleIsDeptChange = ({ target: { value } }) => {
    if (value === "0") {
      setForm((p) => ({
        ...p,
        is_department: "1",
        department: p.title,
        tax_fee: "",
      }));
    } else {
      setForm((p) => ({
        ...p,
        is_department: "0",
        department: taxHeads.find(
          (item) => item.tax_code === form.tax_parent_code
        )?.department,
      }));
    }
  };

  const handleIsHeadChange = ({ target: { value, checked } }) => {
    if (value === "0") {
      setForm((p) => ({
        ...p,
        is_head: "1",
        tax_fee: "",
      }));
    } else {
      setForm((p) => ({
        ...p,
        is_head: "0",
      }));
    }
  };

  const getTaxHeads = useCallback(() => {
    _fetchApi(
      `/kigra-taxes?query_type=select-heads&sector=${form.sector}`,
      (resp) => {
        setTaxHeads(resp.result);
      },
      () => {}
    );
  }, [form.sector]);

  const arr = [
    {
      ...form,
      description: form.title,
      tax_parent_code: form.tax_parent_code,
      query_type: form.edit ? "update-tax" : "create-tax",
    },
  ];

  const handleSubmit = () => {
    if (form.tax_parent_code === "" || form.title === "") {
      toast.error("Uncompleted form");
    } else {
      _postApi(
        "/kigra-taxes",
        arr,
        (res) => {
          console.log(res);
          toast.success(
            form.edit ? "Tax updated successfully" : "Tax created successfully"
          );
          _getTaxHeads();
          toggle();
        },
        (err) => {
          console.error(err);
          toast.error("Error Occured", err);
        }
      );
      console.log(form);
    }
  };

  useEffect(() => {
    getTaxHeads();
  }, [getTaxHeads]);

  const isCheckboxChecked = form.default === "checked";
  const editCond = !form.edit || !edit;
  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      {/* {JSON.stringify(form)} */}
      <Card.Body>
        <div className="d-flex justify-content-between">
          <h5 className="mb-4">Tax information</h5>
          {form.edit && (
            <Button
              outline
              color={"light"}
              size="sm"
              onClick={() => setEdit(!edit)}
            >
              Edit
            </Button>
          )}
        </div>
        {/* {JSON.stringify(form)} */}
        <Form>
          <Row>
            {/* <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Tax category</Form.Label>
                <Form.Select
                  required
                  value={form.sector}
                  name="sector"
                  type="select"
                  onChange={handleChanges}
                  placeholder="Select tax category"
                >
                  <option value="">Select tax sector</option>
                  <option value="TAX">Tax</option>
                  <option value={"NON TAX"}>Non Tax</option>
                  <option value={"VEHICLES"}>Vehicles tax</option>
                  <option value={"LAND"}>Land tax</option>
                  <option value={"LGA"}>LGA Revenue</option>
                </Form.Select>
              </Form.Group>
            </Col> */}
            <Col md={8} className="mb-3">
              <Form.Group id="taxHead">
                {/* <Form.Label>Tax Head</Form.Label> */}
                {editCond ? (
                  <CustomTypeahead
                    options={taxHeads}
                    label="Tax Head"
                    labelKey={(l) => `${l?.title}`}
                    onInputChange={() => {}}
                    onChange={(e) => {
                      if (e.length > 0) {
                        // Set the e object from the options as the value
                        setForm({
                          ...form,
                          tax_parent_code: e[0].tax_code,
                          tax_code: moment().format("YYMMDDhhms"),
                          mda_name: e[0].mda_name,
                          mda_code: e[0].mda_code,
                          department: e[0].department,
                        });
                      } else {
                        // Handle the case when nothing is selected
                        // You might want to clear the values or set some default values here
                      }
                    }}
                    col={12}
                    allowNew
                    // clearButton
                    newSelectionPrefix="Create a new item: "
                    placeholder="Search or create Head"
                    defaultSelected={[form]}
                  />
                ) : (
                  <>
                    <Form.Label>Tax Head</Form.Label>
                    <div>{form.title}</div>
                  </>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Tax title</Form.Label>
                <Form.Control
                  name="title"
                  value={form.title}
                  onChange={handleChanges}
                  disabled={editCond ? false : true}
                />
              </Form.Group>
            </Col>
            <Col sm={6} md={6} className="mb-3">
              <Form.Group id="city">
                <Form.Label>Revenue code</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Revenue code"
                  value={form.economic_code}
                  name="economic_code"
                  onChange={handleChanges}
                  disabled={editCond ? false : true}
                />
              </Form.Group>
            </Col>
            {!form.is_department === "1" ||
            !form.is_head ||
            form.default == "checked" ? (
              <Col sm={6} md={6} className="mb-3">
                <Form.Group className="mb-2">
                  <Form.Label>Tax fee</Form.Label>
                  <Form.Control
                    name="tax_fee"
                    placeholder="Tax fee"
                    value={form.tax_fee}
                    onChange={handleChanges}
                    disabled={editCond ? false : true}
                  />
                </Form.Group>
              </Col>
            ) : null}
            <Col md={6} className="mb-3">
              <Form.Group id="input_type">
                <Form.Label>Item Unit</Form.Label>
                <Form.Control
                  required
                  value={form.uom}
                  name="uom"
                  type="text"
                  disabled={editCond ? false : true}
                  onChange={handleChanges}
                  placeholder="Per m2 | Per hecter | Per annum"
                />
              </Form.Group>
            </Col>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="input_type">
                  <Form.Label>
                    <input
                      className="large-check"
                      onChange={handleCheckboxChange}
                      checked={isCheckboxChecked}
                      value={form.default}
                      disabled={editCond ? false : true}
                      type="checkbox"
                    />
                    <b className="m-2">Required</b>
                  </Form.Label>
                </Form.Group>
              </Col>
              {parseFloat(form.tax_fee) > 0 ? null : (
                <Col md={6} className="mb-3">
                  <Form.Group id="input_type">
                    <Form.Label>
                      <input
                        className="large-check"
                        onChange={handleIsHeadChange}
                        checked={form.is_head === "1"}
                        value={form.is_head}
                        type="checkbox"
                      />
                      <b className="m-2">Is Head</b>
                    </Form.Label>
                  </Form.Group>
                </Col>
              )}
              {parseFloat(form.tax_fee) > 0 ? null : (
                <Col md={6} className="mb-3">
                  <Form.Group id="input_type">
                    <Form.Label>
                      <input
                        className="large-check"
                        onChange={handleIsDeptChange}
                        checked={form.is_department === "1"}
                        value={form.is_department}
                        type="checkbox"
                      />
                      <b className="m-2">Is department</b>
                    </Form.Label>
                  </Form.Group>
                </Col>
              )}
            </Row>
          </Row>
          <Row>{/** Render new tax list */}</Row>
        </Form>
        <Row>
          <Col className="text-center mt-3">
            <Button
              variant="primary"
              onClick={() => setForm(_form)}
              className="px-3"
              disabled={editCond ? false : true}
            >
              Reset form
            </Button>
          </Col>
          <Col className="text-center mt-3">
            <Button
              variant="primary"
              onClick={handleSubmit}
              className="px-3"
              disabled={editCond ? false : true}
            >
              {form.edit ? "Update" : " Submit"}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
