import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Row,
  Form,
  Card,
  FormCheck,
  Container,
  Modal,
  InputGroup,
  FormControl,
} from "@themesberg/react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Button, ButtonGroup, Spinner } from "reactstrap";
import CustomInput from "./CustomInput";
import { UserContext } from "../../redux/contextApi/user_context";
import { useDispatch } from "react-redux";
import { login, logout, signup } from "../../redux/action/auth";
import {
  // faCaretDown,
  // faCaretUp,
  // faList,
  faEye,
  faEyeSlash,
  // faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";

import logo from "../../assets/img/kigra.jpg";

import { Routes } from "../../routes";
import SelectInput from "../components/SelectInput";
// import { kanoLGAs, states } from "../../utils/states";
import { toast } from "react-hot-toast";
import Contact from "../../contact/Contact";
import useIsPhoneSize from "../../utils/useIsPhoneSize";
import { stateLga } from "../../statelga";
import { _postApi } from "../../redux/action/api";
import { useHint } from "react-bootstrap-typeahead";
import { CustomButton } from "../../components/UI";

export default () => {
  const [form, setForm] = useState({
    account_type: "individual",
    contact_name: "", // Individual's full name
    contact_phone: "", // Individual's contact_phone number
    contact_email: "",
    ward:"",
    nin: "", // Individual's nin
    state: "Kano", // Individual's state
    lga: "", // Individual's local government
    org_name: "", // Organization's name
    rc: "", // Organization's RC/BN
    org_tin: "", // Organization's TIN
    tin: "", // Individual's TIN
    contact_address: "",
    office_address: "",
    office_phone: "",
    org_email: "",
    password: "",
    retypePassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [showDefault, setShowDefault] = useState(false);
  const toggle = () => setShowDefault(!showDefault);
  const [taxID, setTaxID] = useState("");
  const isPhoneSize = useIsPhoneSize();
  const handleChange = ({ target: { name, value } }) => {
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };
  const history = useHistory();

  // const passValid = form.password === form.retypePassword;
  useEffect(() => {
    logout();
  }, []);
  const handleApi = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      signup(
        { ...form, address: form.contact_address },
        (result) => {
          setLoading(false);
          if (result.success) {
            setTaxID(result.taxID);
            setShowDefault(true);
          } else {
            toast.error(result.msg);
          }
        },
        (err) => {
          setLoading(false);
          console.log(err);
          toast.error(err.msg);
        }
      )
    );
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    _postApi(
      "/auths/-post-users",
      {
        query_type: "create-admin",
        role: "agent",
        name: form.contact_name,
        phone: form.contact_phone,
        email: form.contact_email,
        accessTo: "",
        rank: "",
        state: form.state,
        lga: form.lga,
        address: form.office_address || form.contact_address,
        password: form.password,
        account_type: "bank",
      },
      (result) => {
        setLoading(false);
        // console.log(result);
        if (result.success) {
          toast.success("Submitted");
          toggle();
        } else {
          console.log(result);
          toast.error(result.msg);
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        toast.error(err.msg);
      }
    );
  };
  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-2 mb-lg-5">
        <Modal as={Modal.Dialog} centered show={showDefault}>
          <Modal.Header>
            <Modal.Title className="h6">
              Your Account created successfully{" "}
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={toggle} />
          </Modal.Header>
          <Modal.Body>
            {form.account_type === "bank" ? (
              <h2>
                Dear, {form.contact_name} your account has been created and you
                can be able to pay Kano state Tax on behalf of
                Individuals/Organizations.
                
              </h2>
            ) : (
              <h2>Your KIRMAS TaxID is: {taxID}</h2>
            )}
          </Modal.Body>
          <Modal.Footer>
            <CustomButton
              loading={loading}
              onClick={() => {
                toggle();
                setLoading(true);
                dispatch(
                  login(
                    {
                      password: form.password,
                      username: form.contact_phone,
                      history,
                    },
                    () => {
                      history.push(`/selection`);
                      setLoading(false);
                      setTimeout(() => window.location.reload(), 400);
                    },
                    () => {
                      toast.error("Error occured");
                      setLoading(false);
                    }
                  )
                );
              }}
            >
              Login
            </CustomButton>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={toggle}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Container>
          <Row
            className="justify-content-center form-bg-image"
            // style={{ backgroundImage: `url(${require('../../assets/img/kano.png')})` }}
          >
            {isPhoneSize ? (
              <img
                src={logo}
                alt="logo"
                style={{ width: 100, marginBottom: -17, zIndex: 1 }}
              />
            ) : (
              <img
                src={logo}
                alt="logo"
                style={{ width: 100, marginBottom: -20, zIndex: 1 }}
              />
            )}
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-800">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Create an account</h3>
                </div>
                <ButtonGroup className="w-100 pb-1">
                  <Button
                    outline={form.account_type === "individual" ? false : true}
                    onClick={() =>
                      setForm((p) => ({
                        ...p,
                        account_type: "individual",
                      }))
                    }
                    color="dark"
                    className={`col-md-6`}
                  >
                    INDIVIDUAL
                  </Button>
                  <Button
                    color="dark"
                    outline={form.account_type === "org" ? false : true}
                    onClick={() =>
                      setForm((p) => ({
                        ...p,
                        account_type: "org",
                      }))
                    }
                    className="col-md-6"
                  >
                    ORGANIZATION/COMPANY
                  </Button>
                  <Button
                    color="dark"
                    outline={form.account_type === "bank" ? false : true}
                    onClick={() =>
                      setForm((p) => ({ ...p, account_type: "bank" }))
                    }
                    className="col-md-6"
                  >
                    Bank
                  </Button>
                </ButtonGroup>
                <Form
                  className="mt-1"
                  onSubmit={
                    form.account_type !== "bank" ? handleApi : handleSubmit
                  }
                >
                  <Row>
                    {form.account_type === "org" && (
                      <>
                        <Card.Body>
                          <Row>
                            <Col md={6} className="mb-1">
                              <CustomInput
                                label={
                                  <>
                                    Organization Name{" "}
                                    <span style={{ color: "red" }}> *</span>
                                  </>
                                }
                                type="text"
                                name="org_name"
                                value={form.org_name}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={6} className="mb-1">
                              <CustomInput
                                label={
                                  <>
                                    Office Phone{" "}
                                    <span style={{ color: "red" }}> *</span>
                                  </>
                                }
                                type="text"
                                name="office_phone"
                                value={form.office_phone}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={6} className="mb-1">
                              <CustomInput
                                label="RC/BN"
                                type="text"
                                name="rc"
                                value={form.rc}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={6} className="mb-1">
                              <CustomInput
                                label={
                                  <>
                                    Office E-mail{" "}
                                    <span style={{ color: "red" }}> *</span>
                                  </>
                                }
                                type="email"
                                name="org_email"
                                value={form.org_email}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={6} className="mb-1">
                              <CustomInput
                                label="ORG TIN"
                                type="text"
                                name="org_tin"
                                value={form.org_tin}
                                onChange={handleChange}
                              />
                            </Col>

                            <Col md={6} className="mb-1">
                              <CustomInput
                                label="Office address / state"
                                type="textarea"
                                required={true}
                                name="office_address"
                                value={form.office_address}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={6}>
                              <SelectInput
                                label="State of residence"
                                type="text"
                                name="state"
                                // required={true}
                                options={stateLga.map((item) => item.state)}
                                value={form.state}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={6} className="mb-1">
                              {/* {JSON.stringify(s)} */}
                              <SelectInput
                                label="L.G.A"
                                type="text"
                                name="lga"
                                // required={true}
                                options={
                                  stateLga.filter(
                                    (item) => item.state === form.state
                                  )[0].lgas
                                }
                                value={form.lga}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={6} className="mb-1">
                              <CustomInput
                                label="Ward"
                                type="text"
                                name="ward"
                                value={form.ward}
                                onChange={handleChange}
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </>
                    )}
                    {/* {form.account_type==} <Card bg="white" className="mb-4 mt-0 mx-0 px-0"></Card>*/}
                    {form.account_type === "org" ? (
                      // <CardH bg="white" className="mb-4 mt-0 mx-0 px-0">Contact Person</CardH>
                      <Card.Header
                        bg="light"
                        className="bg-light text-center mb-4 "
                      >
                        <Card.Title>Contact Person</Card.Title>
                      </Card.Header>
                    ) : null}

                    {form.account_type !== "bank" ? (
                      <>
                        <Col md={6}>
                          <CustomInput
                            label="Name"
                            onChange={handleChange}
                            type="text"
                            required={true}
                            name="contact_name"
                            value={form.contact_name}
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          <CustomInput
                            label="Phone No."
                            type="text"
                            name="contact_phone"
                            required={true}
                            value={form.contact_phone}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          <CustomInput
                            label="TIN"
                            type="text"
                            name="tin"
                            value={form.tin}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          <CustomInput
                            label="Contact Email"
                            // required={true}
                            type="email"
                            name="contact_email"
                            value={form.contact_email}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={12} className="mb-1">
                          <CustomInput
                            label="Contact address"
                            type="text"
                            // required={true}
                            name="contact_address"
                            value={form.contact_address}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={6} className="mb-1">
                          <SelectInput
                            label="State of residence"
                            type="text"
                            name="state"
                            // required={true}
                            options={stateLga.map((item) => item.state)}
                            value={form.state}
                            onChange={handleChange}
                          />
                        </Col>{" "}
                        <Col md={6} className="mb-1">
                          <SelectInput
                            label="L.G.A"
                            type="text"
                            name="lga"
                            // required={true}
                            options={
                              stateLga.filter(
                                (item) => item.state === form.state
                              )[0].lgas
                            }
                            value={form.lga}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={6} className="mb-1  mt-4">
                          <CustomInput
                            label="Ward"
                            type="text"
                            name="ward"
                            value={form.ward}
                            onChange={handleChange}
                          />
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col md={6}>
                          <CustomInput
                            label="Bank Name"
                            onChange={handleChange}
                            type="text"
                            required={true}
                            name="contact_name"
                            value={form.contact_name}
                          />
                        </Col>
                        <Col md={6}>
                          <CustomInput
                            label="Contact Phone Number"
                            onChange={handleChange}
                            type="text"
                            required={true}
                            name="contact_phone"
                            value={form.contact_phone}
                          />
                        </Col>
                        <Col md={6}>
                          <CustomInput
                            label="Contact Email"
                            onChange={handleChange}
                            type="text"
                            // required={true}
                            name="contact_email"
                            value={form.contact_email}
                          />
                        </Col>
                        <Col md={6}>
                          <CustomInput
                            label="Office address / state"
                            type="textarea"
                            // required={true}
                            name="office_address"
                            value={form.office_address}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={6}>
                          <SelectInput
                            label="State of residence"
                            type="text"
                            name="state"
                            // required={true}
                            options={stateLga.map((item) => item.state)}
                            value={form.state}
                            onChange={handleChange}
                          />
                        </Col>{" "}
                        <Col md={6}>
                          <SelectInput
                            label="L.G.A"
                            type="text"
                            name="lga"
                            // required={true}
                            options={
                              stateLga.filter(
                                (item) => item.state === form.state
                              )[0].lgas
                            }
                            value={form.lga}
                            onChange={handleChange}
                          />
                        </Col>
                      </>
                    )}
                    {form.account_type === "user" && (
                      <>
                        <Col md={6}>
                          <SelectInput
                            label="State of residence"
                            type="text"
                            name="state"
                            // required={true}
                            options={stateLga.map((item) => item.state)}
                            value={form.state}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={6}>
                          {/* {JSON.stringify(s)} */}
                          <SelectInput
                            label="L.G.A"
                            type="text"
                            name="lga"
                            // required={true}
                            options={
                              stateLga.filter(
                                (item) => item.state === form.state
                              )[0].lgas
                            }
                            value={form.lga}
                            onChange={handleChange}
                          />
                        </Col>
                      </>
                    )}
                    <Col md={6} className="mb-4 mt-4">
                      <Form.Label>
                        Password <span style={{ color: "red" }}> *</span>{" "}
                      </Form.Label>
                      <InputGroup>
                        <FormControl
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={form.password}
                          required={true}
                          onChange={handleChange}
                          placeholder="Password"
                        />
                        <InputGroup.Text
                          style={{ cursor: "pointer" }}
                          onClick={toggleShowPassword}
                        >
                          <FontAwesomeIcon
                            icon={showPassword ? faEye : faEyeSlash}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </Col>
                    <FormCheck type="checkbox" className="d-flex mb-4">
                      <FormCheck.Input required id="terms" className="me-2" />
                      <FormCheck.Label htmlFor="terms">
                        Please confirm that the data above is correct
                      </FormCheck.Label>
                    </FormCheck>

                    <Button color="dark" type="submit" className="w-100">
                      {loading ? <Spinner /> : "Submit"}
                    </Button>
                  </Row>
                </Form>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account?
                    <Card.Link
                      as={Link}
                      to={Routes.Signin.path}
                      className="fw-bold"
                    >
                      {` Login here `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Contact />
        </Container>
      </section>
    </main>
  );
};
