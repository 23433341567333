import "dotenv";
import { logout } from "./auth";

// import { useHistory } from 'react-router-dom';
// let localEndpoint = "http://192.168.43.23:3589";
let localEndpoint = "http://localhost:3589";
let remoteEndpoint = "https://galaxybis.ebudgetkano.ng/kirmas-test";
let remoteTestEndpoint = "https://kirmas-test-931cfc93adfb.herokuapp.com";

// let remoteEndpoint = "https://yge.wvi.mybluehost.me/kirmas-api";
const apiURL =
  process.env.NODE_ENV === "development" ? remoteEndpoint : remoteEndpoint;

const _postApi = (url, data = {}, success = (f) => f, error = (f) => f) => {
  let token = localStorage.getItem("@@auth_token");
  fetch(apiURL + url, {
    method: "POST",
    headers: { "Content-Type": "application/json", authorization: token },
    body: JSON.stringify(data),
  })
    .then((raw) => raw.json())
    .then((response) => {
      if (response.status >= 400) {
        error(response);
        if (response.status === 401) {
          logout();
          window.location.reload();
        }
      } else success(response);
    })
    .catch((err) => error(err));
};

const _fetchApi = (
  url,
  success = (f) => f,
  error = (f) => f,
  empty = (f) => f
) => {
  let token = localStorage.getItem("@@auth_token");
  fetch(apiURL + url, {
    method: "GET",
    headers: { "Content-Type": "application/json", authorization: token },
  })
    .then((raw) => raw.json())
    .then((response) => {
      if (response) {
        success(response);
      } else {
        console.log("Empty response");
        empty();
      }
    })
    .catch((err) => {
      if (err.status === 401) {
        logout();
        window.location.reload();
      }
      error(err);
    });
  // alert(token);
};

//tax_parent_code tax_code
function unflatten(arr) {
  var tree = [],
    mappedArr = {},
    arrElem,
    mappedElem;

  // First map the nodes of the array to an object -> create a hash table.
  for (var i = 0, len = arr?.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem.tax_parent_code] = arrElem;
    mappedArr[arrElem.tax_parent_code]["children"] = [];
  }

  // Create the tree structure by iterating over the array again.
  for (let i = 0, len = arr?.length; i < len; i++) {
    arrElem = arr[i];
    mappedElem = mappedArr[arrElem.tax_parent_code];

    // Add a custom property to each child containing parent head values
    const parentHeads = [];
    let currentParent = mappedElem;
    while (currentParent?.tax_code) {
      parentHeads.unshift(currentParent?.tax_code);
      currentParent = mappedArr[currentParent?.tax_code];
    }
    const childWithProperty = { ...mappedElem, parentHeads };

    if (mappedElem?.tax_code) {
      mappedArr[`parent_${i}`] = "20";
      mappedArr[mappedElem?.tax_code]["children"].push(childWithProperty);
    } else {
      tree.push(childWithProperty);
    }
  }

  return tree;
}
// / System for American Numbering
var th_val = ["", "thousand", "million", "billion", "trillion"];
// System for uncomment this line for Number of English
// var th_val = ['','thousand','million', 'milliard','billion'];

var dg_val = [
  "zero",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
];
var tn_val = [
  "ten",
  "eleven",
  "twelve",
  "thirteen",
  "fourteen",
  "fifteen",
  "sixteen",
  "seventeen",
  "eighteen",
  "nineteen",
];
var tw_val = [
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];
export function toWordsconver(s) {
  if (s) {
    s = s.toString() || 0;
    s = s.replace(/[\\, ]/g, "");
    if (s != parseInt(s)) return "not a number ";
    var x_val = s.indexOf(".");
    if (x_val == -1) x_val = s.length;
    if (x_val > 15) return "too big";
    var n_val = s.split("");
    var str_val = "";
    var sk_val = 0;
    for (var i = 0; i < x_val; i++) {
      if ((x_val - i) % 3 == 2) {
        if (n_val[i] == "1") {
          str_val += tn_val[Number(n_val[i + 1])] + " ";
          i++;
          sk_val = 1;
        } else if (n_val[i] != 0) {
          str_val += tw_val[n_val[i] - 2] + " ";
          sk_val = 1;
        }
      } else if (n_val[i] != 0) {
        str_val += dg_val[n_val[i]] + " ";
        if ((x_val - i) % 3 == 0) str_val += "hundred ";
        sk_val = 1;
      }
      if ((x_val - i) % 3 == 1) {
        if (sk_val) str_val += th_val[(x_val - i - 1) / 3] + " ";
        sk_val = 0;
      }
    }
    if (x_val != s.length) {
      var y_val = s.length;
      str_val += "point ";
      for (var e = x_val + 1; e < y_val; e++) str_val += dg_val[n_val[e]] + " ";
    }
    return str_val.replace(/\s+/g, " ");
  }
}
export { _postApi, _fetchApi, apiURL, unflatten };
