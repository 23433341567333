import React, { useCallback, useEffect, useState } from "react";
import { Col, Button, ButtonGroup } from "@themesberg/react-bootstrap";
import DaterangeSelector from "./dashboard/DaterangeSelector";
import moment from "moment";
import { _fetchApi } from "../redux/action/api";
import { formatNumber, useQuery } from "../utils";
import PrintWrapper from "../components/PrintWrapper";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import ReportPDF from "./dashboard/ReportPDF";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "reactstrap";
import CustomDateRange from "./dashboard/CustomDateRange";

export default () => {
  const today = moment().format("YYYY-MM-DD");
  const query = useQuery();
  const from = query.get("from") || today;
  const to = query.get("to") || today;
  const [form, setForm] = useState({ from, to });
  const [sector, setSector] = useState([]);
  const [mda, setMda] = useState([]);
  const [revenue, setrevenue] = useState([]);
  const [top50, setTop50] = useState([]);
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);
  const [banklyRevenues, setBanklyRevenues] = useState([]);

  const handleChangeDate = (updated) => setForm(updated);

  const getData = useCallback(
    (cb, query) => {
      setLoading(true);
      _fetchApi(
        `/get-transaction-details?from=${form.from}&to=${form.to}&query_type=${query}`,
        (res) => {
          setLoading(false);
          cb(res.data);
        },
        (err) => {
          setLoading(false);
          console.log(err);
        }
      );
    },
    [form]
  );

  useEffect(() => {
    _fetchApi(
      `/get/mdas?query_type=bankly-reports`,
      (res) => {
        setLoading(false);
        setBanklyRevenues(res.data);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
    getData(setSector, "sector");
    getData(setMda, "mda");
    getData(setrevenue, "get_revenue");
    getData(setTop50, "top_50");
  }, [getData]);

  const printReport = () => {
    setDisplay(!display);
  };

  return (
    <div className="card card-body" style={{ zIndex: 1 }}>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-3">
        <div className="d-block mb-4 mb-md-0">
          <h4>All Payments Reports</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
            <Button variant="outline-primary" size="sm" onClick={printReport}>
              Print
            </Button>
          </ButtonGroup>
        </div>
      </div>
      {/* {JSON.stringify({ type })} */}
      {display ? (
        <div>
          <div style={{ height: 200 }}>
            <PDFViewer
              style={{
                position: "absolute",
                border: 0,
                height: "200%",
                width: "80%",
                left: 0,
              }}
            >
              <ReportPDF
                sector={sector}
                mda={mda}
                revenue={revenue}
                top50={top50}
                banklyRevenues={banklyRevenues}
              />
            </PDFViewer>
          </div>
        </div>
      ) : (
        <div>
          <CustomDateRange
            handleChange={handleChangeDate}
            from={form.from}
            to={form.to}
            col={2}
          />

          {loading && <Spinner />}
          <center>
            <h4 className="">Sector Reports</h4>
          </center>

          {/* {JSON.stringify(sector)} */}
          {sector?.map((item) => (
            <div className="row m-2">
              <Col md={8}>{item.sector}</Col>
              <Col md={4} className="text-right">
                {formatNumber(item.total_amt)}
              </Col>
            </div>
          ))}
          <hr />
          <center>
            <h4 className="">MDA Reports</h4>
          </center>
          {mda?.map((item) => (
            <div className="row m-2">
              <Col md={6}>{item.mda_name}</Col>
              <Col md={4}>{item.sector}</Col>
              <Col md={2} className="text-right">
                {formatNumber(item.total_amt)}
              </Col>
            </div>
          ))}
          <hr />
          <center>
            <h4 className="">Report by revenue code</h4>
          </center>
          {revenue?.map((item) => (
            <div className="row m-2">
              <Col md={6}>{item.description}</Col>
              <Col md={3}>{item.rev_code}</Col>
              <Col md={3} className="text-right">
                {formatNumber(item.total_amt)}
              </Col>
            </div>
          ))}

          <hr />
          <center>
            <h4 className="">Report by bank</h4>
          </center>
          {banklyRevenues?.map((item) => (
            <div className="row m-2">
              <Col md={8}>{item.bank_name || "Others"}</Col>
              <Col md={4} className="text-right">
                {formatNumber(item.total)}
              </Col>
            </div>
          ))}

          <hr />
          <center>
            <h4 className="">Top 50 tax payers Reports</h4>
          </center>
          {top50?.map((item) => (
            <div className="row m-2">
              <Col md={6}>{item.tax_payer}</Col>
              <Col md={4}>{item.description}</Col>
              <Col md={2} className="text-right">
                {formatNumber(item.dr)}
              </Col>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
