import React, { forwardRef, useState } from "react";
import CustomInput from "./examples/CustomInput";
import { Card } from "@material-ui/core";
import { Button, CardBody, CardHeader, Col, Row, Spinner } from "reactstrap";
import { Modal } from "@themesberg/react-bootstrap";
import { useDispatch } from "react-redux";
import { expressSignup, getTaxPayerInfo, signup } from "../redux/action/auth";
import { useHistory } from "react-router-dom";
import { ButtonGroup } from "@themesberg/react-bootstrap";
import { Routes } from "../routes";
import { toast } from "react-hot-toast";
import { stateLga } from "../statelga";
import SelectInput from "./components/SelectInput";
// import { SelectInput } from "../components/UI";
// import CustomInput from "../examples/CustomInput";

const ExpressAcct = (props) => {
  const { isModal = false, handleFocus = (f) => f } = props;
  const [form, setForm] = useState({
    contact_name: "",
    contact_phone: "",
    address: "",
    password: "123456",
    account_type: "individual",
    ward: "",
    state: "Kano",
    lga: "",
  });
  const [loading, setLoading] = useState(false);
  const onChanges = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const [taxID, setTaxID] = useState(null);
  const [showDefault, setShowDefault] = useState(false);
  const toggle = () => setShowDefault(!showDefault);
  const handleApi = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      form.contact_name === "" ||
      form.contact_phone === "" ||
      (form.address === "" && form.office_address === "")
    ) {
      toast.error("Please complete the form");
    } else {
      dispatch(
        expressSignup(
          {
            ...form,
            org_name: form.account_type === "org" ? form.contact_name : "",
          },
          (result) => {
            setLoading(false);
            if (result.success) {
              setTaxID(result.taxID);
              setShowDefault(true);
            } else {
              toast.error(result.msg);
            }
          },
          (err) => {
            setLoading(false);
            console.log(err.msg);
            toast.error(err?.msg || "Error occured");
          }
        )
      );
    }
  };
  return (
    <div>
      <Modal as={Modal.Dialog} centered show={showDefault}>
        <Modal.Header>
          <Modal.Title className="h6">
            Your Account created successfully{" "}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={toggle} />
        </Modal.Header>
        <Modal.Body>
          <h2>Your KIRMAS TaxID is: {taxID}</h2>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              if (isModal) {
                handleFocus(taxID);
                // ref.current.focus();
              } else {
                dispatch(
                  getTaxPayerInfo(taxID, () => {
                    setLoading(false);
                    history.push(`${Routes.Selection.path}`);
                  }),
                  () => {
                    setLoading(false);
                    toast.error("Failed!");
                  }
                );
              }
            }}
          >
            Proceed
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={toggle}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {isModal ? (
        <>
          {" "}
          <ButtonGroup className="w-100 pb-4">
            <Button
              outline={form.account_type === "org"}
              onClick={() =>
                setForm((p) => ({ ...p, account_type: "individual" }))
              }
              color="dark"
              className={`col-md-6`}
            >
              INDIVIDUAL
            </Button>
            <Button
              color="dark"
              outline={form.account_type === "individual"}
              onClick={() => setForm((p) => ({ ...p, account_type: "org" }))}
              className="col-md-6"
            >
              ORGANIZATION/COMPANY
            </Button>
          </ButtonGroup>
          {/* {JSON.stringify(stateLga)} */}
          <Row>
            <Col md={6}>
              <CustomInput
                label="Enter Phone No."
                name="contact_phone"
                value={form.contact_phone}
                onChange={onChanges}
                required={true}
              />
            </Col>
            <Col md={6}>
              <CustomInput
                label="Tax payer name"
                name="contact_name"
                value={form.contact_name}
                onChange={onChanges}
                required={true}
              />
            </Col>
            <Col md={6}>
              <CustomInput
                type="textarea"
                required={true}
                label={
                  form.account_type === "org" ? "Office Address" : "Address"
                }
                name={
                  form.account_type === "org" ? "office_address" : "address"
                }
                value={
                  form.account_type === "org"
                    ? form.office_address
                    : form.contact_address
                }
                onChange={onChanges}
              />
            </Col>
            <Col md={6}>
              <SelectInput
                label="State of residence"
                type="text"
                name="state"
                // required={true}
                options={stateLga.map((item) => item.state)}
                value={form.state}
                onChange={onChanges}
              />
            </Col>
          </Row>
          <Row>
           
            <Col md={6} className="mb-1">
              {/* {JSON.stringify(s)} */}
              <SelectInput
                label="L.G.A"
                type="text"
                name="lga"
                // required={true}
                options={
                  stateLga.filter((item) => item.state === form.state)[0]?.lgas
                }
                value={form.lga}
                onChange={onChanges}
              />
            </Col>
            <Col md={6} className="mb-1">
              <CustomInput
                label="Ward"
                type="text"
                name="ward"
                value={form.ward}
                onChange={onChanges}
              />
            </Col>
          </Row>
          <center>
            {" "}
            <Button onClick={handleApi} disabled={loading}>
              {" "}
              {loading ? <Spinner size="sm" /> : null}Create
            </Button>
          </center>
        </>
      ) : (
        <Card>
          <CardHeader>Express Account</CardHeader>
          <CardBody>
            <ButtonGroup className="w-100 pb-4">
              <Button
                outline={form.account_type === "org"}
                onClick={() =>
                  setForm((p) => ({ ...p, account_type: "individual" }))
                }
                color="dark"
                className={`col-md-6`}
              >
                INDIVIDUAL
              </Button>
              <Button
                color="dark"
                outline={form.account_type === "individual"}
                onClick={() => setForm((p) => ({ ...p, account_type: "org" }))}
                className="col-md-6"
              >
                ORGANIZATION/COMPANY
              </Button>
            </ButtonGroup>
            <Row>
              <Col md={6}>
                <CustomInput
                  label="Enter Phone No."
                  name="contact_phone"
                  value={form.contact_phone}
                  onChange={onChanges}
                  required={true}
                />
              </Col>
              <Col md={6}>
                <CustomInput
                  label="Tax payer name"
                  name="contact_name"
                  value={form.contact_name}
                  onChange={onChanges}
                  required={true}
                />
              </Col>
              <Col md={12}>
                <CustomInput
                  type="textarea"
                  label={
                    form.account_type === "org" ? "Office Address" : "Address"
                  }
                  name={
                    form.account_type === "org" ? "office_address" : "address"
                  }
                  value={
                    form.account_type === "org"
                      ? form.office_address
                      : form.contact_address
                  }
                  required={true}
                  onChange={onChanges}
                />
              </Col>
            </Row>
            <center>
              <Button onClick={handleApi} disabled={loading}>
                {loading ? <Spinner size="sm" /> : null}Create
              </Button>
            </center>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default ExpressAcct;
