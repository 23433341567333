import React from "react";
import Marquee from "react-fast-marquee";

function Notifications(props) {
  return (
    <div>
      <Marquee className="bg-danger">
        <h4 className="d-inline-flex m-0 font-weight-italic text-white">
          Welcome to Kirma's playground, where you can explore the app and enjoy
          navigating through it.
        </h4>
      </Marquee>
      {props.children}
    </div>
  );
}

export default Notifications;
