import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Row,
  Form,
  Modal,
  InputGroup,
  FormControl,
  Table,
  Badge,
  FormSelect,
  // Container,
} from "@themesberg/react-bootstrap";
import { Button, Label, Spinner } from "reactstrap";
import CustomInput from "../CustomInput";
import { useSelector } from "react-redux";
import {
  faEye,
  faEyeSlash,
  // faTrash,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-hot-toast";
import { _fetchApi, _postApi } from "../../../redux/action/api";
import { SearchBar } from "../../../components/UI";
import validator from "validator";
import CustomTypeahead from "../../../components/UI/CustomTypeahead";
import { Checkbox } from "@material-ui/core";
import "./admin-mngr.css";

export default () => {
  const { user } = useSelector((s) => s.auth);
  const _form = {
    account_type: "admin",
    name: "", // Individual's full name
    phone: "", // Individual's phone number
    email: "",
    role: "admin", // Individual's nin
    state: "Kano", // Individual's state
    lga: "", // Individual's local government
    org_name: "", // Organization's name
    password: "123456",
    accessTo: [],
    mda_code: user.mda_code || "",
    mda_name: user.mda_name || "",
    rank: "",
    department: "",
    sector:""
  };
  const [form, setForm] = useState(_form);
  const [errors, setErrors] = useState({
    department: "",
    name: "",
    phone: "",
    email: "",
    accessTo: "",
    rank: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDefault, setShowDefault] = useState(false);
  const toggle = () => setShowDefault(!showDefault);
  const [filterText, setFilterText] = useState("");
  const [admins, setAdmins] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [mdaDepts, setMdaDepts] = useState([]);
  const [mdas, setMdas] = useState([]);

  const ACCESS_OPTIONS =
    user?.accessTo?.split(",") ||
    []?.concat(mdaDepts?.map((dept) => dept?.title));

  const handleChange = ({ target: { name, value } }) => {
    if (name === "accessTo") {
      setForm((prevState) => {
        if (prevState[name].includes(value)) {
          return {
            ...prevState,
            [name]: prevState[name].filter((item) => item !== value),
          };
        } else {
          return {
            ...prevState,
            [name]: [...prevState[name], value],
          };
        }
      });
    } else {
      setForm((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const getMDAs = useCallback(() => {
    _fetchApi("/get/mdas", (resp) => {
      setMdas(resp.data);
    });
  });

  const getMDADepts = useCallback(() => {
    _fetchApi(
      `/get/mda-departments?mda_code=${user.mda_code || ""}`,
      (resp) => {
        if (resp.success) {
          setMdaDepts(resp.data);
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }, [user.mda_code]);

  const getAdmins = useCallback(() => {
    _fetchApi(
      `/users/get-admins?mda_code=${user.mda_code || ""}`,
      (resp) => {
        if (resp.success) {
          setAdmins(resp.data);
        } else {
          setAdmins([]);
        }
      },
      () => {
        setAdmins([]);
        // toast.error("Error: Failed to submit");
      }
    );
  });

  const getDepartment = () => {
    _postApi(
      "/add-departments",
      {},
      () => {},
      () => {}
    );
  };
  useEffect(() => {
    getAdmins();
    getMDAs();
    getMDADepts();
  }, [getMDADepts]);

  const handleSubmit = () => {
    // list.forEach((admin, idx) => {
    _postApi(
      "/auths/-post-users",
      {
        ...form,
        accessTo: form.accessTo.toString(),
        query_type: form.id ? "update-admin" : "create-admin",
        user_id: form.id ? form.id : "",
      },
      (result) => {
        setLoading(false);
        // console.log(result);
        if (result.success) {
          toast.success("Submitted");
          getAdmins();
          setForm([]);
        } else {
          console.log(result);
          toast.error(result.msg);
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        toast.error(err.msg);
      }
    );
    // });
  };

  // const handleSubmit = () => {
  //   setLoading(true);
  //   const list = adminList.map((admin) => {
  //     if (admin.department) {
  //       return { ...admin, mda_code: user.mda_code, mda_name: user.mda_name };
  //     } else {
  //       return admin;
  //     }
  //   });
  //   list.forEach((admin, idx) => {
  //     _postApi(
  //       "/auths/-post-users",
  //       {
  //         ...admin,
  //         accessTo: admin.accessTo.toString(),
  //         query_type: admin.id ? "update-admin" : "create-admin",
  //       },
  //       (result) => {
  //         setLoading(false);
  //         // console.log(result);
  //         if (result.success && idx === adminList.length - 1) {
  //           toast.success("Submitted");
  //           getAdmins();
  //           setAdminList([]);
  //         } else {
  //           console.log(result);
  //           toast.error(result.msg);
  //         }
  //       },
  //       (err) => {
  //         setLoading(false);
  //         console.log(err);
  //         toast.error(err.msg);
  //       }
  //     );
  //   });
  // };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const addAdmin = (e) => {
    e.preventDefault();
    const error = {};
    if (!form.department && user.mda_code) {
      error.department = "Department is required!";
    } else {
      error.department = "";
    }
    if (!form.name) {
      error.name = "Admin name is required";
    } else {
      error.name = "";
    }
    if (!form.email) {
      error.email = "Contact Email is required";
    } else {
      error.email = "";
    }
    if (!validator.isEmail(form.email)) {
      error.email = "Enter a valid email";
    }
    if (
      !validator.isNumeric(form.phone) ||
      !validator.isLength(form.phone, { min: 11, max: 11 })
    ) {
      error.phone = "Enter a valid 11-digit phone number";
    } else {
      error.phone = "";
    }
    // if (form.accessTo.length < 1) {
    //   error.accessTo = "Please add some privileges";
    // } else {
    //   error.accessTo = "";
    // }

    setErrors(error);
    if (
      error.department === "" &&
      error.name === "" &&
      error.phone === "" &&
      error.email === ""
    ) {
      setAdminList((p) => [...p, form]);

      setForm(_form);
    }
  };

  const updateUser = (user_status, message) => {
    _postApi(
      "/auths/-post-users",
      {
        user_status: user_status,
        query_type: "update_status",
      },
      () => {
        toast.success(message);
        getAdmins();
      },
      (err) => {
        toast.error(err.msg);
      }
    );
  };

  const filtered_data = admins?.filter(
    (item) =>
      item?.name.toLowerCase().includes(filterText.toLowerCase()) ||
      item?.phone.toString().includes(filterText)
  );

  return (
    <div
      style={{
        height: "90vh",
        overflowY: "scroll",
        scrollbarWidth: "thin", // For Firefox
        scrollbarColor: "transparent transparent", // For Firefox
        overflow: "-moz-scrollbars-none",
      }}
    >
      {/* {JSON.stringify(adminList)} */}
      <Modal as={Modal.Dialog} centered show={showDefault} size="lg">
        <Modal.Header>
          <Modal.Title className="h6">Admin/Agent Form</Modal.Title>
          <Button className="bg-danger" aria-label="Close" onClick={toggle}>
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form className="mt-4">
            <Row>
              <Col md={6}>
                <CustomInput
                  label="Contact name"
                  onChange={handleChange}
                  type="text"
                  name="name"
                  value={form.name}
                />

                <span className="text-danger">
                  {errors.name ? errors.name : null}
                </span>
              </Col>
              <Col md={6} className="mb-1">
                <CustomInput
                  label="Contact Phone No."
                  type="text"
                  name="phone"
                  value={form.phone}
                  onChange={handleChange}
                />

                <span className="text-danger mt--2">
                  {errors.phone ? errors.phone : null}
                </span>
              </Col>
              <Col md={6} className="mb-1">
                <CustomInput
                  label="Contact Email"
                  type="email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                />

                <span className="text-danger">
                  {errors.email ? errors.email : null}
                </span>
              </Col>
              <Col md={6} className="mb-1">
                <Label>Role</Label>
                <FormSelect
                  label="Role"
                  type="role"
                  name="role"
                  value={form.role}
                  onChange={handleChange}
                >
                  <option value={"admin"}>Admin</option>
                  <option value={"agent"}>Agent</option>
                </FormSelect>
              </Col>
              <Col md={12} className="mb-1">
                <Label>Access to </Label>{" "}
                <Label htmlFor={`access-x`}>
                  ( All
                  <Checkbox
                    id={`access-x`}
                    name="accessTo"
                    onChange={() =>
                      setForm((p) => ({
                        ...p,
                        accessTo: p.accessTo.length ? [] : ACCESS_OPTIONS,
                      }))
                    }
                    value={ACCESS_OPTIONS}
                    checked={form.accessTo.length === ACCESS_OPTIONS.length}
                  />
                  )
                </Label>
                <div className="row">
                  {ACCESS_OPTIONS?.map((item, idx) => (
                    <div className="col-md-4">
                      <Label
                        key={idx}
                        htmlFor={`access-${idx}`}
                        className="checkbox-label"
                      >
                        <Checkbox
                          id={`access-${idx}`}
                          name="accessTo"
                          onChange={handleChange}
                          value={item}
                          checked={form.accessTo.includes(item)}
                        />{" "}
                        {item}
                      </Label>
                    </div>
                  ))}
                </div>
                <span className="text-danger">
                  {errors.accessTo ? errors.accessTo : null}
                </span>
              </Col>

              {user?.mda_code ? null : (
                <Col md={6} className="mb-1">
                  <CustomTypeahead
                    label="MDA"
                    onInputChange={() => {}}
                    onChange={(s) => {
                      setForm((p) => ({
                        ...p,
                        mda_code: s[0]?.mda_code,
                        mda_name: s[0]?.mda_name,
                      }));
                    }}
                    labelKey={"mda_name"}
                    options={mdas}
                    clearButton
                    col={12}
                  />
                </Col>
              )}
              {user.mda_code ? (
                <Col md={6} className="mb-1">
                  <Label>Department</Label>
                  <FormSelect
                    type="select"
                    name="department"
                    value={form.department}
                    required="required"
                    onChange={handleChange}
                  >
                    <option value={""}>Select department</option>
                    {/* <option value={'*'}>MDA Admin</option> */}
                    {mdaDepts.map((dept, idx) => (
                      <option key={idx}>{dept.title}</option>
                    ))}
                  </FormSelect>
                  <span className="text-danger">
                    {errors.department ? errors.department : null}
                  </span>
                </Col>
              ) : null}

              {/* <Col md={6} className="mb-4">
                <Form.Label>Rank</Form.Label>
                <InputGroup>
                  <FormControl
                    type={"text"}
                    name="rank"
                    value={form.rank}
                    onChange={handleChange}
                    placeholder="Rank"
                  />
                </InputGroup> */}

              <Col md={6} className="mb-4">
                <Form.Label>Rank</Form.Label>
                <FormSelect
                  label="Rank"
                  type="rank"
                  name="rank"
                  value={form.rank}
                  onChange={handleChange}
                >
                  <option value={""}>Select Rank</option>
                  <option value={"Department Head"}>Department Head</option>
                  <option value={"Staff"}>Staff</option>
                </FormSelect>
              </Col>
              <Col md={6} className="mb-1">
                <CustomTypeahead
                  label="Sector"
                  multiple
                  onInputChange={() => {}}
                  onChange={(s) => {
                    setForm((p) => ({
                      ...p,
                      sector: s.join(','),
                    }));
                  }}
                  // labelKey={"mda_name"}
                  options={["TAX", "NON TAX", "LAND", "LGA", "VEHICLES"]}
                  col={12}
                />
              </Col>
              <Col md={6} className="mb-4">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <FormControl
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={form.password}
                    onChange={handleChange}
                    placeholder="Password"
                  />
                  <InputGroup.Text
                    style={{ cursor: "pointer" }}
                    onClick={toggleShowPassword}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              <Col md={12} className="text-center">
                {" "}
                <Button onClick={handleSubmit} color="dark" className="ms-auto">
                  {loading ? <Spinner /> : "Submitt"}
                </Button>
              </Col>
              <Col md={6} className="text-center">
                <br />
                {/* <Button
                  onClick={addAdmin}
                  color="dark"
                  type="submit"
                  className="w-100"
                >
                  Add to list
                </Button> */}
              </Col>
            </Row>
          </Form>

          <table>
            {adminList.length ? (
              <thead>
                <tr>
                  <th>SN</th>
                  <th>NAME</th>
                  <th>PHONE</th>
                  <th>EMAIL</th>
                  <th>ROLE</th>
                  <th>MDA</th>
                  <th>DEPARTMENT</th>
                  <th>ACTION</th>
                </tr>
              </thead>
            ) : null}
            <tbody>
              {adminList?.map((admin, idx) => (
                <tr>
                  <td>{idx + 1}</td>
                  <td>{admin.name}</td>
                  <td>{admin.phone}</td>
                  <td>{admin.email}</td>
                  <td>{admin.role}</td>
                  <td>{admin.mda}</td>
                  <td>{admin.department}</td>
                  <td>
                    <Button
                      color="light"
                      size="sm"
                      onClick={() => {
                        setForm((p) => ({ ...p, ...admin }));
                        setShowDefault(true);
                      }}
                    >
                      EDIT
                    </Button>
                    <Button size="sm" color="warning">
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          {adminList.length ? (
            <Col md={12} className="text-center">
              {" "}
              <Button onClick={handleSubmit} color="dark" className="ms-auto">
                {loading ? <Spinner /> : "Submitt"}
              </Button>
            </Col>
          ) : null}
        </Modal.Footer>
      </Modal>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-3">
        <div className="d-block mb-4 mb-md-0">
          <h4>Admin list</h4>
          {/* {JSON.stringify(form)} */}
          <p className="mb-0">{user.mda_name}.</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Button variant="outline-primary" size="sm" onClick={toggle}>
            Create Admin{" "}
            <FontAwesomeIcon icon={faUserAlt} size="lg" className="me-2" />
          </Button>
        </div>
      </div>
      {/* {JSON.stringify(admins)} */}
      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col md={10}>
            <SearchBar
              filterText={filterText}
              onFilterTextChange={(val) => setFilterText(val)}
            />
          </Col>
          <Col md={2}>
            <Badge>{admins.length}</Badge>
          </Col>
        </Row>
      </div>
      <div style={{ height: "70vh", minHeight: "50vh", overflowY: "scroll" }}>
        <Table striped>
          <thead>
            <tr>
              <th>SN</th>
              <th>NAME</th>
              <th>PHONE</th>
              <th>EMAIL</th>
              <th>ROLE</th>
              <th>MDA</th>
              <th>DEPARTMENT</th>
              <th>RANK</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {filtered_data?.map((admin, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{admin.name}</td>
                <td>{admin.phone}</td>
                <td>{admin.email}</td>
                <td>{admin.role}</td>
                <td>{admin.mda}</td>
                <td>{admin.department}</td>
                <td>{admin.rank}</td>
                <td>
                  <Button
                    color="secondary"
                    size="sm"
                    outline
                    onClick={() => {
                      setForm((p) => ({ ...p, ...admin }));
                      setShowDefault(true);
                    }}
                  >
                    EDIT
                  </Button>
                  {admin.status === "suspended" ? (
                    <Button
                      outline
                      size="sm"
                      color="danger"
                      onClick={() =>
                        updateUser("approved", "Suspended successfully")
                      }
                    >
                      Revert
                    </Button>
                  ) : (
                    <Button
                      outline
                      size="sm"
                      color="warning"
                      onClick={() =>
                        updateUser("approved", "Approved successfully")
                      }
                    >
                      Suspend
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {/* List of Admins */}
    </div>
  );
};
