import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Card } from "@themesberg/react-bootstrap";
import ChartComponent from "./dashboard/ChartComponent";
import { useHistory } from "react-router";
import { Routes } from "../routes";
import PieChart from "./dashboard/charts/PieChart";
import { toast } from "react-hot-toast";
import { _fetchApi } from "../redux/action/api";
import { CustomButton } from "../components/UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import {
  faArrowCircleLeft,
  faTh,
  faThList,
} from "@fortawesome/free-solid-svg-icons";
import { getMonth, toCurrency, useQuery } from "../utils";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { Label, Input } from "reactstrap";
import { ButtonGroup, Table } from "@themesberg/react-bootstrap/lib/esm";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton/dist";
import CustomDateRange from "./dashboard/CustomDateRange";

const Feature = (props) => {
  const { total, name, onClick } = props;

  return (
    <Col xs={12} sm={6} lg={3} className="my-1">
      <Card
        border="primary"
        className="shadow bg-info text-white"
        style={{ cursor: "pointer", height: "150px" }}
        onClick={onClick}
      >
        <Card.Body>
          <div
            style={{
              display: "fex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h6 className="text-center">{name}</h6>
            <h3 className="mb-1 text-center">{total}</h3>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

function MdaReportsDashboard({
  view_by = "Tax items",
  sector = "",
  general = false,
}) {
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const today = moment().format("YYYY-MM-DD");
  const oneYearAgo = moment().subtract(1, "years").format("YYYY-MM-DD");
  const q = useQuery();
  // const sector = q.get("sector") || "";
  // const view_by = q.get("view_by") || "Tax items";

  const [form, setForm] = useState({
    from: oneYearAgo,
    to: today,
    sector,
    view_by,
    mda_code: "",
  });

  // date picker setup
  const [dateRange, setDateRange] = useState({
    type: "daily",
    month: today.format("MM"),
    quarter: Math.ceil(today.format("MM") / 3).toString(),
    year: today.format("YYYY"),
    from: today.format("YYYY-MM-DD"),
    to: today.format("YYYY-MM-DD"),
  });

  useEffect(() => {
    const today = moment().format("YYYY-MM-DD");
    const weekStart = moment().startOf("week").format("YYYY-MM-DD");
    const weekEnd = moment().endOf("week").format("YYYY-MM-DD");
    const monthStart = moment().startOf("month").format("YYYY-MM-DD");
    const monthEnd = moment().endOf("month").format("YYYY-MM-DD");
    const yearStart = moment().startOf("month").format("YYYY-MM-DD");
    const yearEnd = moment().endOf("month").format("YYYY-MM-DD");

    const qStart = moment(`'${dateRange.year}-${(dateRange.quarter*3)-3}-01`).format("YYYY-MM-DD");
    const qEnd = moment(`'${dateRange.year}-${(dateRange.quarter*3)}-01`).endOf("month").format("YYYY-MM-DD");

    switch (dateRange.type) {
      case "daily":
        setDateRange({ ...dateRange, from: today, to: today });
        break;
      case "weekly":
        setDateRange({ ...dateRange, from: weekStart, to: weekEnd });
        break;
      case "monthly":
        setDateRange({ ...dateRange, from: monthStart, to: monthEnd });
        break;
      case "quarterly":
        setDateRange({ ...dateRange, from: qStart, to: qEnd });
        break;
      case "yearly":
        setDateRange({ ...dateRange, from: yearStart, to: yearEnd });
  
      default:
        break;
    }
  }, [dateRange.type]);

  const handleChangeDate = ({ target: { name, value } }) => {
    switch (name) {
      case "month":
        const monthStart = moment(`'${dateRange.year}-${value}-01'`).format(
          "YYYY-MM-DD"
        );
        const monthEnd = moment(`'${dateRange.year}-${value}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: monthStart,
          to: monthEnd,
          month: value,
        });
        break;
      case "year":
        const yearStart = moment(`'${value}-01-01'`).format("YYYY-MM-DD");
        const yearEnd = moment(`'${value}-12'`)
          .endOf("year")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: yearStart,
          to: yearEnd,
        });
        break;

      case "quarter":
        let startMonth, endMonth;
        let selectedQuarter = value;
        switch (selectedQuarter) {
          case "Q1":
            startMonth = 1;
            endMonth = 3;
            break;
          case "Q2":
            startMonth = 4;
            endMonth = 6;
            break;
          case "Q3":
            startMonth = 7;
            endMonth = 9;
            break;
          case "Q4":
            startMonth = 10;
            endMonth = 12;
            break;
          default:
            // Handle unexpected quarter values
            break;
        }
        const qStart = moment(`'${dateRange.year}-${startMonth}-01'`).format(
          "YYYY-MM-DD"
        );
        const qEnd = moment(`'${dateRange.year}-${endMonth}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: qStart,
          to: qEnd,
          quarter: selectedQuarter,
        });
        break;
      case "from":
        setDateRange({
          ...dateRange,
          from: moment(value).format("YYYY-MM-DD"),
        });
        break;

      case "to":
        setDateRange({
          ...dateRange,
          to: moment(value).format("YYYY-MM-DD"),
        });
        break;
      default:
        break;
    }
    setDateRange((prevDateRange) => ({
      ...prevDateRange,
      [name]: value,
    }));
  };

  //   const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  //   const [total_revenue, setTotalRevenue] = useState(0);
  const [monthlyRevenues, setMonthlyRevenues] = useState([]);
  const [banklyRevenues, setBanklyRevenues] = useState([]);
  const [taxlyRevenues, setTaxlyRevenues] = useState([]);
  const [sectorRevenues, setSectorRevenues] = useState([]);
  const [mdas, setMDAs] = useState([]);

  const handleChange = ({ target: { name, value } }) => {
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };
  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `/get/mdas?query_type=all&sector=${form.sector}`,
      (resp) => {
        if (resp.success) {
          setMDAs(resp.data);
        }
      },
      (err) => {
        console.error(err);
        toast.error("Errorr occured! failed to fetch MDA  data.");
      }
    );
    _fetchApi(
      `/get/mdas?query_type=bankly-reports&sector=${form.sector}&mda_code=${form.mda_code}`,
      (res) => {
        setLoading(false);
        if (res.success) {
          setBanklyRevenues(res.data);
        } else setBanklyRevenues([]);
      },
      (err) => {
        setLoading(false);
        setBanklyRevenues([]);
        console.log(err);
      }
    );
    _fetchApi(
      `/get/mdas?query_type=taxly-reports&sector=${form.sector}&mda_code=${form.mda_code}`,
      (res) => {
        setLoading(false);
        if (res.success) {
          setTaxlyRevenues(res.data);
        } else setTaxlyRevenues([]);
      },
      (err) => {
        setLoading(false);
        setTaxlyRevenues([]);
        console.log(err);
      }
    );
    _fetchApi(
      `/get/mdas?query_type=sectorly-reports&sector=${form.sector}&start_date=${dateRange.from}&end_date=${dateRange.to}`,
      (res) => {
        setLoading(false);
        if (res.success) {
          setSectorRevenues(res.data);
        } else setSectorRevenues([]);
      },
      (err) => {
        setLoading(false);
        setSectorRevenues([]);
        console.log(err);
      }
    );
    _fetchApi(
      `/get/mdas?query_type=monthly-reports&start_date=${dateRange.from}&end_date=${dateRange.to}&sector=${form.sector}&mda_code=${form.mda_code}`,
      (res) => {
        setLoading(false);
        if (res.success) {
          setMonthlyRevenues(res.data);
        } else setMonthlyRevenues([]);
      },
      (err) => {
        setLoading(false);
        setMonthlyRevenues([]);
        console.log(err);
      }
    );
    // _fetchApi(
    //   `/get/mdas?query_type=total-revenue&sector=${form.sector}&mda_code=${form.mda_code}`,
    //   (res) => {
    //     setLoading(false);
    //     if (res.success) {
    //       setTotalRevenue(res.data[0]);
    //     } else setTotalRevenue(0);
    //   },
    //   (err) => {
    //     setLoading(false);
    //     setTotalRevenue(0);
    //     console.log(err);
    //   }
    // );
  }, [user, form, dateRange]);

  const [isGrid, setIsGrid] = useState(true);

  const toggleView = () => {
    setIsGrid(!isGrid);
  };

  // const handleChangeDate = (updated) => setForm(updated);
  //   const total = data?.reduce((item, d) => item + parseFloat(d.total_amt), 0);

  return loading ? (
    <SkeletonTheme baseColor="#f1f1f1" highlightColor={"#ffffff"}>
      <p>
        <Skeleton count={10} height={50} />
      </p>
    </SkeletonTheme>
  ) : (
    <div>
      <Row>
        <Col md={2}>
          <CustomButton
            onClick={() => history.push(Routes.DashboardOverview.path)}
          >
            <FontAwesomeIcon icon={faArrowCircleLeft} />
            Back
          </CustomButton>
        </Col>
        <Col>
          {" "}
          <h4 className="text-center fw-bold">Performance Report</h4>
        </Col>

        <Col md={2}></Col>
      </Row>
      {general ? (
        <Row className="mb-1">
          <Col md={3}>
            <Label>Tax category</Label>
            <Input
              type="select"
              name="sector"
              value={form.sector}
              onChange={handleChange}
            >
              {["", "TAX", "NON TAX", "VEHICLES", "LAND", "LGA"].map(
                (item, idx) =>
                  item === "" ? (
                    <option value="" key={idx}>
                      Select category
                    </option>
                  ) : (
                    <option key={idx}>{item}</option>
                  )
              )}
            </Input>
          </Col>
          <Col md={3}>
            <Label>MDA</Label>
            <Input
              type="select"
              name="mda"
              value={form.mda}
              onChange={handleChange}
            >
              <option value="">Select MDA</option>
              {mdas.map((item, idx) => (
                <option value={item.mda_code} key={idx}>
                  {item.mda_name}
                </option>
              ))}
            </Input>
          </Col>

          <Col className="pt-2">
            <CustomDateRange
              type={dateRange.type}
              month={dateRange.month}
              quarter={dateRange.quarter}
              year={dateRange.year}
              from={dateRange.from}
              to={dateRange.to}
              handleChangeDate={handleChangeDate}
            />
          </Col>
        </Row>
      ) : (
        <CustomDateRange
          type={dateRange.type}
          month={dateRange.month}
          quarter={dateRange.quarter}
          year={dateRange.year}
          from={dateRange.from}
          to={dateRange.to}
          handleChangeDate={handleChangeDate}
        />
      )}
      <div
        style={{
          height: "70vh",
          overflowY: "scroll",
          scrollbarWidth: "thin", // For Firefox
          scrollbarColor: "transparent transparent", // For Firefox
          overflow: "-moz-scrollbars-none",
        }}
      >
        <Row>
          <Col md={6}>
            <ChartComponent
              data={monthlyRevenues.map((item) => ({
                ...item,
                name: getMonth(item.month),
                [moment().format("YYYY")]: item.total,
              }))}
              type="bar"
            />
          </Col>
          <Col md={6}>
            <PieChart
              rows={sectorRevenues.map((item) => ({
                name: item.sector,
                value: parseInt(item.total),
              }))}
            />
          </Col>
        </Row>
        {/* {JSON.stringify({ form })} */}
        <Row className="mt-2">
          <Col md={3}>
            <ButtonGroup className="w-100 pb-1">
              <Button
                onClick={toggleView}
                className={`${isGrid ? "btn-default" : "btn-secondary"}`}
                color={isGrid ? "white" : "primary"}
                title="List view"
              >
                <FontAwesomeIcon icon={faThList} />
              </Button>
              <Button
                onClick={toggleView}
                className={`${!isGrid ? "btn-default" : "btn-secondary"}`}
                color={!isGrid ? "white" : "primary"}
                title="Grid view"
              >
                <FontAwesomeIcon icon={faTh} />
              </Button>
            </ButtonGroup>
          </Col>
          <Col md={3}>
            <Input
              label="View items by"
              title="View items by"
              type="select"
              name="view_by"
              value={form.view_by}
              onChange={handleChange}
            >
              {["", "Banks", "Tax items"].map((item, idx) =>
                item === "" ? (
                  <option value="" key={idx}>
                    Select category
                  </option>
                ) : (
                  <option key={idx}>{item}</option>
                )
              )}
            </Input>
          </Col>
          <Col className="text-right">
            <Label>
              Total:{" "}
              {form.view_by === "Banks"
                ? toCurrency(
                    banklyRevenues.reduce(
                      (it, id) => it + parseFloat(id.total),
                      0
                    )
                  )
                : toCurrency(
                    taxlyRevenues.reduce(
                      (it, id) => it + parseFloat(id.total),
                      0
                    )
                  )}
            </Label>
          </Col>
        </Row>
        {form.view_by === "Banks" ? (
          <>
            {!isGrid ? (
              <Row>
                <Card>
                  <Card.Body>
                    <Table className="bg-info text-white">
                      <thead>
                        <tr>
                          <td>#</td>
                          <td>BANK NAME</td>
                          <td>AMOUNT</td>
                        </tr>
                      </thead>
                      <tbody>
                        {banklyRevenues.map((row, idx) => (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{row.bank_name || "Others"}</td>
                            <td className="text-right">
                              {toCurrency(row.total)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Row>
            ) : (
              <Row>
                {banklyRevenues.map((a, i) => (
                  <Feature
                    {...{
                      name: a.bank_name || "Others",
                      total: toCurrency(a.total),
                    }}
                    key={i}
                  />
                ))}
              </Row>
            )}
          </>
        ) : form.view_by === "Tax items" || form.view_by === "" ? (
          <>
            {isGrid ? (
              <Row>
                {taxlyRevenues.map((a, i) => (
                  <Feature
                    {...{ name: a.description, total: toCurrency(a.total) }}
                    key={i}
                    onClick={() =>
                      history.push(`${Routes.MDAReport.path}/${a.mda_code}`)
                    }
                  />
                ))}
              </Row>
            ) : (
              <Row>
                <Card>
                  <Card.Body>
                    <Table className="bg-info text-white">
                      <thead>
                        <tr>
                          <td>#</td>
                          <td>TAX DESCRIPTION</td>
                          <td>TOTAL COLLECTION</td>
                        </tr>
                      </thead>
                      <tbody>
                        {taxlyRevenues.map((row, idx) => (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{row.description || "Others"}</td>
                            <td className="text-right">
                              {toCurrency(row.total)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Row>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
}

export default MdaReportsDashboard;
