import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { InterswitchPay } from "react-interswitch";
import { _postApi } from "../../redux/action/api";
import moment from "moment";
import { useSelector } from "react-redux";
import { formatNumber } from "../../utils";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "../../routes";

const PaymentButton = ({
  addStyle = {},
  merchantCode = "60969",
  // payItemID = "60969",
  amount = 0,
  label = "Confirm Payment",
  email = "",
  sector = "",
  data = [],
  taxID = "",
  name = "",
  phone = "",
  reference_no = "",
  init = (f) => f,
  toggle = (f) => f,
  setPayModal = (f) => f,
}) => {
  let [refNo, setRefNo] = useState(null);
  let payItemID = null;
  const history = useHistory();
  const location = useLocation();
  const [checker, setChecker] = useState(false);
  useEffect(() => {
    if (checker) {
      history.push(`/online-payment?reference_number=${refNo || reference_no}`);
    }
  }, [checker]);
  if (!reference_no && data.length) {
    init((ref) => {
      setRefNo(ref);
    });
  }
  const handleResponse = (response) => {
    if (response.desc === "Approved by Financial Institution") {
      fetch(
        `https://webpay.interswitchng.com/collections/api/v1/gettransaction.json?merchantcode=MX${merchantCode}&transactionreference=${
          refNo || reference_no
        }&amount=${amount * 100}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((raw) => raw.json())
        .then((resp) => {
          if (resp) {
            console.log(resp);
            console.log("resp");
            if (resp.ResponseCode === "00") {
              _postApi(
                "/inter-response",
                { ...resp, ...response },
                (res) => {
                  setChecker(true);
                  if (res.successs) {
                    setChecker(true);
                  }
                },
                (err) => {
                  console.log(err);
                  toast.error("Error occured while saving the payment update ");
                }
              );
            }
          } else {
            console.log("Empty response");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Payment aborted!");
        });
    } else {
      toast.error("Payment aborted!");
      console.error(response);
    }
  };
  switch (sector) {
    case "VEHICLES":
      payItemID = "7035882";
      break;
    case "LGA":
      payItemID = "4351942";
      break;
    case "LAND":
      payItemID = "6762266";
      break;
    case "NON TAX":
      payItemID = "6160982";
      break;
    case "TAX":
      payItemID = "5775505";
      break;

    default:
      break;
  }
  let new_amount = amount * 100;
  let input_amount = new_amount;
  const paymentParameters = {
    merchantCode: `MX${merchantCode}`,
    payItemID,
    customerEmail: email,
    redirectURL: `https://kirmas.kn.gov.ng${
      Routes.OnlinePayment.path
    }?reference_number=${refNo || reference_no}`,
    text: ` Confirm Payment(NGN${formatNumber(amount)})`,
    mode: "LIVE", //"TEST",
    payRef: "WEB",
    customerName: name.replace("'", "&#x27;"),
    customerID: taxID,
    currency: "566",
    transactionReference: reference_no,
    amount: parseFloat(input_amount),
    style: {
      // width: '200px',
      height: "40px",
      border: "none",
      color: "#fff",
      backgroundColor: "#262B40",
      padding: "7px",
      width: "100%",
      borderRadius: "10px",
      ...addStyle,
    },
    callback: (response) => {
      handleResponse(response);
      console.log("response: ", response);
    },
  };
  return (
    <>
    {/* {JSON.stringify(phone)} */}
      <InterswitchPay {...paymentParameters} />
    </>
  );
};

export default PaymentButton;
